export const  AutColors = {
    skobelOff: '#127475ff',
    cambridgeBlue: '#97C581',
    frenchBlue: '#2374abff',
    snow: '#f7f2f5ff',
    darkPurple: '#27213cff',
    darkPurpleSecondary: "#5d5c63",
    greyLighter: '#ebebeb',
    greyLight: '#f2f2f2',
    grey: '#b0b0b0',
}