import React                           from 'react';
import PropTypes                       from 'prop-types';
//import {Button}                        from 'react-bootstrap';
import {LinearProgress}                from '@material-ui/core';

import {
  useTable,
  //useGroupBy,
  //useFilters,
  useSortBy,
  //useExpanded,
  usePagination,
} from 'react-table'

//import {FontAwesomeIcon}               from '@fortawesome/react-fontawesome';
//import {faCheck}                       from '@fortawesome/free-solid-svg-icons';
//import {faEdit}                        from '@fortawesome/free-solid-svg-icons';
//import {faTrashAlt}                    from '@fortawesome/free-solid-svg-icons';
//import {faBook}                        from '@fortawesome/free-solid-svg-icons';
//import {faPlus}                        from '@fortawesome/free-solid-svg-icons';
//import {faQuestion}                    from '@fortawesome/free-solid-svg-icons';

import {myFabrik}                      from './LaFabrik';

//import {infosdbs}                      from './Bdd';
//import {addfuel}                       from './Bdd';
import {getdefaultbddentry}            from './Bdd';
import {useDocFromId}                  from './Bdd';
import {useDocsFromSelector}           from './Bdd';
import {CRUDcompo}                     from './Bdd';
import {showModal}                     from './Modal';
import {editModal}                     from './Modal';
import {MyOverlayTrigger}              from './Modal';
import {appstore}                      from './Communs';
import {hEchelle}                      from './Communs';
import {mynature}                      from './Communs';
//import {mymetalog}                     from './Bdd';
//export let jQuery   = require('jquery');
//window.jQuery       = jQuery;
//jQuery.DataTable    = require('datatables.net');
//require ('datatables.net-plugins/dataRender/ellipsis');

/**
 *
 * @class Datatable - Reçoit dans son constructor son type : "user", "participant", "atelier", ...
 * On concatène 'table' et data-table-type- avec le type pour faire fonctionner la DT.
 * Paramètres pour la gestion des champs autour de la DT : fl & pi (length, paging, ... ) à placer dans la partie dom,
 * de part et d'autres de <t> (table).
 * @param {props} tracktype Permet de suivre un document d'un type donné.
 * @param {dict} myEntete sous forme de clé/valeurs on génére les entêtes du tableau.
 */

const MyCell = ({cell,contenu,modal}) => {
    const {value, row} = cell;
    // contenu: (r,i,modal) => { quleque chose avec le row, l'id de ligne et l'uuid de la modale d'édition}
    const macellule    = (contenu && 'original' in row?contenu(row.original,row.id,modal):(value?value:'-'));
    return (macellule);
}


const MaReactTable = ({props, mydata}) => {
    const {modal=null, hDescDt=null, myTopHeader=null, tableClass=null, thClass=null} = props;
    const tableDefaultClass = "table table-striped table-bordered m-auto w-100";
    const thDefaultClass    = "bg-dark whiteColor th-sm";

    // Exemple tiré de la documentation:
    //[
    //    { Header: 'Column 1', accessor: 'col1', }, // accessor is the "key" in the data
    //    { Header: 'Column 2', accessor: 'col2', },
    //]
//mymetalog ("MAREACTABLE 11111111", infosdbs);
    const columns = React.useMemo( () => (hDescDt
        ?   Object.keys(hDescDt)
        .map(e => {
            const contenu = hDescDt[e].contenu;
            const mycell  = cell => <MyCell cell={cell} contenu={contenu} modal={modal}/>
            return ({ accessor: e, Header: hDescDt[e].title, Cell: mycell });
        })
        : null
        ), [hDescDt]
    );

    // Exemple du manuel:
    //[
    //    { col1: 'Hello'      , col2: 'World',   },
    //    { col1: 'react-table', col2: 'rocks',   },
    //    { col1: 'whatever'   , col2: 'you want' },
    //]
//mymetalog ("MAREACTABLE 22222222", infosdbs);
    const data = React.useMemo(() =>
        (mydata?mydata:[])
	,[mydata]
    );

//mymetalog ("MAREACTABLE 33333333", infosdbs);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // remplace rows,
        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({ columns, data, initialState: {pageIndex:0} }, useSortBy, usePagination);
//mymetalog ("MAREACTABLE 44444444", infosdbs);
    const hooks        = ('__workflow__' in props && 'hooks' in props.__workflow__?props.__workflow__.hooks:null);
//mymetalog ("MAREACTABLE 55555555", infosdbs);
    const onrowdisplay = (hooks && 'onrowdisplay' in hooks && hooks.onrowdisplay?hooks.onrowdisplay:null);
//mymetalog ("MAREACTABLE 66666666", infosdbs);

    return (
        <div className='tableContainer'>
            <table {...getTableProps()} className={(tableClass?tableClass:tableDefaultClass)}>
                <thead className={thClass?thClass:thDefaultClass}>
                    {myTopHeader}
                    {headerGroups.map((headerGroup,j) => (
                    <tr key={j} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column,i) => (
                            <th key={i} {...column.getHeaderProps(column.getSortByToggleProps())}>
                            { column.render('Header') }
                            </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row,j) => {
                        prepareRow(row);
                        onrowdisplay && onrowdisplay('original' in row?row.original:null);
                        return (
                            <tr key={j} {...row.getRowProps()}>
                                {row.cells.map((cell,i) => {
                                    return <td key={i} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {mydata.length > 10
            ?
            <div className="pagination d-flex flex-wrap justify-content-between mt-1 col-12">
                <div>
                    <button className="btnIcon" onClick={() => gotoPage(0)}    disabled={!canPreviousPage}> 
                        <i className="bi bi-arrow-left-circle-fill"></i>
                    </button>
                    <button className="btnIcon" onClick={() => previousPage()} disabled={!canPreviousPage}> 
                        <i className="bi bi-arrow-left-circle"></i>
                    </button>
                    <button className="btnIcon" onClick={() => nextPage()}     disabled={!canNextPage}>     
                        <i className="bi bi-arrow-right-circle"></i>
                    </button>
                    <button className="btnIcon" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}> 
                        <i className="bi bi-arrow-right-circle-fill"></i>
                    </button>
                
                </div>
                <span> Page{' '}{pageIndex + 1} de {pageOptions.length}{' '} </span>
                <span>
                    Aller à la page{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        className="inputNumStyle"
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>
                {' '}
                <select
                    className='btnTextGreen'
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                {[10, 25, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                        Montrer {pageSize}
                    </option>
                ))}
                </select>
            </div>
            :null}
        </div>
    );
}
MaReactTable.propTypes = {
    props:        PropTypes.object,
    __workflow__: PropTypes.object,
    hDescDt:      PropTypes.object,
    thClass:      PropTypes.string,
    tableClass:   PropTypes.string,
    mydata:       PropTypes.array,
    myTopHeader:  PropTypes.element,
};

// Lister les fichiers attachés du doc (doc unique dans ce cas)
const myattachments = (doc) => (doc && '_attachments' in doc && doc._attachments
    ? Object.keys(doc._attachments)
    .reduce((a,k)=>{
        a.push({
            ...doc._attachments[k],
            filename:k,
            _id:doc['_id'],
            _rev:doc['_rev'],
            __methanol__:doc['__methanol__']
        });
        return (a);
    },[])
    :[]
);

// Datatable pour des champs à l'intérieur d'un doc
// Utilisé typiquement pour les fichiers attachés au doc
// i.e. dans _attachments
const DatatableById = (props) => {
    const {_id=null,tracktype=null,partof=null,bddentry=getdefaultbddentry(null)} = props;
    const doc       = useDocFromId ({bddentry, _id});
    const error     = (doc && 'error' in doc?doc:null);
    return (error
        ?   <Error error={error}/>
        :   doc
        ?   <MaReactTable props={props} mydata={myattachments(doc)} tracktype={tracktype} partof={partof}/>
        :   <LinearProgress />
    );
}
DatatableById.propTypes = {
    _id:       PropTypes.string,
    partof:    PropTypes.string,
    tracktype: PropTypes.string,
    bddentry:  PropTypes.string,
};

export const Error = (params) => {
    const {error=null, text=null} = params;
    const stringerror = (error && typeof error === "string"?error:null);
    const stringtext  = (text  && typeof text  === "string"?text :null);
    const string2prnt = (stringerror?stringerror:stringtext?stringtext:typeof params === "string"?params:null);
    if (!string2prnt) { console.error (params); }
    return (
        <span>
        {string2prnt?string2prnt:"unknown error"}
        </span>
    );
}

// Datatable dont les data sont fournies de manière statique
const DatatableByData = (props) => {
    const { tracktype=null,partof=null,partvalue=null,
        userData=null } = props;
    const lebonfil  = appstore.getState()['fildAriane'];
    const myvalue   = (partvalue?partvalue:partof && partof in lebonfil?lebonfil[partof]:null);
    let   selector  = ('selector'  in props?props.selector:{ type: tracktype });
    if (partof && myvalue  ) {
        selector[partof] = myvalue  ;
    }
    const mydata    = userData;
    const error     = (mydata && 'error' in mydata?mydata:null);

    return (error
        ?   <Error error={error}/>
        :   mydata
            ?   <MaReactTable props={props} mydata={mydata} tracktype={tracktype} partof={partof}/>
            :   <LinearProgress />
    );
}
DatatableByData.propTypes = {
    userData:  PropTypes.array,
    tracktype: PropTypes.string,
    partof:    PropTypes.string,
    partvalue: PropTypes.string,
    bddentry:  PropTypes.string,
    selector:  PropTypes.object,
};

// Datatable avec surveillance des modifications sur les enregistrements
// correspondant à un selector (exemple: {type: 'montypeasurveiller'}
const DatatableBySelector = (props) => {
    const {type=null,tracktype=type,partof=null,partvalue=null,
        bddentry=getdefaultbddentry(null)} = props;
//const mymetalog=console.log;
//mymetalog ("INFOSDBS 1", infosdbs);
    const lebonfil  = appstore.getState()['fildAriane'];
    const myvalue   = (partvalue?partvalue:partof && partof in lebonfil?lebonfil[partof]:null);
    let   selector  = ('selector'  in props?props.selector:{ type: tracktype });
    if (partof && myvalue  ) {
        selector[partof] = myvalue  ;
    }
    const defaultinit = [];
    const mydata    = useDocsFromSelector ({defaultinit, bddentry, selector});
    const error     = (mydata && 'error' in mydata?mydata:null);

    return (error
        ?   <Error error={error}/>
        :   mydata
            ?   <MaReactTable props={props} mydata={mydata} tracktype={tracktype} partof={partof}/>
            :   <LinearProgress />
    );
}
DatatableBySelector.propTypes = {
    type:      PropTypes.string,
    userData:  PropTypes.array,
    tracktype: PropTypes.string,
    partof:    PropTypes.string,
    partvalue: PropTypes.string,
    bddentry:  PropTypes.string,
    selector:  PropTypes.object,
};

//let    icpt = 0;
export const Datatable = (props) => {
//mymetalog ("INFOSDBS BEFORE DATATABLE", infosdbs);
//console.log ("JFBJFB AFFICHAGE DATATABLE LE RETOUR", icpt++, props);
    const {userData=null} = props;
    const error = "error";
    if (!props) {
        console.error ("Datatable: no props", props);
        throw new Error ("No props", {props});
    }
//mymetalog ("INFOSDBS BEFORE BEFORE... 111", infosdbs);
    const digidfield= (props && 'dig_id_field' in props);
//mymetalog ("INFOSDBS BEFORE BEFORE... 222", infosdbs, digidfield);
    return (!props
        ?   <Error error={error}/>
        :   digidfield
            ?   <DatatableById           {...props} />
            :   userData
                ?   <DatatableByData     {...props} />
                :   <DatatableBySelector {...props} />
    );
}
Datatable.propTypes = {
    userData: PropTypes.array,
};

/**
 * Dernière colonne des DT qui permettent de modifier ou de supprimer la ligne.
 * @param {Dict} rowdata {
 *  type: "cveeez",
 *  nature: "appel_d_offre",
 *  denomination: "Architecte en cyber-sécurité",
 *  date: "Courant Mars",
 *  ...}
 * @param {Array} actions ["edit", "trash"]
 * Le bouton ne se créé que si l'index existe dans 'actions'.
 * L'edit crée une modale une MODAL à partir d'un type.
 * new MODALCOMPO permet d'instancier une modale en passant les paramètres des champs à laFabrik
 * get_myBilan permet de faire un dispatch pour aller sur le dashboard (pas présent dans cveeez 19/03).
 *
 */
const get_myBilan   = ({doc,fildAriane}) => { appstore.dispatch({ type : "SET_FIL_ARIANE", fildAriane  : { ...fildAriane, target: "dashboard", etude: doc._id } }); }
const yesOrNo       = (param)                => { showModal        ({ modal: "yesorno", param }); }
const check         = ({doc,mycompo   }) => {
    //console.log ("CHECK DOC",  doc);
    const myCrud = new CRUDcompo({...doc, ...mycompo});
    myCrud.save({doc: {status: 'checked', ...doc}});
}
const mydelete      = ({doc,mycompo   }) => {
    //console.log ("DELETE DOC", doc);
    const myCrud = new CRUDcompo({...doc, ...mycompo});
    myCrud.delete(doc);
}
export const CellAction = (props) => {
    const {rowdata,actions,modal} = props;
    const type          = rowdata['type'];
    const mycompo       = myFabrik.getCompo(type);
    const extraprops    = ('__workflow__' in mycompo && 'extraprops' in mycompo.__workflow__ && mycompo.__workflow__.extraprops?mycompo.__workflow__.extraprops:null);

    const formname      = ('formname' in mycompo?mycompo.formname:null);
    const extra         = (b)     => (extraprops?extraprops({type, confront: 'button', formname, doc:rowdata, name: b}):null);

    const fildAriane    = appstore.getState()['fildAriane'];

//mymetalog ("ROWDATA",rowdata);
    return (
        <div className="d-flex justify-content-around">
            {(actions.indexOf('edit' )<0?null:<button type="button" className="btn btn-sm btn-primary" {...extra('edit' )} onClick={() => { editModal  ({doc:rowdata, modal     })                       } } id={"edit-button"  +rowdata._id}><i className="fa fa-edit"      /></button>)}
            {(actions.indexOf('trash')<0?null:<button type="button" className="btn btn-sm btn-danger " {...extra('trash')} onClick={() => { yesOrNo    ({doc:rowdata, yes:() => {mydelete (rowdata);} }) } } id={"delete-button"+rowdata._id}><i className="fa fa-trash-alt" /></button>)}
            {(actions.indexOf('check')<0?null:<button type="button" className="btn btn-sm btn-success" {...extra('check')} onClick={() => { check      ({doc:rowdata, mycompo   });                      } } id={"check-button" +rowdata._id}><i className="fa fa-check"     /></button>)}
            {(actions.indexOf('bilan')<0?null:<button type="button" className="btn btn-sm btn-info   " {...extra('bilan')} onClick={() => { get_myBilan({doc:rowdata, fildAriane})                       } }                                 ><i className="fa fa-book"      /></button>)}
        </div>
    );
}
CellAction.propTypes = {
    rowdata:    PropTypes.object,
    modal:      PropTypes.string,
    actions:    PropTypes.array,
}

export const CellFromEchelle = (props) => {
    const {rowdata,myField=null,myValue=null,tracktype=null} = props;
    const  montype  = (tracktype?tracktype:'type' in rowdata?rowdata.type:null);
    const  mavaleur = (myValue?myValue:myField && myField in rowdata?rowdata[myField]:null);
    const  pEchelle = (montype in hEchelle && mavaleur in hEchelle[montype]?hEchelle[montype][mavaleur]:null);
    return (mavaleur && montype && pEchelle
    ?   <span>{'tip' in pEchelle?pEchelle.tip:'label' in pEchelle?pEchelle.label:mavaleur}</span>
    :   <span>-</span>
    );
}
CellFromEchelle.propTypes = {
    rowdata:    PropTypes.object,
    tracktype:  PropTypes.string,
    myField:    PropTypes.string,
    myValue:    PropTypes.string,
}

export const CellFromData = ({rowdata,myField,tracktype=null}) => {
    try {
        const  myCellCompo= myFabrik.getCompo(tracktype?tracktype:rowdata.type);
        return <span>{myCellCompo.tFields[myField].others.data[rowdata[myField]]}</span>
    }
    catch(error) {
        console.error("CELLFROMDATA",error);
        return <span>_{myField}_</span>
    }
}
CellFromData.propTypes = {
    rowdata:    PropTypes.object,
    tracktype:  PropTypes.string,
    myField:    PropTypes.string,
}

const DefaultIcon = <i className="fa fa-plus     text-muted" />
const QuestionIcon= <i className="fa fa-question text-muted" />

export const IconFromData = (props) => {
    const {rowdata,myField=null,myValue=null,otherClass="",tracktype=null} = props;
    try {
	// on peut avoir { monfield: mavaleur } et { monfield: {value: mavaleur} }
        const  entry      = (myValue
            ?myValue
            :mynature (myField, rowdata)
        );
	const  track      = (tracktype?tracktype:'type' in rowdata?rowdata.type:myField);
	const  pEchelle   = (track && track in hEchelle?hEchelle[track]:null);
        const  myIcon     = (entry && pEchelle && entry in pEchelle && 'icon' in pEchelle[entry]?pEchelle[entry].icon:DefaultIcon);
        return <span className={'text-center '+otherClass}>{myIcon}</span>;
    }
    catch(error) {
        console.error("ICONFROMDATA",error,rowdata,myField,otherClass,tracktype);
        return <span className={'text-center '+otherClass}>{QuestionIcon}</span>;
    }
}
IconFromData.propTypes = {
    rowdata:    PropTypes.object,
    tracktype:  PropTypes.string,
    myField:    PropTypes.string,
    myValue:    PropTypes.string,
    otherClass: PropTypes.string,
};

// Appelé avec les paramètres: myCompo = {somecomponenttorender} rowdata={myrowdata} decoField={namefieldfordeco}
export const TipsFromData = (props) => {
    const {myCompo, myField=null, rowdata, myValue=null} = props;
    const myElem = (myCompo
        ?   <span>{React.createElement(myCompo, props, null)}</span>
        :   (myField && myField in rowdata)
        ?   <span>{rowdata[myField]}</span>
        :   myValue
        ?   <span>{myValue}</span>
        :   <span>&quot;-&quot;</span>
    );
//console.log ("TIPTIP TipsFromData",myElem,props);
//overlay  = <MyPopover mystruct={props.rowdata} {...props}/>
    return (
        <MyOverlayTrigger mystruct={rowdata} {...props}>
        {myElem}
        </MyOverlayTrigger>
    );
}
TipsFromData.propTypes = {
    rowdata: PropTypes.object,
    myCompo: PropTypes.func,
    myField: PropTypes.string,
    myValue: PropTypes.string,
};

