import React                    from 'react';

import {setechelles}            from '../methanol/Communs';

//import shield                   from '../assets/shield.svg';
import {FontAwesomeIcon}        from '@fortawesome/react-fontawesome';
//import {faCheckSquare}          from '@fortawesome/free-solid-svg-icons';
//import {faExclamationTriangle}  from '@fortawesome/free-solid-svg-icons';
import {faFileAlt}              from '@fortawesome/free-solid-svg-icons';
import {faFileArchive}          from '@fortawesome/free-solid-svg-icons';
import {faFileExcel}            from '@fortawesome/free-solid-svg-icons';
import {faFilePdf}              from '@fortawesome/free-solid-svg-icons';
import {faFilePowerpoint}       from '@fortawesome/free-solid-svg-icons';
import {faFileWord}             from '@fortawesome/free-solid-svg-icons';
//import {faShareSquare}          from '@fortawesome/free-solid-svg-icons';
//import {faShieldAlt}            from '@fortawesome/free-solid-svg-icons';
//import {faSignature}            from '@fortawesome/free-solid-svg-icons';
//import {faSpinner}              from '@fortawesome/free-solid-svg-icons';
//import {faStickyNote}           from '@fortawesome/free-solid-svg-icons';
import {faUserCheck}            from '@fortawesome/free-solid-svg-icons';
import {faUserClock}            from '@fortawesome/free-solid-svg-icons';
import {faUserCog}              from '@fortawesome/free-solid-svg-icons';
import {faUserTimes}            from '@fortawesome/free-solid-svg-icons';
import {faUser}                 from '@fortawesome/free-solid-svg-icons';
//import {faWindowClose}          from '@fortawesome/free-solid-svg-icons';


//import {faMicrosoft}            from '@fortawesome/free-brands-svg-icons';

import logo from '../assets/images/logo.svg';

//import {Patientez}              from '../Communs';

// Utilisé pour avoir des svg de la bonne hauteur et largeur
export const sizeprops = {height: "40px", width:"40px"};

//const hListeScenarioStrategique = {
//    'direct'                        : {"label": 'Direct'                   , "icon" : <FontAwesomeIcon icon={faUser         }  {...sizeprops}/>, tip : "Scénario se réalise en direct"             , },
//    'via_ecosysteme'                : {"label": "Via l'écosystème"         , "icon" : <FontAwesomeIcon icon={faUser         }  {...sizeprops}/>, tip : "Scénario se réalise à travers l'écosystème", },
//}

//const hEchelle0a5        = [...Array(5+1).keys()].reduce ((a,v) => {a[v  ]={label:v  };return (a)},{});
//const hCycleOperationnel = [1,3,6,12].reduce ((a,v) => {a[v  ]={label:v  };return (a)},{});



const statut_protection = {
    'draft'          : {"label": 'En cours de définition'   , 'icon': <i className="grey   fa       fa-sticky-note"          {...sizeprops} />, tip : "Etat brouillon"           , variant:'warning',   },
    // 'draft'          : {"label": 'Brouillon'               , "icon" : logo, tip : "Etat brouillon"       , },
    'signstarted'    : {"label": 'Certificat en préparation', "icon": <i className="jaune  fa       fa-file-signature"       {...sizeprops} />, tip : "Certificat en préparation", variant:'primary',   },
    'otpsent'        : {"label": 'OTP envoyé'               , "icon": <i className="noir   fa       fa-share-square"         {...sizeprops} />, tip : "En attente d'OTP"         , variant:'secondary', },
    'signcanceled'   : {"label": 'Certificat annulé'        , "icon": <i className="rouge  fa       fa-window-close"         {...sizeprops} />, tip : "Certificat abandonné"     , variant:'danger',    },
    'signerror'      : {"label": 'Certificat en erreur'     , "icon": <i className="rouge  fa       fa-exclamation-triangle" {...sizeprops} />, tip : "Certificat en erreur"     , variant:'danger',    },
    'otprepeat'      : {"label": 'Erreur OTP'               , "icon": <i className="rouge  fa       fa-exclamation-circle"   {...sizeprops} />, tip : "Erreur OTP"               , variant:'danger',    },
    'otpconfirmed'   : {"label": 'OTP confirmé'             , "icon": <i className="green  fa-solid fa-square-check"         {...sizeprops} />, tip : "OTP confirmé"             , variant:'success',   },
    'signendedok'    : {"label": 'Oeuvre protégée'          , "icon": <i className="green  fas fa-shield-alt violet"         {...sizeprops} />, tip : "Oeuvre protégée"          , variant:'success',   },
    'finaldoc'       : {"label": 'Certificat disponible'    , "icon": <i className="green  fas fa-shield-alt violet"         {...sizeprops} />, tip : "Certificat disponible"    , variant:'success',   },
    'ziploading'     : {"label": 'Chargement zip'           , "icon": <>
                                                                      <i className="violet fas fa-shield-alt"                {...sizeprops} />
                                                                      +
                                                                      <i className="violet fa-solid fa-spinner fa-pulse"     {...sizeprops} />
                                                                      </>,                                                                      tip : "Chargement zip"       , variant:'success',   },
    'proofdoc'       : {"label": "Certificat"               , "icon": <>
                                                                      <i className="green  fas fa-shield-alt"                {...sizeprops} />
                                                                      +
                                                                      <i className="green  fa-solid fa-box-archive"          {...sizeprops} />
                                                                      </>,                                                                      tip : "Zip disponible"       , variant:'success',   },
}


const preuve_identite = {
    'carte_identite'         : {"label": "Carte nationale d'identité"  , "icon" : logo, tip : "Carte d'identité"         , },
    'permis de conduire'     : {"label": 'Permis de conduire'          , "icon" : logo, tip : "Permis de conduire"       , },
    'passeport'              : {"label": 'Passeport'                   , "icon" : logo, tip : "Passeport"                , },
    'securite_sociale'       : {"label": 'Carte de sécurité sociale'   , "icon" : logo, tip : "Carte de sécurité sociale", },
}

const verify_profil = {
    'draft'       : { 'label': "Identité à vérifier"              , 'icon': <FontAwesomeIcon icon={faUser}              {...sizeprops} className='text-warning'/>, 'label_short': "A vérifier" , 'tip': "Identité à  vérifier" },
    'checkstart'  : { 'label': "Démarrage vérification d'identité", 'icon': <FontAwesomeIcon icon={faUserCog}           {...sizeprops} className='text-info'   />, 'label_short': "Démarrage"  , 'tip': "Identité en cours de vérification" },
    'checkdone'   : { 'label': "Vérification d'identité réalisée" , 'icon': <FontAwesomeIcon icon={faUserClock}         {...sizeprops} className='text-primary'/>, 'label_short': "En cours...", 'tip': "Attente des résultats..." },
    'checkendedok': { 'label': "Identité vérifiée oK"             , 'icon': <FontAwesomeIcon icon={faUserCheck}         {...sizeprops} className='text-success'/>, 'label_short': "Validé !"   , 'tip': "Identité validée" },
    'checkerror'  : { 'label': "Erreur lors de la vérification"   , 'icon': <FontAwesomeIcon icon={faUserTimes}         {...sizeprops} className='text-danger' />, 'label_short': "Erreur !"   , 'tip': "Identité invalide" },
}

const hContentType = {
    'application/pdf'                                                                    : { 'label': "Pdf"                                                    , 'icon': <FontAwesomeIcon {...sizeprops} /*pulse*/ icon={faFilePdf           } className='text-danger' />, 'label_short': "Fichier pdf"                   , 'tip': "Fichier pdf."                        },
    'application/vnd.ms-excel'                                                           : { 'label': "Fiche de calcul Excel xlsx"                             , 'icon': <FontAwesomeIcon {...sizeprops} /*pulse*/ icon={faFileExcel         } className='text-success'/>, 'label_short': "Feuille de calcul excel xlsx"  , 'tip': "Fiche de calcul excel xslx."         },
    'application/vnd.oasis.opendocument.presentation'                                    : { 'label': "Présentation libre office"                              , 'icon': <FontAwesomeIcon {...sizeprops} /*pulse*/ icon={faFilePowerpoint    } className='text-warning'/>, 'label_short': "Présentation libre office"     , 'tip': "Fichier présentation libre office."  },
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'          : { 'label': "Présentation PowerPoint"                                , 'icon': <FontAwesomeIcon {...sizeprops} /*pulse*/ icon={faFilePowerpoint    } className='text-warning'/>, 'label_short': "Présentation office"           , 'tip': "Fichier présentation office."        },
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'                  : { 'label': "Fiche de calcul Excel xlsx"                             , 'icon': <FontAwesomeIcon {...sizeprops} /*pulse*/ icon={faFileExcel         } className='text-success'/>, 'label_short': "Feuille de calcul excel xlsx"  , 'tip': "Fiche de calcul excel xslx."         },
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'            : { 'label': "Document Word"                                          , 'icon': <FontAwesomeIcon {...sizeprops} /*pulse*/ icon={faFileWord          } className='text-primary'/>, 'label_short': "Document Word"                 , 'tip': "Document Word"                       },
    'application/zip'                                                                    : { 'label': "Zip"                                                    , 'icon': <FontAwesomeIcon {...sizeprops} /*pulse*/ icon={faFileArchive       } className='text-info'   />, 'label_short': "Fichier zip (chemin de preuve)", 'tip': "Fichier zip (chemin de preuve)."     },
    'text/csv'                                                                           : { 'label': "Fiche de calcul Excel xlsx"                             , 'icon': <FontAwesomeIcon {...sizeprops} /*pulse*/ icon={faFileExcel         } className='text-success'/>, 'label_short': "Feuille de calcul excel xlsx"  , 'tip': "Fiche de calcul excel xslx."         },
    'text/plain'                                                                         : { 'label': "Fiche texte"                                            , 'icon': <FontAwesomeIcon {...sizeprops} /*pulse*/ icon={faFileAlt           } className='text-info'   />, 'label_short': "Fichier texte"                 , 'tip': "Fichier texte"                       },
};

const hListeEchelles = {
    'content_type'           : hContentType,
    'preuve_identite'        : preuve_identite,
    'profil_verif'           : verify_profil,
    'protection'             : statut_protection,
}

// Cet appel met à disposition hEchelles et hKeysEchelles depuis methanol/communs
export const F_SetEchelles = () => { setechelles (hListeEchelles); }
export const F_GetEchelles = () => { Object.keys (hListeEchelles); }

