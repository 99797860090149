import React                from 'react';
import {Dropdown, Container}           from 'react-bootstrap';

import MenuItem             from '@material-ui/core/MenuItem';

import {showModal}          from '../methanol/Modal';

import {authorwebsite}      from '../Communs';
import logo                 from '../assets/images/logo.svg';
import {MyCredits}          from '../components/Credits';

// Quand 0 crédits, il faudrait faire apparaître un bouton "buy credits"
export const MenuUp = ({handleClose, disconnect}) => {
    return(
    <Container className='mb-3'>
    <nav className="navbar-bg">
        <div className="navbar navbar-expand-lg d-flex justify-content-between">
        {/* <div className="col-3 flex fondblanc sideshadow borderbottomrouge">
            <a href={authorwebsite} target="_blank" rel="noopener noreferrer">
                <img id="logo" src={logo} alt={authorwebsite} style={{margin: 'auto', height:'80px'}}/>
            </a>
        </div> */}
        <a href={authorwebsite} target="_blank" rel="noopener noreferrer">
                <img id="logo" src={logo} alt={authorwebsite} style={{margin: 'auto', height:'80px'}}/>
        </a>
        <div className="d-flex justify-content-end">
            <MyCredits/>
            <Dropdown>
                <Dropdown.Toggle variant="none" id="parametre-user" >
                    <i className="bi bi-person h4"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <MenuItem onClick={()=>{handleClose();showModal({modal: "profil", param: {_id: "mon_profil"}})}}>Mon profil              </MenuItem>
                    <MenuItem onClick={()=>{handleClose();showModal({modal: "password_verif"                    })}}>Mot de passe            </MenuItem>
                    {/* <MenuItem onClick={()=>{handleClose();showModal({modal: "liste_fichiers"                    })}}>Mes fichiers            </MenuItem> */}
                    {/* <MenuItem onClick={()=>{handleClose();showModal({modal: "liste_profils"                     })}}>Mes co-auteurs          </MenuItem> */}
                    <MenuItem onClick={()=>{handleClose();showModal({modal: "PGP"                               })}}>Politique de Gestion de la Preuve</MenuItem>
                    <MenuItem onClick={()=>{handleClose();showModal({modal: "CGU"                               })}}>Conditions Générales d&apos;Utilisation</MenuItem>
                    <div className="dropdown-divider"></div>
                    <li className="dropdown-item" onClick={disconnect}>Me déconnecter</li>
                </Dropdown.Menu>
            </Dropdown>
            <button className="navbar-toggler d-none " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            </div>
        </div>
        </div>
    </nav>
    </Container>
    );
}


