import React                from 'react';
import axios                from 'axios';

import TextField            from '@material-ui/core/TextField';
import {makeStyles}         from '@material-ui/core/styles';
import {Alert}              from 'react-bootstrap';

import {getdefaultbddentry} from './methanol/Bdd';
import {RenderMetaToast}    from './methanol/MetaToast';

import {store}              from './Store';

const Promise              = require('lie');

// URL pour accéder à commons
export const couchcst      = (process.env.REACT_APP_REMOTE_COUCH_CST?process.env.REACT_APP_REMOTE_COUCH_CST:"https://erasm.app/d/")

export const authorwebsite = (process.env.REACT_APP_AUTHOR_WEB?process.env.REACT_APP_AUTHOR_WEB:'https://erasm.io');
export const BASE_APIREST  = (process.env.REACT_APP_APIREST   ?process.env.REACT_APP_APIREST   :'https://erasm.app/r/');
export const contactus     = authorwebsite+"/contactus";
export const bddcommons    = (process.env.REACT_APP_DBCOMMONS ?process.env.REACT_APP_DBCOMMONS :"author-commons")

export const nbmaxparpreuve= 10;

export const tsideconst = {
    cni_recto: { type: 'SET_CNI_RECTO',       entry: 'id',       idcheckio: 'frontImage' },
    cni_verso: { type: 'SET_CNI_VERSO',       entry: 'id',       idcheckio: 'backImage'  },
    pst_volet: { type: 'SET_PASSEPORT_RECTO', entry: 'passport', idcheckio: 'frontImage' },
};

export const renderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
}) => (
    <TextField
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
    />
);

/*
<TextField error id="filled-error" label="Error" defaultValue="Hello World" variant="filled" />
    */

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export const Patientez = () => {
  return (
      <div className="d-flex justify-content-center h-100">
      <div className="spinner-border m-auto" style={{width: "3rem", height: "3rem"}} role="status">
          <span className="sr-only">Loading...</span>
      </div>
      </div>
  );
}

const urlshop = authorwebsite+"/shop";
export const openshop = () => {window.open(urlshop, '_blank');}
export const AskForCredits = () => {
    return (
        <div className="justify-content-center">
            <a href={urlshop} target="_blank" rel="noopener noreferrer" className="ml-lg-5 pb-2">
                <Alert variant="danger">
                    <p className="p-lg">Vous n&apos;avez plus de crédits...</p>
                    <p className="p-lg">Cliquez ici pour recharger</p>
                </Alert>
                {/* <Button variant="danger">
                      <p className="p-lg">Vous n'avez plus de crédits...</p>
                      <p><img src={credit} alt="credits_left" /></p>
                  </Button> */}
            </a>
        </div>
    );
}

//console.log ("BASE_APIREST", BASE_APIREST);
export const gotoMenu             = (target)           => { store.dispatch({type: "SET_FIL_ARIANE",         fildAriane: {target}         }); }
export const setNbCredits         = (nbcredits)        => { store.dispatch({type: "SET_NB_CREDITS",         nbcredits                    }); }
export const setMonProfil         = (monProfil)        => { store.dispatch({type: "SET_MON_PROFIL",         monProfil                    }); }
export const setVerifiedProfil    = (verifiedProfil)   => { store.dispatch({type: "SET_VERIFIED_PROFIL",    verifiedProfil               }); }
export const setStatusProfilVerif = (newstatus)        => { store.dispatch({type: "SET_STATUS_PROFILVERIF", statusProfilVerif: newstatus }); }
export const setCGU               = (cgu)              => { store.dispatch({type: "SET_CGU",                cgu                          }); }
export const setPGP               = (pgp)              => { store.dispatch({type: "SET_PGP",                pgp                          }); }
export const setdocCGUtoconfirm   = (doccgutoconfirm)  => { store.dispatch({type: "SET_CGUTOCONFIRM",       doccgutoconfirm              }); }
export const setdocPGPtoconfirm   = (docpgptoconfirm)  => { store.dispatch({type: "SET_PGPTOCONFIRM",       docpgptoconfirm              }); }
export const defaultAction        = (k,fildAriane=null)=> { store.dispatch({type: "SET_FIL_ARIANE",         fildAriane: { ...fildAriane, target: k } }); }


export const F_EnvoyerOtp = ({doc=null, otp}) => {
    const dbname             = getdefaultbddentry();
    const mystate            = store.getState();
    const {monProfil  =null} = mystate;
    const {inscription=null} = monProfil;
    const {email      =null} = inscription;
    const docid              = (doc && '_id' in doc?doc._id:null);
    const url                = BASE_APIREST+'otp'
    const uuidtoast          = "OTP";
    RenderMetaToast("OTP","Validation d'OTP","warning",uuidtoast);
    return Promise.resolve()
        .then (() => {
            if (!url) {
                RenderMetaToast("OTP","Validation d'OTP...","danger",uuidtoast);
                throw new Error ({text: "F_EnvoyerOtp erreur dbname ou docid", dbname, docid, doc});
            }
            return axios.post (url, {email, dbname, docid, otp})
        })
        // then let it flow
}

export const file2base64 = (face, binaryattachment, keepheader=false) => new Promise ((resolve, reject) => { 
    const uuidtoast = RenderMetaToast("Préparation","Préparation du justificatif "+face,"warning");
    //let input       = e.target;
    //let myfilename  = e.target.value;
    //let myfile      = binaryattachment.files[0];
    let myfile      = binaryattachment[0];
    //Name pas utilisé
    //const name      = (myfile && 'name' in myfile?myfile.name:null);
    const type      = (myfile && 'type' in myfile?myfile.type:null);
    if (myfile) {
        try {
                let reader = new FileReader();
                // pst_volet[0]      de type File    
                // pst_volet[0].name => "passeport.jpg" 
                // pst_volet[0].type => "image/jpeg" 
                reader.onload = (justificatif) => {
                const  target            = justificatif.target;
                const  result            = target.result.split('base64,');
                const  data /* base64 */ = (keepheader?target.result:result.length>0?result[1]:target.result);
                const  content_type      = type
                return resolve ({face, _attachment: {content_type, data}});
            }
            // JFBJFB TBC doublon avec readMyFile ??
            reader.readAsDataURL(myfile);
            // reader.readAsArrayBuffer(myfile);
        }
        catch (e) {
            RenderMetaToast("Préparation","Erreur lors de l'import de votre justificatif", "danger", uuidtoast);
//            console.error ("ChargeJustificatifId (import): fichier incorrect ou erreur lors de l'import", myfile);
            return reject ("Error during base64 conversion");
        }
    } else {
        return reject ("No file");
    }
})

//axios.defaults.baseURL = BASE_APIREST;
//console.log ("BASE_APIREST",BASE_APIREST,process.env)
export const F_vDemarrerProtection = (doc) => {
//console.log ("F_DEMANDERPROTECTION", BASE_APIREST, doc);
    const dbname      = getdefaultbddentry();
    const mystate     = store.getState();
    const {monProfil  = null} = mystate;
    const {inscription= null} = monProfil;
    const {email      = null} = inscription;
    const docid       = (doc && '_id' in doc?doc._id:null);
    const url         = BASE_APIREST+'signature';
//console.log ("PROTECTION", email, dbname, docid, url, doc);
    return Promise.resolve()
        .then (axios.post (url, {email, dbname, docid}))
        .then  (res   => res)
        .catch (error => { throw error; });
}

export const verifprofil = (typejustif, doc) => {
//console.log ("verifprofil", typejustif, doc);
    const dbname      = getdefaultbddentry();
    const mystate     = store.getState();
    const {monProfil  = null} = mystate;
    const {inscription= null} = monProfil;
    const {email      = null} = inscription;
    const url         = BASE_APIREST+'profil_verif'
    const justificatifs = (doc?Object.keys(doc).reduce((a,k) => {a.push(doc[k]); return a;},[]):[]);
    const uuidtoast   = RenderMetaToast("Service","Demande de validation d'identité en cours...","info");
    if (justificatifs.length>0) {
        Promise.all(Object.keys(doc).map(face => file2base64(face, doc[face])))
            .then (images => {
                // On passe de {face: _attachment: {content_type, data}}
                // à
                // _attachments: {
                //     face: {content_type, data},
                //     face: {content_type, data},
                //     ...
                // }
                return (images.reduce ((a, image) =>{
                    a[image.face] = image._attachment;
                    return (a);
                }, {}));
            })
        .then (_attachments => {
//console.log ("ATTACHMENTS", _attachments);
            return axios.post (url, {email, dbname, typejustif, _attachments})
        })
        .then (() => {
            RenderMetaToast("Service","Demande envoyée...","info", uuidtoast);
        })
        .catch (error => {
            RenderMetaToast("Service","Impossible de valider l'identité, erreur technique, réessayez...","danger", uuidtoast);
            throw (error)
        })
        ;
    //const docid       = (doc && '_id' in doc?doc._id:null);
    //RenderMetaToast("Service","Demande de vérification de profil","info");
//console.log ("verifprofil", email, dbname, docid, url, doc);
    } else {
        return ("No data for profil_verif");
    }
}

// type = CGU ou PGP
// phase= start ou confirm
export const processcommons = (type, phase, params) => {
    // params doit contenir:
    // - Pour phase "start": {email, dbname, idcgu, revcgu}
    // - Pour phase "confirm" ...
    const url = BASE_APIREST+'commons/'+type+'/'+phase;
    //console.log ("processcommons", {type, phase, params, url});

    return axios.post (url, params);
}

export const mysha256sumFromBuffer = (monbuffer) => {
    const crypto = window.crypto || window.msCrypto; // pour IE 11
    return Promise.resolve()
        .then (() => {
            return crypto
                .subtle
                .digest("SHA-256", monbuffer)
        })
        .then  (buf => {
            const mysha256sumcomputed = Array
                .prototype
                .map
                .call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2)))
                .join('');
            return (mysha256sumcomputed);
        })
        // then let it flow
}

export const mysha256sum = (mystring) => {
    const monutf8 = new TextEncoder("utf-8").encode(mystring);
    return Promise.resolve()
        .then  (() => {return mysha256sumFromBuffer (monutf8)})
        // then sha256 computed...
        // the let it flow
}

export const RenderField = (props) => {
    const { input, placeholder, type, blocRenderField="col-lg-12 p-0 my-3", meta: { touched, error }, className=null, disabled=null } = props;
    return (
    <div className={blocRenderField}>
        <input {...input} type={type} placeholder={placeholder}
            {...{disabled} }
            {...(className?{className}:{className:"form-control"})}
         />
        {touched && error && <span>{error}</span>}
    </div>
    );
}

