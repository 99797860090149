import React                 from 'react';
import {useState, useEffect} from 'react';
import QRCode                from 'qrcode';

import {Patientez}          from '../Communs';
import {getdefaultbddentry} from '../methanol/Bdd';
import {MetaDoc}            from '../methanol/MetaDoc';
import {myFabrik}           from '../methanol/LaFabrik';
//import {CellAction}         from '../methanol/Datatable';
import {TipsFromData}       from '../methanol/Datatable';
import {IconFromData}       from '../methanol/Datatable';
import {CellFromEchelle}    from '../methanol/Datatable';
import {FilesFromData}      from '../methanol/Diesel';
//import {styles}             from '../assets/proof-styles';
import {BASE_APIREST}       from '../Communs';


const opts = {
  errorCorrectionLevel: 'H',
  type: 'image/jpeg',
  //quality: 0.5,
  margin: 5,
  color: {
    dark:"#694189",
    //light:"#F7F2F5FF"
  }
}

const authorsoloapirest = BASE_APIREST;
const urlverif          = (_id) => [authorsoloapirest, 'verify', _id].join('/');

const monqrcode = (doc) => {
    const _id   = (doc && '_id'  in doc?doc._id :null);
    //const _rev  = (doc && '_rev' in doc?doc._rev:null);
    const url             = urlverif   (_id);
    const [myqr, setMyQr] = useState (<Patientez />);

    useEffect(() => {
        // Produire une url du type https://erasm.app/verify/3c5659f4-44ed-40d7-a208-a568b27a21f1
        QRCode.toDataURL(url, opts)
        .then  (res => {
            setMyQr (<img src={res} className={"qrinthelist"} style={{alignSelf: 'center', height:'50px', width:'50px'}} />);
        })
        .catch (err => {
            console.log ("ERROR qrcode:",{_id,url,err});
            setMyQr("error...");
        })
    },[]);

    return (myqr);
}

const majoliedate   = (r) => {
    const isodate   = (r && 'created_at' in r?r.created_at                    :""     );
    const absdate   = (isodate               ?Date.parse(isodate)             :null   );
    const joliedate = (absdate               ?new Date(absdate).toLocaleDateString():isodate);
    //const joliedate = (absdate               ?new Date(absdate).toLocaleString():isodate);
    return (joliedate);
}

export const TableauOeuvres = () => {
    const mystatus = r => <TipsFromData  rowdata={r} myField='status' myCompo={IconFromData} tipCompo={CellFromEchelle} />;
    const myddoc   = r => (r && 'status' in r && ["finaldoc", "proofdoc"].includes(r.status)?<FilesFromData rowdata={r} />:"...");
    //const myaction = r => <CellAction    rowdata={r} actions={['trash']} />;
    const nicedate = r => majoliedate (r);
    return (
    <div>
        <h3 className="py-3 text-left">Tableau de bord</h3>
        <MetaDoc {...myFabrik.getCompo('protection')}
            bddentry   = {getdefaultbddentry()}
            thClass    = 'thead-light'
            tableClass = 'table table-striped table-bordered table-hover table-sm m-auto'
            addbutton  = {null}
            hDescDt    = {{
                "denomination"          : { title: "Dossier",                             },
                "description"           : { title: "Descriptif",                          },
                "result.terminateDate"  : { title: "Date",              contenu: nicedate },
                "status"                : { title: "Statut",            contenu: mystatus },
                "download_doc"          : { title: "Certificat",        contenu: myddoc   },
                "_id"                   : { title: "QR code",           contenu: r => monqrcode(r) },
                //"action"                : { title: "Action",            contenu: myaction },
            }}
        />
    </div>
    );
}

//let    iNbAppelsInit = 0;
//export const F_vInitOeuvres = () => {
//    if (iNbAppelsInit<1) {
//	// Rien à faire
//    }
//    else {iNbAppelsInit = 1;}
//}
