import React, { useState } from 'react';

export const Input = ({ label, error, inputProps }) => {
  const [show, setShow] = useState(false);
  const isPassWord = inputProps.type === 'password';
  const myClass = show ? 'fa fa-eye' : 'fa fa-eye-slash';

  return (
    <div className='inputMargin'>
      <div className="inputContainer inputContainerFlexRow">
        <div className="containerFlexCol inputKeyW">
          <label className="inputLabel">{label}</label>
          <input
            className="shadow-none inputStyle"
            type={isPassWord && show ? 'text' : inputProps.type}
            //onChange={inputProps.onChange}
            {...inputProps}
          />
        </div>
        {isPassWord && (
          <span onClick={() => setShow(!show)}>
            <i style={{background:'#fcfcfc', padding:'10px'}} className={myClass}></i>          
          </span>
        )}
      </div> 
      {error && <span style={{color: "#c13515", fontSize: '12px'}}>{error}</span>}
    </div>
  );
};
