import React               from 'react';
import {useState}          from 'react';
import {Link}              from 'react-router-dom';
import {useParams}         from "react-router-dom";
import {useNavigate}       from "react-router-dom";
import axios               from 'axios';
import VerifiedUserIcon    from '@material-ui/icons/VerifiedUser';

import {BASE_APIREST}      from '../Communs';
import {authorwebsite}     from '../Communs';
import {contactus}         from '../Communs';
import {loginToMyApp}      from '../methanol/Login';
import {RenderMetaToast}   from '../methanol/MetaToast';
import {EyePasswordInput}  from '../methanol/Login';
import {email as regemail} from './noRender/validate';
import {regPassword}       from './noRender/validate';
//import {BtnCustom}         from './btn_custom/BtnCustom';
import {Input}             from './input/Input'
import logo                from '../assets/images/logo_landing.svg';

//console.log('contactus', contactus)

const MyFront = ({Charge}) => {
    return (
	<main className="landing p-1">
	<div className="col-12 login-banner m-0 pt-4 p-md-0 text-center">
        <div>
		<span className="d-flex col-5 col-lg-1 p-0" style={{margin: 'auto'}}> 
            <img id="logo" src={logo} alt="/" className="col-12 col-lg-8 py-5 m-auto" />
		</span>
        </div>
        <div style={{margin: '15px;'}}>
        <h4 className="bold">{"Protégez vos créations"}</h4>
        </div>
        <section className="col-10 col-md-5 m-auto" style={{maxWidth: '400px'}}>
            {React.createElement('div', null, Charge)}
        </section>
        <hr/>
        <a href={authorwebsite} target="_blank" rel="noopener noreferrer">
        <button className="btn silverColor">By erasm.io</button>
        </a>
	</div>
	</main>
    );
}

const LoginBannerForm = props => {
    const {onSubmit}  = props;
    const dfltemail   = {email: '', length: 0};
    const [myemail,   setemail  ] = useState (dfltemail);

    const onChange     = (e) => {
        const v = e.target.value;
        setemail(v?{email: v, length:(v?v.length:0)}:dfltemail);
    }
    //console.log('email:', myemail)
    const myregexpemail = regemail(myemail.email);
    const Charge =
    <>
        <form onSubmit={onSubmit}>
            <Input 
                label="Email" 
                inputProps={{
                    onChange, type: "email", autoFocus: true, id: "email"
                }} 
                error={myregexpemail}
            />  
            <EyePasswordInput name='password' id="password" />
            <button type="submit" className="btnGreen">Connexion</button>
        </form>
        <div style={{display: 'flex', flexDirection:'column', marginTop: '34px'}}>
            <Link to="/forgot" className="btn login-banner">Mot de passe oublié ?</Link>
            <a href={contactus} target="_blank" rel="noopener noreferrer" className="">
                Inscription
            </a>
        </div>
        
    </>

    return <MyFront Charge={Charge}/>;
}

const loginurl = BASE_APIREST+'login';
export const LoginBanner = () => {
    const submit = e => {
        e.preventDefault();
        const email    = document.getElementById("email").value;
        const password = document.getElementById("password").value;
        loginToMyApp ({loginurl, email, password});
    }
    if ('REACT_APP_AUTO_LOGIN' in process.env && 'REACT_APP_AUTO_PASSWORD' in process.env) {
        const values = {loginurl, 'email': process.env.REACT_APP_AUTO_LOGIN, 'password': process.env.REACT_APP_AUTO_PASSWORD};
        loginToMyApp (values);
    }
    return (<LoginBannerForm onSubmit={submit}/>);
}

// <Link to="/forgot">Mot de passe oublié ?</Link>

// Demander un jeton par email pour un nouveau mot de passe
export const ForgotPasswd = () => {
    const [emailState, setEmail] = useState('');
    const handleChangePasswd = () => {
        axios.post (BASE_APIREST+'recover', { email: emailState })
        .then  (() => {
            RenderMetaToast("register", "Si votre adresse est reconnue, vous allez recevoir un email de confirmation", "success");
        })
        .catch (error => {
            console.log("ERROR registerConst", error);
            RenderMetaToast("register", "Erreur... disposez-vous dejà d'un compte?", "danger");
        });
    }
    const myregexpemail = regemail(emailState);
    //console.log("email: ", emailState)
    const Charge = (
        <div className="mt-5">
            <h5 className="py-2 pd-md-5">Demander un nouveau mot de passe</h5>
            <Input 
                label="Email" 
                inputProps={{
                    onChange: (e) => setEmail(e.target.value), type: "email", autoFocus: true
                }} 
                error={myregexpemail}
            />  
   
            <button className="btnGreen" onClick={handleChangePasswd}>Envoyer</button>
            <p className="pt-5">
                <Link to="/" className="btn whiteColor">Déja un compte ? Cliquez ici pour vous connecter...</Link>
            </p>
        </div>
    );
    return <MyFront Charge={Charge}/>;
}

export const RecoverPasswd = (/*props*/) => {
    const {email, tokencfm} = useParams();
    //const email    = props.match.params.email;
    //const tokencfm = props.match.params.token;
    const [newpasswd, setNewpasswd] = useState ("");
    const history  = useNavigate();

    const handleChangePasswd = e => {
        e.preventDefault();
        axios.post(BASE_APIREST+"recoverconfirm", {email, tokencfm, newpasswd})
            .then(() => {
                RenderMetaToast ("Mot de passe", "Votre mot de passe a bien été enregistré", "success");
                setTimeout(() => { history("/"); }, 3000);
            })
            .catch(error => {
                console.log("ERROR recover", error);
                RenderMetaToast("Mot de passe", "Erreur lors de la modification de mot de passe...", "danger");
            });
    }

    const rp     = regPassword(newpasswd);
    const Charge =
        <div className="pt-3">
            <h3 className="py-2 pd-md-5">Changement de mot de passe</h3>
            <div className="form-group">
                <label htmlFor="newpasswd">Mot de passe</label>
                <EyePasswordInput name='newpasswd' className="form-control" onChange={e => {setNewpasswd (e.target.value);}} autoFocus />
                {rp.message}
            </div>
            <button className="btn btn-warning"
                style={rp.isInvalid?{display:'none'}:{}}
                onClick={handleChangePasswd}
                >
                Changer
            </button>
            <p className="pt-5">
                <Link to="/" className="whiteColor">Retour à l&apos;accueil</Link>
            </p>
        </div>

    return (
        <MyFront Charge={Charge}/>
    );
}

export const Welcome = () => {
    const history = useNavigate();
    const Charge = (
        <div className="mt-5">
            <h5 className="py-2 pd-md-5">Bienvenue sur la plateforme ERASM</h5>
			<p className="mt-2 green-text">Votre email est maintenant validé. <VerifiedUserIcon /></p>
            <p>Surveillez votre boîte email, vous devriez recevoir un mail à usage unique pour créer votre password.</p>
            <p>Vous allez être rédigé automatiquement vers l&apos;accueil d&apos;ici 10 secondes</p>
            <p className="pt-5">
                <Link to="/" className="btn whiteColor">Déja un compte ? Cliquez ici pour vous connecter...</Link>
            </p>
        </div>
    );
    setTimeout(() => { history("/"); }, 10000);
    return <MyFront Charge={Charge}/>;
}

