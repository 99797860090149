import React                from 'react';
import {useState}           from 'react';

import axios                from 'axios';

import {appstore}           from './Communs';
import {infosdbs}           from './Bdd';
import {defoltdb}           from './Bdd';
import {methanolPouchDb}    from './Bdd';
import {RenderMetaToast}    from './MetaToast';

export const LOGGED_CONSTS = {
    LOGIN            : "LOGIN",
    LOGOUT           : "LOGOUT",
}
const notlogged = { logged: false, info: null, bddentry: null };
export const loggedInitState = { logged: notlogged }

export const EyePasswordInput = (props) => {
    // props => name, id, onChange, className, ...
    

    const [show, setShow] = useState(false);
    const myClass = show ? 'bi bi-eye' : 'bi bi-eye-slash';

      return ( 
        <div className='inputMargin'>
            <div className="inputContainer inputContainerFlexRow">
                <div className="containerFlexCol inputKeyW">
                <input
                    className="shadow-none inputStyle"
                    type={show ? 'text' : 'password'}
                    {...props}
                />
                </div>
            
                <span onClick={() => setShow(!show)}>
                    <i style={{background:'#fcfcfc', padding:'10px'}} className={myClass + " fs-5"}></i>
                </span>
            </div>
        </div>
      )

}

const loggedreducer = (state=notlogged, action) => {
    const bddfromroles = (roles) => {
        const tlistbddauthor = roles.filter (r => r.startsWith('author-'));
        const ret = (tlistbddauthor.length>0?tlistbddauthor[0]:null);
        return (ret);
    }
    switch (action.type) {
        case LOGGED_CONSTS.LOGIN: {
            const res      = (action && 'res'   in action && action.res?action.res         :null);
            const data     = (res    && 'data'  in res    && res.data  ?res.data           :null);
            const roles    = (data   && 'roles' in data   && data.roles?data.roles         :null);
            const bddentry = (roles                                    ?bddfromroles(roles):null);
            return {logged: true , info: data, bddentry};
        }
        case LOGGED_CONSTS.LOGOUT:
            return notlogged;
        default:
            return state;
    }
}
export const loggedReducers  = { logged: loggedreducer, }

export const logoutFromMyApp = () => {
    appstore.dispatch ({type: "LOGOUT"});
}

const loginWithPouchdb    = ({bddentry=null, email, password}) => {
    if (!infosdbs['__logincouchdb__'])
    {
        infosdbs.__logincouchdb__           = {};
        infosdbs.__logincouchdb__[defoltdb] = methanolPouchDb['logindb'](bddentry)
    }
    const db = infosdbs.__logincouchdb__[defoltdb];
    return (db.logIn(email, password));
}

const loginWithUrl        = ({loginurl=null, email, password}) => {
    return (axios.post (loginurl, {email, password}));
}

export const loginToMyApp = (props) => {
    const {withpouchdb=false} = props;
    const wichlogin = (withpouchdb?loginWithPouchdb:loginWithUrl);
        wichlogin (props)
        .then  (res => {
            //RenderMetaToast("Login","Login réussi","success");
            appstore.dispatch ({type: "LOGIN", res});
        })
        .catch ((error)  => {
            RenderMetaToast("Login","Connexion impossible (login, mot de passe ou pas de connexion à la base de données)","danger");
            // TBC => no-network indicator
            console.error ("LOGIN ERR",error);
        });
}

export const disconnectFromMyApp = () => {
    if ('__logincouchdb__' in infosdbs && infosdbs.__logincouchdb__)
    { infosdbs.__logincouchdb__.logOut(); }
    appstore.dispatch ({type: "LOGOUT"});
}

