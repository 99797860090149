import React                from 'react';
import {connect}            from 'react-redux';
//import {reset}              from 'redux-form';
//import {Field}              from 'redux-form';

// IMAGES
//import shield               from '../assets/shield-white.svg';
//import check                from '../assets/check-mark.svg';
// import credit_white         from '../assets/credit_white.svg';
//import caddy                from '../assets/caddy.svg';
//import caddy_white          from '../assets/caddy_white.svg';
//import oeuvre_protege       from '../assets/oeuvre_protege.svg';
//import oeuvre_protege_white from '../assets/oeuvre_protege_white.svg';
//import allDoc               from '../assets/images/allDoc.svg';
// import call                 from '../assets/call.svg';

//import {store}              from '../Store';
import {openshop}           from '../Communs';
import {defaultAction}      from '../Communs';
import {DashboardFichiers}  from './Fichiers';
import {DashboardProfils}   from './Profils';
import {EcranIdentites}     from './ProfilVerif';
import {TableauOeuvres}     from './Oeuvres';
import {UneProtection}      from './Protection';

import {Nav}                from 'react-bootstrap';
import {BtnMenu} from './btn_menu/BtnMenu'


const MenuListerFichiers = ({active}) => {
   const handleAction = ()=> defaultAction('lister_fichiers');
   return (
       <BtnMenu title={"Mes documents"} iconName="bi bi-card-list" active={active}
            btnProps={{
                onClick: () => handleAction(),
            }} 
        />
   );
}

const MenuAnuaire = ({active}) => {
   const handleAction = ()=> defaultAction('lister_profils');
   return (
       <BtnMenu title={"Co-auteurs"} iconName="bi bi-people" btnBorderNone active={active}
        btnProps={{
            onClick: () => handleAction(),
        }}
       /> 
   );
}

/*  A complèter pour faire une doc à la jsdoc

    # La sidebar va servir à 3 choses :
    - Voir si notre profil est validé ou pas, si ce n'est pas le cas on peut se rediriger vers la page de validation du profil.
    - Voir combien de crédits il nous reste : si positif ET si profil validé, on peut démarrer un processus de protection d'oeuvre.
    (Le paiement se fera avant l'accès à l'application ? sur Odoo ? Afin d'avoir des crédits avant la vérification du profil).
    - Voir l'ensemble des oeuvres protégées (component Datatable)
    - Bouton assistance qui permettra de contacter directement back-office ?

    # Données
    PROPS devant venir dans la sidebar :
    - verifiedProfil: le profil de l'utilisateur est-il vérifié ? 2 ou 3 états ? :
        - Oui
        - Non
        - En cours de vérification (?)
    - creditsLeft: Nombre de crédits restants (int)
*/

//        <Button className="bx-shadow blue-erasm-bg" onClick={handleAction}>
//            <h6>Identité confirmée</h6>
//            <p className="font-little">{info}</p>
//            <img src={check} alt="profil_verified" />
//        </Button>

const MenuProfilVerifConnected = ({verifiedProfil, nbcredits, active}) => {
    const handleAction   = () => {
        // Reset form
        // Then goto form
        //store.dispatch (reset('profil_verif')); // Reset du formulaire
        defaultAction('profil_verif');
    }
    
    return (nbcredits>0
        ?
            verifiedProfil
            ?
            null
            :
            <BtnMenu title={"Identification"} iconName="bi bi-person-bounding-box" active={active}
                btnProps={{
                    onClick: () => handleAction(),
                }} 
            />
        :
        null
    );
}
const MenuProfilVerif = connect (
    state => ({
        verifiedProfil: state.verifiedProfil,
        nbcredits     : state.nbcredits,
    })
)(MenuProfilVerifConnected);

// <p className="font-little d-flex justify-content-between">
//     <span>Crédit{esse} restant{esse}:</span>
//     <span><b>{nbcredits}</b><img src={credit_white} alt="credits_left" className="px-1" /></span>
// </p>
/* <p className="font-medium m-0">Protéger mon oeuvre <img src={white ? add_white : add} className="w-10 pt-2" alt="add_protection" /></p> 
<img src={add_white} className="w-10 pt-2" alt="add_protection" /> */
const MenuProtegerOeuvreConnected = ({nbcredits /*, white*/, active}) => {
    // const esse           = (nbcredits && nbcredits>1?'s':null);
    const handleAction   = () => {
        // Reset form
        // Then goto form
        //store.dispatch (reset('protection')); // Reset du formulaire
        defaultAction('proteger_oeuvre');
    }
    // const disabled       = (verifiedProfil?null:{disabled:'disabled'});
        // <Button {...disabled} className={className} variant="success b-" id="protect_oeuvre" onClick={handleAction}>
            //<img src={oeuvre_protege_white} alt="oeuvre_protege" className="oeuvre_protege_icon" />
            //<img src={white ? caddy_white : caddy} alt="caddy" />
    return (
        (nbcredits > 0)
        ?
        // <Button className="backgroundjaune mb-4 w-100 d-flex flex-column justify-content-center" id="protect_oeuvre" onClick={handleAction}>
        //     <i className="bi bi-save2 noir"></i>
        //     <p className="font-medium noir">Protéger une oeuvre</p>
        // </Button>
        <BtnMenu title={"Générer un certificat"} iconName="bi bi-save2" active={active}
            btnProps={{
                onClick: () => handleAction(),
                id: "protect_oeuvre" 
            }} 
        />
        :
        // <Button className="backgroundrouge mb-4 w-100 d-flex flex-column justify-content-center"
        //     id="protect_oeuvre" onClick={openshop} rel="noopener noreferrer" variant="danger"
        // >
        //     <p className="font-medium m-0">Achetez des crédits en cliquant ici</p>
        //     <i className="bi bi-cart-plus"></i>
        // </Button>
        <BtnMenu title={"Achetez des crédits"} iconName="bi bi-cart-plus" active={active}
            btnProps={{
                onClick: () => openshop(),
                id: "protect_oeuvre" 
            }} 
        />
    );
}

const MenuProtegerOeuvre = connect(
    state => ({
        verifiedProfil: state.verifiedProfil,
        nbcredits     : state.nbcredits,
    })
)(MenuProtegerOeuvreConnected);

const listermesoeuvresprotegees = () => defaultAction('lister_oeuvres');
const MenuListerOeuvres = ({active}) => {

    return (
        <BtnMenu title={"Tableau de bord"} iconName="bi bi-folder-check" active={active}
            btnProps={{
                onClick: () => listermesoeuvresprotegees(),
            }} 
        />
    );
}

export const hTopMenus = {
    profil_verif    : { label: "Profil à vérifier"          , action: defaultAction , fa: MenuProfilVerif   , compo: EcranIdentites   },
    // profil_verif_deux    : { label: "Profil à vérifier"          , action: defaultAction , fa: MenuProfilVerif   , compo: UneProtectionConnected  },
    proteger_oeuvre : { label: "Générer un certificat"      , action: defaultAction , fa: MenuProtegerOeuvre, compo: UneProtection    },
    lister_oeuvres  : { label: "Liste des preuves"          , action: defaultAction , fa: MenuListerOeuvres , compo: TableauOeuvres   },
    lister_fichiers : { label: "Documents analysés"         , action: defaultAction , fa: MenuListerFichiers, compo: DashboardFichiers},
    lister_profils  : { label: "Annuaire co-auteurs"        , action: defaultAction , fa: MenuAnuaire       , compo: DashboardProfils },
}

const tKeysTopMenu = Object.keys(hTopMenus);

export const MenuGauche = ({fildAriane}) => {
    const target    = (fildAriane && 'target' in fildAriane?fildAriane.target:'proteger_oeuvre');
    const navRed = 12;
    return (
        <Nav className="sidebar sideshadow fondblanc text-center align-items-center"
            style={{borderTopRightRadius: navRed, borderBottomRightRadius: navRed, height: 'fit-content', overflow: 'hidden'}}
        >
            <div className="w-100" style={{display: 'flex', flexDirection: 'column'}} >
                {tKeysTopMenu.map ((k,i) => {
                    const MyFa = hTopMenus[k].fa;
                    //console.log('bool: ', k===target, 'target: ', target, 'k: ', k)
                    return (<MyFa key={i} active={target === k ? true : false}/>);
                })}
            </div>
        </Nav>      
    );
}

