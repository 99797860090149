import React                  from 'react';
import {useState}             from 'react';
import {connect}              from 'react-redux';
import {Form}                 from 'react-final-form';
import {Field}                from 'react-final-form';

import {FontAwesomeIcon}      from '@fortawesome/react-fontawesome';
import {faCheck}              from '@fortawesome/free-solid-svg-icons';

import Grid                   from '@material-ui/core/Grid';
import Button                 from '@material-ui/core/Button';
import ToggleButton           from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup      from '@material-ui/lab/ToggleButtonGroup';
//import {FormControl}          from '@material-ui/core';
//import {FormControlLabel}     from '@material-ui/core';
//import {FormLabel}            from '@material-ui/core';
//import {Radio}                from '@material-ui/core';
//import {RadioGroup}           from '@material-ui/core';

//import {myFabrik}             from '../methanol/LaFabrik';
//import {MetaDoc}              from '../methanol/MetaDoc';
//import {RenderMetaToast}      from '../methanol/MetaToast';
import {showModal}            from '../methanol/Modal';

//import {store}                from '../Store';
//import {tsideconst}           from '../Communs';
import {useStyles}            from '../Communs';
//import {renderTextField}    from '../Communs';
import {setStatusProfilVerif} from '../Communs';
import {AskForCredits}        from '../Communs';
import {Patientez}            from '../Communs';
import {gotoMenu}             from '../Communs';
import {verifprofil}          from '../Communs';
import {file2base64}          from '../Communs';

import {isconfirmed}          from './Protection';
import {AcceptCguOrPgp}       from './Protection';


const urladobescan  = 'https://acrobat.adobe.com/fr/fr/mobile/scanner-app.html';
const AdobeScan     = <a href={urladobescan} target='_blank' rel="noopener noreferrer">Adobe scan</a>

//const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const MonUploadButton = ({ face, texte, /*action,*/ input }) => {
    const [image, setImage] = useState (null);
    const monid = "raised-button-"+face;
    const onInputChange = (e) => {
        e.preventDefault();
        const files = [...e.target.files];
        const nb    = (files && files.length>0?files.length:null);
        input.onChange(files);
        if (nb) {
            const keepheader = true;
            file2base64 (face, files, keepheader)
            .then  (att   => {/*console.log ("OK image", att);*/setImage(att._attachment.data)})
            .catch (error => console.log ("Erreur image", error))
            ;
        }
    };
////    const myHandleChange = (face, e) => {
////        handleFileUpload(face, e, action);
////    }
////                onChange={e => myHandleChange(face, e)}
        //data-allowed-file-extensions={"pdf png jpg jpeg"}
//    return (
//        <input type="file"
//            style={{display: 'inline'}}
//            onChange={onInputChange}
//            accept="application/pdf, image/jpeg, image/png"
//        />
//    )
//}
        //{...input}
    return (
        <div>
            {image
                ?
            <Grid item xs={6} className="m-auto">
                <object data={image} title={texte} width="100%" aria-label="Upload"></object>
            </Grid>
                :   null
            }
            <Grid item xs={image?6:12} className="m-auto">
                <input
                    type="file"
                    id={monid}
                    onChange={onInputChange}
                    accept="application/pdf, image/jpeg, image/png"
                />
                <label htmlFor={monid} className="font-little my-2">
                    {/*
                        Uploader votre document (pdf, jpg, png) ici.
                        Taille maximale : 2 Mo
                    */}
                    <Button component="span" className="input-file-cta mt-1 btnTextGreen textTransform">
                        <i className="bi bi-upload me-2"></i>
                        {texte}
                    </Button>
                </label>
            </Grid>
        </div>
    );
}

//const mustbechecked = value => value ? undefined : 'Required';

const CniRectoVerso = () => {
//                <span className="bold">
//                    {(uploaded && 'cni_recto' in uploaded && uploaded.cni_recto) 
//                    ? <object data={uploaded.cni_recto} title="pdf-view" width="100%"></object>
//                    : "Recto de votre pièce d'identité"}
//                </span>
//                <span className="bold">
//                    {(uploaded && 'cni_verso' in uploaded && uploaded.cni_verso) 
//                    ? <object data={uploaded.cni_verso} title="pdf-view" width="100%"></object>
//                    : "Verso de votre CNI"
//                    }
//                </span>

    const onSubmit = values => verifprofil ('id', values);
    //console.log('invalid: ', invalid, 'submitting: ', submitting, 'pristine: ', pristine)
    return (
        <Form
            onSubmit = {onSubmit}
            //enableReinitialize=true
        >
        {(formprops) => {
            const {handleSubmit, invalid, submitting, pristine, values} = formprops;
            const min2scan = ('cni_recto' in values && 'cni_verso' in values && values['cni_recto'].length>0 && values['cni_verso'].length>0);
            return (
                <form form='cni_recto_verso_upload' onSubmit = {handleSubmit} >
                    <div style={{padding: "10px"}}>
                    {"Justificatifs acceptés: carte d'identité et carte de séjour"}
                    </div>
                    <Grid item xs={6} className="m-auto" style={{maxWidth: "550px"}}>
                        <Field type="file" name='cni_recto' component={MonUploadButton} face='cni_recto' texte="Recto de la pièce d'identité"/>
                    </Grid>
                    <Grid item xs={6} className="m-auto" style={{maxWidth: "550px"}}>
                        <Field type="file" name='cni_verso' component={MonUploadButton} face='cni_verso' texte="Verso de la pièce d'identité"/>
                    </Grid>
                    <Button
                        id={'cni-submitButton'}
                        disabled={invalid || submitting || pristine || !min2scan}
                        className="mt-4 btnGreen textTransform"
                        type="submit"
                    >
                        <span>Transmettre</span>
                    </Button>
                </form>
        );
        }}
        </Form>
    );
}

const Passeport = () => {
//                <span className="bold pt-3">
//                    {(uploaded && 'pst_volet' in uploaded && uploaded.pst_volet) 
//                    ? <object data={uploaded.pst_volet} title="pdf-view" width="100%"></object>
//                    : "Volet n°2 du passeport"}
//                </span>
    const onSubmit = values => verifprofil ('passport', values);
    return (
        <Form
            onSubmit = {onSubmit}
            //enableReinitialize: true
        >
        {(formprops) => {
            const {handleSubmit, invalid, submitting, pristine, values} = formprops;
            const min1scan = ('pst_volet' in values && values['pst_volet'].length>0);
            return (
        <form form='passeport_upload' onSubmit = {handleSubmit} >
            <div style={{padding: "10px"}}>
                Volet n° 2 de votre passeport
            </div>
            <Grid item xs={12} className="m-auto" style={{maxWidth: "800px"}}>
                <Field type="file" name='pst_volet' style={{display: 'inline'}} component={MonUploadButton} face='pst_volet' texte='Volet n°2 du passeport'/>
            </Grid>
            <Button
                id={'verifier_profil-submitButton'}
                disabled={invalid || submitting || pristine || !min1scan}
                className="mt-4 btnGreen textTransform"
                type="submit"
            >
                <span>Transmettre</span>
            </Button>
        </form>
        );
        }}
        </Form>
    );
}

// Types https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
const ChargeJustificatifId = ({justifchoice}) => {
    const classes     = useStyles();

    const hjustif = {
        'id'      : <CniRectoVerso/>,
        'passport': <Passeport    />
    }
    const mychoice = (justifchoice && Object.keys(hjustif).includes(justifchoice)?justifchoice:'id');
    return (
        <div className={classes.root}>
        <Grid container spacing={3}>
            <Grid item xs={12} className="secondaryText mt-2">
                Formats acceptés: pdf, png, jpg<br/>
                Taille maximale: 2Mo<br/>
                Vous pouvez par exemple utiliser {AdobeScan}.<br/>
            </Grid>

            <div className="mx-auto d-flex p-1 mb-2 authBackground" >
                { hjustif[mychoice] }
            </div>
        </Grid>
        </div>
    );
}

/*
import {makeStyles} from '@material-ui/core/styles';
const useStylesSelect = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

import InfoIcon             from '@material-ui/icons/Info';
import Popover              from '@material-ui/core/Popover';
import InputLabel           from '@material-ui/core/InputLabel';
import MenuItem             from '@material-ui/core/MenuItem';
import FormControl          from '@material-ui/core/FormControl';
import Select               from '@material-ui/core/Select';

import recto                from '../assets/recto.jpg';
import verso                from '../assets/verso.jpg';
import passport             from '../assets/volet_passport.jpg';

                <SimplePopover >
                    <div>
                        <img className="logo-identity" src={recto} alt="logo" />
                        <img className="logo-identity" src={verso} alt="logo" />
                    </div>
                </SimplePopover>
                <SimplePopover >
                    <div className="text-center">
                        <span>Scanner le volet n°2 du passeport</span> 
                        <img src={passport} alt="logo" />
                    </div>
                </SimplePopover>
const SimplePopover = ({children}) => {
 
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <InfoIcon color="primary" onMouseEnter={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="m-1">{children}</div>
      </Popover>
    </div>
  );
}
     * */
const hJustifs = {
    id:       {text: <p className="justificatif">Carte d&apos;identité</p>, back: "back_justif recto_cni px-4 backjustif" },
    passport: {text: <p className="justificatif">Passeport            </p>, back: "back_justif passeport px-4 backjustif" }
}
const ChoixDuJustificatif = ({justifchoice, setJustifChoice}) => {
    //const classes  = useStyles();
    const mychange = (e, mychoice) => setJustifChoice(mychoice?mychoice:'id');
//        <FormControl className="w-100">
//            <FormLabel component="legend">Choix du justificatif</FormLabel>
//            <div className={classes.root}>
//                <Grid container spacing={1}>
//                    {Object.keys(hJustifs).map((justif, i) =>
//                    <Grid key={i} item xs={6}>
//                    <Button style={{maxWidth: '200px'}} component="span"
//                        onClick={mychange}
//                        size={justif === justifchoice?'large':'small'}
//                        className={"mt-1 "+hJustifs[justif].back}>
//                        {hJustifs[justif].text}
//                    </Button>
//                    </Grid>
//                    )}
//                </Grid>
//            </div>
//        </FormControl>
    return (
        <ToggleButtonGroup exclusive value={justifchoice} onChange={mychange} aria-label="text formatting">
            {Object.keys(hJustifs).map((justif, i) => {
                const selected = (justif === justifchoice);
                    // <div  className='containerFlexCol'>
                return (
                        <ToggleButton key={i}  value={justif} aria-label={justif}
                            id={"justif-"+justif}
                            style={{maxWidth: '200px'}} component="span"
                            selected={selected}
                            size={'small'}
                            className={"mx-3 justifBtn" + " " +hJustifs[justif].back}
                        >
                        <i>SPÉCIMEN</i>
                        </ToggleButton>
                   
                );
                    // </div>
            })}
        </ToggleButtonGroup>
    );
}

export const CheckCondsProfilVerified  = (doc) => {
    // Il faut aussi check la date de vérification
    const checkedid    = (doc && 'checkedid' in doc && doc.checkedid?doc.checkedid:null);
    const dateverif    = (checkedid && 'dateverif' in checkedid && checkedid.dateverif ? new Date(checkedid.dateverif) : null);
    const dateNow      = new Date();
    const unjour       = 86400000; // 24 heures*60 minutes*60 secondes*1000 ms
    const centjours    = 1095 * unjour; // 3 ans
    const timeToLive   = dateverif ? centjours - (dateNow - dateverif): null;

    const uid          = (checkedid && timeToLive && timeToLive > 0 && 'uid' in checkedid && checkedid.uid?checkedid.uid:null);

    return (uid);
}

const ErreurProfilRetry = () => {
    const myAction = () => { setStatusProfilVerif ('default'); }
    return (
        <div>
            <div className="alert alert-danger" role="alert">
                <h2>Erreur sur le profil</h2>
                <button onClick={myAction} className="btn btn-success mt-2">Relancer la vérification</button>
            </div>
        </div>
    );
}

const VerifierProfil = () => {
    const [justifchoice, setJustifChoice] = useState ('id');

    return (
        <>
            <h2 className="mb-5">Identifiez-vous</h2>
            <ChoixDuJustificatif  justifchoice={justifchoice} setJustifChoice={setJustifChoice}/>
            <ChargeJustificatifId justifchoice={justifchoice}/>
        </>
    );
}


// La vérification de profil suit les phases suivantes:
// checkstart   : le robot a démarré le check du profil,
// checkdone    : check transmis, en attente du résultat,
// checkendedok : check ok,
// checkerror   : erreur.
const tComposProfilsVerif = {
    //'default'     : <MetaDoc {...myFabrik.getCompo('profil_verif')} />,
    'default'     : <VerifierProfil/>,
    'draft'       : <VerifierProfil/>,
    'checkstart'  : <Patientez/>,
    'checkdone'   : <h2>Vérification en cours</h2>,
    'checkendedok': <h2>Profil reconnu...</h2>,
    'checkerror'  : <ErreurProfilRetry/>,
};
export const tKeysProfilsVerif = Object.keys(tComposProfilsVerif);
const WorkflowVerifProfilConnected = ({statusProfilVerif}) => {
    const iindice = (statusProfilVerif && statusProfilVerif in tComposProfilsVerif?statusProfilVerif:'default');
//console.log ("iindice", iindice);
    return (
        tComposProfilsVerif[iindice]
    );
}
const WorkflowVerifProfil = connect(
    state => ({ statusProfilVerif: state.statusProfilVerif })
)(WorkflowVerifProfilConnected);

            //<MetaDoc {...myFabrik.getCompo('profil')} _id='mon_profil' />
const EcranIdentitesConnected = ({verifiedProfil, nbcredits, cgu, pgp}) => {
//console.log ("verifiedProfil", nbcredits, verifiedProfil);
    if (verifiedProfil) {
        setTimeout(() => {
            gotoMenu("proteger_oeuvre");
            showModal({modal: 'profil', param: {_id: 'mon_profil'}});
        }, 3000);
    }
    const cguandpgpconfirmed = (isconfirmed(cgu) && isconfirmed(pgp));
    return (!cguandpgpconfirmed
        ?   <AcceptCguOrPgp cgu={cgu} pgp={pgp} />
        :   nbcredits>0
            ?   verifiedProfil
                ?   <div className="center">
                        <h2>Profil vérifié<FontAwesomeIcon icon={faCheck}/></h2>
                        <p>Redirection vers l&apos;écran de protection dans 3 secondes</p>
                    </div>
                :   <WorkflowVerifProfil />
            :   <AskForCredits />
    );
}

export const EcranIdentites = connect(
    state => ({
        verifiedProfil: state.verifiedProfil,
        cgu           : state.cgu,
        pgp           : state.pgp,
        nbcredits     : state.nbcredits,
    })
)(EcranIdentitesConnected);

