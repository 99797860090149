import {ListFields}                         from '../methanol/MetaDoc';
import {getdefaultbddentry}                 from '../methanol/Bdd';
//import {getValueFromFormUnderEdit}          from '../methanol/MetaDoc';

//import {store}                              from '../Store';
import {renderTextField}                    from '../Communs';
//import {tsideconst}                         from '../Communs';
//import {MonJustificatif}                    from '../components/ProfilVerif';
//import {ChargePieceIdentite}                from '../components/ProfilVerif';
//import {ValidationSoumissionPieceIdentite}  from '../components/ProfilVerif';
//import {useFromProfils}                     from '../components/Protection';

//import recto                from '../assets/recto.jpg';
//import verso                from '../assets/verso.jpg';
//import passport             from '../assets/volet_passport.jpg';

//const f_Toto = (props) => {
//console.log ("F_TOTO",props);
//    return (null);
//}

// Injection de propriétée dans les button, field, tab rattachés à la gestion de profil
const mondisabled={disabled: true};
const mytPleaseDisable = {
    'button': { 'trash': true },
    'field':  {
        'checkedid.gender':    true,
        'checkedid.firstname': true,
        'checkedid.lastname':  true,
        'inscription.email':   true,
    },
    'tab':    {}
}
export const injection_props_profil = (props) => {
    // Permet de griser des champs qu'on ne peut pas modifier dans mon_profil
    // Exemples:
    // - disable si bouton poubelle et _id==='mon_profil',
    // - disable si champs nom      et _id==='mon_profil.,
    //
    // confront === button|field|tab
    // type     === profil
    // _id      === _id du doc
    // doc      === contenu du profil
    // name     === ['edit','trash','bilan','save','cancel'] pour un button
    //              ['checkedid.firstname','checkedid.lastname',...] pour un profil
    const {_id, type, confront, formname, doc, name} = props;
//console.log ("INJECTION_PROPS_PROFIL", props);
    const okform    = (type && type === 'profil');
    const myid      = (_id ? _id : doc && '_id' in doc && doc._id?doc._id:null);
    // On disable le field s'il est dans la liste
    const mydis     = (okform && myid && confront && name
        && confront in  mytPleaseDisable
        && myid     === 'mon_profil'
        && name     in  mytPleaseDisable[confront] ? true : false);
    if (!okform) { console.error ('ERROR bad use of injection_props_profil: ', formname); }

    return (mydis?mondisabled:null);
}

//const f_InjecterJustif = (doc, face) => {
//    const mystate   = store.getState();
//    const myatchmt  = (face && mystate && face in mystate?mystate[face]:null);
//    const type      = (face && face in tsideconst?tsideconst[face].type:null);
//    if (face && myatchmt) {
//        if (!('_attachments' in doc)) { doc['_attachments'] = {}; }
//        doc._attachments[face] = myatchmt;
//        store.dispatch({ type, myatchmt: null });
//    }
//    return doc;
//}

//const f_InjectRectoVerso = (doc) => {
//    const madate = new Date().toISOString();
//    doc['status'      ] = 'draft';
//    doc['log'         ] = [];
//    doc['log'         ].push({'status': 'draft', 'date_front': madate});
////console.log ("F_INJECTRECTOVERSO", doc);
//    doc = f_InjecterJustif (doc, 'cni_recto');
//    doc = f_InjecterJustif (doc, 'cni_verso');
//    doc = f_InjecterJustif (doc, 'pst_volet');
//    return (doc);
//}

export const compoPasswordVerif = {
    formname: 'password_verif',
    type:     'password_verif',
    title:    'Changer mon mot de passe',
    bddentry: getdefaultbddentry(),
    displaytype: '',
    typeedit : 'inmult',
    tCompos: {
        'Informations'     : { component: ListFields, intitule: 'Informations', others: {
            tFields : {
                'old_password' : { component: renderTextField, intitule: 'Ancien mot de passe'         , others: { required: true } },
                'new_password' : { component: renderTextField, intitule: 'Nouveau mot de passe'        , others: { required: true } },
                'new_password2': { component: renderTextField, intitule: 'Retapez nouveau mot de passe', others: { required: true } },
            }
        } },
    },
};

