import React                     from 'react';
import PropTypes                from 'prop-types';
//import {connect}                 from 'react-redux';
import mermaid                   from 'mermaid';
import * as d3                   from 'd3';

import {uuid}                    from './Communs';
//import {LinkGrafik}              from './GrafikCompoos';
//import {SourceRisqueGrafik}      from './GrafikCompoos';
//import {appstore}                from './Communs';
//import {addfuel}                 from './Bdd';
//import {CRUDcompo}               from './Bdd';
//import {useDocFromId}            from './Bdd';
//import {infosdbs}                from './Bdd';

//import {getValueFromFormUnderEdit}  from './MetaDoc';

//const Promise     = require('lie');
//const createboite = (doc      ,idatelier) => { if (doc   && idatelier && 'type' in doc) {appstore.dispatch ({type: "CREATE_BOITE", doc       , idatelier });} }
//const createlink  = (key,relationships,idatelier) => { if (relationships && idatelier && key          ) {appstore.dispatch ({type: "CREATE_LINK" , key, relationships, idatelier });} }
//const linkson     = (idatelier)           => {appstore.dispatch ({type: "LINK_ON" , idatelier});}
//const linksoff    = (idatelier)           => {appstore.dispatch ({type: "LINK_OFF", idatelier});}

export const GRAFIK_CONSTS = {
    RAZ_ITEM         : "RAZ_ITEM",
    RAZ_LINK         : "RAZ_LINK",
    CREATE_BOITE     : "CREATE_BOITE",
    CREATE_LINK      : "CREATE_LINK",
    LINK_ON          : "LINK_ON",
    LINK_OFF         : "LINK_OFF",
    //LINK_END_CLICKED : "LINK_END_CLICKED",
    //LINK_DEL         : "LINK_DEL",
}
export const grafikInitState = { listeItems: {}, }

const listeItemsReducer = (state={}, action) => {
    switch (action.type) {
        case GRAFIK_CONSTS.RAZ_ITEM: {
            const newStateRaz = {...state};
            const idatelierraz= action.idatelier;
            if (idatelierraz in newStateRaz)     { newStateRaz[idatelierraz] = {}; }
            return newStateRaz;
        }
        case GRAFIK_CONSTS.CREATE_ITEM: {
            const newState = {...state};
            const idatelier= action.idatelier;
            if (!(idatelier in newState))        { newState[idatelier]       = {}; }
            newState[idatelier][action.doc._id] = action.doc;
            return newState;
        }
        default: {
            return state;
        }
    }
}
export const grafikReducers  = { listeItems: listeItemsReducer, }

const Feuille = ({mycompo,item,centrex,centrey,boitesatelier}) => {
    // Créer tous les liens possibles avec les conteneurs adjacents
    // Si ces liens n'existent pas, afficher un link à "false"
    //const sideitem = [item.doc.type,item.doc._id].join ('\t');
    const tListeAdjacents = Object.keys(boitesatelier)
        .filter(k => k !== item.doc._id);
    tListeAdjacents.forEach(k => {
        //const sideba   = [boitesatelier[k].type,boitesatelier[k]._id].join ('\t');
        //const mykey    = (sideitem<sideba?[sideitem,sideba]:[sideba,sideitem]).join('\t');
        const relationships = {};
        relationships[item.doc._id        ] = item.doc.type;
        relationships[boitesatelier[k]._id] = boitesatelier[k].type;
        //createlink (mykey,relationships,item.idatelier);
    });

    return (
        React.createElement(
            mycompo,
            // myuuid: myListBoites[item]['uuid'], type: MyType, doc: null, idatelier: idatelier,
            { item, centrex, centrey },
            null
        )
    );
}
Feuille.propTypes = {
    item:          PropTypes.object,
    mycompo:       PropTypes.element,
    centrex:       PropTypes.number,
    centrey:       PropTypes.number,
    boitesatelier: PropTypes.array,
};

const Container = (props) => {
    //const {width=10, height=800, infosBoite, position, idatelier, listeItems} = props;
    const {type, width=10, height=800, infosBoite, position, idatelier} = props;
    const MyType       = type;
    //const myListBoites = (idatelier in listeItems && MyType in listeItems[idatelier]?listeItems[idatelier][MyType]:[]);
    const boitesatelier= [];
    const myListBoites = Object.keys(boitesatelier)
	.filter (k => boitesatelier[k]['type'] === MyType)
	.map    (k => boitesatelier[k]);
    const containerScale_Y = d3.scaleLinear()
        .domain([0,4])
        .range([0,800]);
//	d3.select('.editme')
//	    .on('click',(e) => {console.log('coucou',e)})
    return (
        <g height="100%" width={width}>
            <g height="100" width={width} stroke='lightgrey'>
                <rect id={"container-"+position} x="0" y="0" height="90" width={width-10} fill={infosBoite.fill}>
                </rect>
                <text x={(width-10)/2|0} y="50" textAnchor="middle" alignmentBaseline="central"
                    fill="black" fontWeight="bold" fontSize='30px'
// A remplacer par une ouverture de la fenêtre modale de création du composant de type MyType???
//                    onClick={()=> appstore.dispatch ({ type: "CREATE_BOITE", idatelier: idatelier, typeBoite: MyType, uuid: "id-"+uuidv4() })}
                >
            {infosBoite.denomination}
		</text>
            </g>
            <g transform={"translate(0,100)"} height="900" width="100%" stroke='lightgrey'>
                <rect id={"container-"+position} x="0" y="0" height={height} fill={infosBoite['fill']} width={width-10} />
                {myListBoites.map((doc, i) => 
                    <g key={i} transform={"translate(0, "+ containerScale_Y(i)+")"} height="200" width="100%">
                        <Feuille mycompo={infosBoite.mycompo} item={{doc,idatelier}} centrex={((width-10)/2|0)} centrey={100} boitesatelier={boitesatelier} />
                    </g> 
                )}
            </g>
        </g>
    );
}
Container.propTypes = {
    width:      PropTypes.number,
    height:     PropTypes.number,
    position:   PropTypes.number,
    infosBoite: PropTypes.object,
    type:       PropTypes.string,
    idatelier:  PropTypes.string,
};

const MaPhase = (props) => {
    const {idatelier, position, type, infosBoite, nbBoites} = props;
//    useEffect (() => {
//	const callback    = (doc) => { console.log ("GrafikAtelier event",doc); }
//	const abo         = 'selector';
//	const selector    = {type};
//	const lebonfil    = appstore.getState().fildAriane;
//	const uuid_partof = (partof && partof in lebonfil?lebonfil[partof]:null);
//	    "link": true,
//	    "relationships.1.type": "chemin_attaque",
//	    "relationships.0.type": "scenario_strategique"
//	if (partof && uuid_partof) {
//	    // Donne { etude: N° d'étude } pour le filtre
//	    selector[partof] = uuid_partof;
//	}
//////	//const iindex = 6;
//        const uuidAbonnement = subscribeToBddChanges ({ abo, selector, callback });
//        return function cleanup() { if (uuidAbonnement) {unsubscribeToBddChanges ({uuidAbonnement: uuidAbonnement})}; }
//    },[type]);
    const viewPort = (1000 / nbBoites)|0; // Le bitwise permet d'avoir un entier
    const containerScale_X = d3.scaleLinear()
        .domain([0, nbBoites           ])
        .range ([1, nbBoites * viewPort]); //On donne 1/4 de 1000 du viewport soit 250 par colonne
    return (
        <g width={viewPort} transform={"translate("+(containerScale_X(position)|0)+", 0)"}>
            <Container type={type} idatelier={idatelier} width={viewPort} infosBoite={infosBoite} position={position} />
        </g>
    );
}
MaPhase.propTypes = {
    type:       PropTypes.string,
    idatelier:  PropTypes.string,
    position:   PropTypes.number,
    nbBoites:   PropTypes.number,
    infosBoite: PropTypes.object,
};

const MesPhases = (props) => {
    const {idatelier, tMesPhases} = props;
    //const _id      = getValueFromFormUnderEdit ('scenario_strategique');
    //const doc      = useDocFromId ({_id});
    // ['srov','valeur_metier','objectif_intermediaire']
    //const phasekeys     = Object.keys(tMesPhases);

    // Injecter les objets srv_v_ov et valeur_metier
//    const tObjects = (doc && 'relationships' in doc?Object.keys(doc.relationships).reduce((a,k)=>[...doc.relationships[k],...a],[]):[]);
//    Promise.all(tObjects.map(elem => myPromiseReadFromId (elem))) // elem de la forme {_id,bddentry}
//    .then  (values => { values.forEach (doc => createboite(doc,idatelier)); })
//    .catch (reason => { console.error (">>>> Grafik rendering impossible <<<<", idatelier, tMesPhases, reason); })

    // Injecter les parties prenantes de l'écosystème ayant accès à la/les valeur(s) métier
//    React.useEffect(() => {
//	const callback = (doc) => {
//	    // Le doc est de type pp_v_vm, il faut maintenant lire le doc de la ou des parties prenantes liées
//	    if ('relationships' in doc && 'partie_prenante' in doc.relationships) {
//		const mycrud = new CRUDcompo({type:'partie_prenante'});
//		doc.relationships.partie_prenante.forEach (s => { mycrud.read ({_id:s._id,then:(doc)=>createboite(doc,idatelier)}) });
//	    }
//	};
//	const pVM      = (doc && 'relationships' in doc && 'valeur_metier' in doc.relationships?doc.relationships.valeur_metier:null);
//	const _ids     = (pVM?pVM.map(vm => vm._id):[]); // _id de la ou des valeur_metier
//	const abo      = 'selector';
//	const selector = {
//	    "link": true,
//	    "relationships.valeur_metier": {"$elemMatch": {"_id":{"$in":_ids}}},
//	    "relationships.partie_prenante": {"$exists": true}
//	};
//        const uuidAbonnement = (_ids.length>0?subscribeToBddChanges ({ abo, selector, callback }):null);
//        return function cleanup() { if (uuidAbonnement) {unsubscribeToBddChanges ({uuidAbonnement: uuidAbonnement})}; }
//    }, [_id,idatelier,doc]);

    const nbBoites = Object.keys(tMesPhases).length;
    return (
        <>
            {Object.keys (tMesPhases).map ((k,i) =>
                <MaPhase key={i} idatelier={idatelier} position={i} type={k} infosBoite={tMesPhases[k]} nbBoites={nbBoites} />
            )}
        </>
    );
}
MesPhases.propTypes = {
    idatelier:  PropTypes.string,
    tMesPhases: PropTypes.object,
};

//const MesLiensConst = ({idatelier,links,linkson,listeItems}) => {
//    const nbboites = (idatelier in listeItems?Object.keys(listeItems[idatelier]).length:0);
////console.log ("LINKS 1",idatelier,links,linkson,nbboites);
//    // Les dispatch et le seek sur les données est fait dans MesPhases
//    if ((!(idatelier in links)) || (linkson!==nbboites)) {
//        return (
//            <>
//            </>
//        );
//    } else {
////console.log ("LINKS 2",links[idatelier]);
//        return (
//            <>
//            {Object.keys(links[idatelier]).map ((k,i)=>
//                <LinkGrafik key={i} idatelier={idatelier} relationships={links[idatelier][k]}/>
//            )}
//            </>
//        );
//    }
//}
//
//const MesLiens = connect(
//    (state, props) => ({
//	listeItems: state.listeItems
//    }),
//)(MesLiensConst);

//const myPromiseReadFromId = ({_id,bddentry}) => {
//    return new Promise ((resolve, reject) => {
//	infosdbs[bddentry].memorydb.get(_id)
//	.then ((doc) => {resolve (addfuel(bddentry,doc)); })
//	.catch((err) => {reject  (err); });
//    });
//}

//Données de test
const masrvov = { "_id": "368bb041-690b-483b-b35d-a8eeae277a66", "type": "ov_v_sr", "link": true, "relationships": { "source_risque": [ { "_id": "a7f0ff45-40a3-4ccb-ab3d-4815f749189b", "bddentry": "friskeeez-7b4d8d86-8e5a-44e6-9c5f-1d39f70f7b7b" } ], "objectif_vise": [ { "_id": "25c1de1b-8978-4d1c-bb37-87d388719793", "bddentry": "friskeeez-7b4d8d86-8e5a-44e6-9c5f-1d39f70f7b7b" } ] }, "etude": "7b4d8d86-8e5a-44e6-9c5f-1d39f70f7b7b", "priorite": "P1", "denomination": "ETA-R&D", "description": "Etatique intéressé par secrets R&D", "commentaire": "Sauf crise majeure, l'achat des produits est une meilleure option pour les états qu'une dépense d'espionnage sur la R&D du produit.\nA ne pas négliger cependant sur certains état potentiellement désireux de donner un coup de pouce à leurs industries médicamenteuses pour leur donner un avantage stratégique.", "motivation": "++", "ressources": "+++", "activite": "+", "pertinence": "elevee" };
//const mavm    = { "_id": "ac547121-eae9-4c36-a71d-7808378d1d48", "type": "valeur_metier", "_rev": "9-4e6d64b3ca60fd4a8a985a28359ebfb6", "etude": "7b4d8d86-8e5a-44e6-9c5f-1d39f70f7b7b", "nature": "processus", "denomination": "P1-R&D", "d": "0", "i": "1", "c": "2", "p": "4", "description": "Recherche & développement (R&D)", "commentaire": "Activité de recherche et développement des vaccins nécessitant :\n■ l’identification des antigènes ;\n■ la production des antigènes (vaccin vivant atténué, inactivé, sous-unité) : fermentation (récolte), purification, inactivation, filtration, stockage ;\n■ l’évaluation préclinique ;\n■ le développement clinique" };
const monervvm= { "_id": "0ed3f648-5f6d-48d1-8fa2-f867efdb92bb", "_rev": "12-004a0514e1e9f0a54abd150de54c3e77", "type": "er_v_vm", "link": true, "relationships": { "valeur_metier": [ { "_id": "1922b23a-531c-4ada-aff5-ba687ce8a344", "bddentry": "friskeeez-7b4d8d86-8e5a-44e6-9c5f-1d39f70f7b7b" } ], "evenement_redoute": [ { "_id": "574913d0-5648-451b-88a9-0467e8228937", "bddentry": "friskeeez-7b4d8d86-8e5a-44e6-9c5f-1d39f70f7b7b" } ] }, "etude": "7b4d8d86-8e5a-44e6-9c5f-1d39f70f7b7b" }
const mapp    = { "_id": "cd273dff-8be4-43bc-8448-0d9826d3dce8", "type": "partie_prenante", "_rev": "9-462c1d41739759cd6e99cbd5daaf0f2b", "etude": "7b4d8d86-8e5a-44e6-9c5f-1d39f70f7b7b", "nature": "client", "denomination": "C1 - Etablissements de santé", "exposition": 9, "fiabilite": 2, "commentaire": "- Secteur public: Centres hospitaliers régionaux (CHR/CHU), Centres hospitaliers (CH), dont anciens hôpitaux locaux, Centres hospitaliers spécialisés en psychiatrie, Autres établissements publics,\n- Secteur privé à but non lucratif, Centres de lutte contre le cancer, Autres établissements à but non lucratif,\n- Secteur privé à but lucratif, Établissements de soins de suite et de réadaptation, Établissements de soins de courte durée ou pluridisciplinaires, Établissements de lutte contre les maladies mentales, Établissements de soins de longue durée.", "description": "Secteur public, privé non lucratif et privé luvratif", "seuildanger": 4.5, "dependance": "3", "penetration": "3", "maturite": "1", "confiance": "2", "selected": "selected" };
const mesDemoData = {
    "368bb041-690b-483b-b35d-a8eeae277a66": { name: "coucou", item: { doc: masrvov  } },
    "0ed3f648-5f6d-48d1-8fa2-f867efdb92bb": { name: "salut" , item: { doc: monervvm } },
    //"ac547121-eae9-4c36-a71d-7808378d1d48": { name: "salut" , item: { doc: mavm     } },
    "cd273dff-8be4-43bc-8448-0d9826d3dce8": { name: "hello" , item: { doc: mapp     } },
}

export const GrafikAtelier = (props) => {
    const {idatelier, rowdata=null, data=null, fieldname='type', fieldvalue='_id', tMesPhases} = props;
    //const _id           = getValueFromFormUnderEdit('scenario_strategique');

    //<MesItems  idatelier={idatelier}                         />
    //console.log ("D3",d3);
    const tPhasesKeys = Object.keys(tMesPhases);
    const nbBoites    = tPhasesKeys.length;
    const viewPort    = (1000 / nbBoites)|0; // Le bitwise permet d'avoir un entier
    const containerScale_X = d3.scaleLinear()
        .domain([0, nbBoites           ])
        .range ([1, nbBoites * viewPort]); //On donne 1/4 de 1000 du viewport soit 250 par colonne
    const containerScale_Y = d3.scaleLinear()
        .domain([0,4])
        .range([0,800]);
    const mesdata = (data
        ?   data.reduce ((a,d) =>
            // Champs value donne le _id
            {const unid=uuid(); a[d['value']||unid] = {name: ('denomination' in rowdata?rowdata['denomination']:unid), item: d}; return (a);},
            {}
        )
        :   mesDemoData);
    const byphase   = {};
    tPhasesKeys.forEach (phase => {
        //byphase[phase] = Object.keys(mesdata).filter(k=>(phase === mesdata[k].item.doc[fieldname])).map (k=>mesdata[k]);
        byphase[phase] = Object.keys(mesdata)
            .filter(k=>(k in mesdata && 'item' in mesdata[k] && phase === mesdata[k].item[fieldname]))
            .map (k=>mesdata[k]);
    })

    const MesLinks = {}
    //    Object.keys (mesdata).forEach (k=> {
    //	const item     = mesdata[k].item;
    //	const sideitem = [item.doc.type,item.doc._id].join ('\t');
    //	const tListeAdjacents = Object.keys(mesdata).filter(l => l !== k);
    //	tListeAdjacents.forEach(l => {
    //	    const itemba   = mesdata[l].item;
    //	    const sideba   = [itemba.doc.type,itemba.doc._id].join ('\t');
    //	    const mykey    = (sideitem<sideba?[sideitem,sideba]:[sideba,sideitem]).join('\t');
    //	    const relationships = {};
    //	    relationships[item.doc._id  ] = item.doc.type;
    //	    relationships[itemba.doc._id] = itemba.doc.type;
    //	    MesLinks[mykey] = {relationships};
    //	});
    //    })
    //console.log ("MESLINKS",MesLinks);
    React.useEffect (() => {
        const myelems = Object.keys(MesLinks).map(k=>MesLinks[k]);
        const s       = document.getElementById(idatelier);
        const abscoordxy = (mycircle) => {
            if (!mycircle) { return ([0,0]); }
            let monpoint = s.createSVGPoint();
            //console.error ("MYCIRCLE",myelems,s,mycircle);
            monpoint.x   = mycircle.getAttribute("cx");
            monpoint.y   = mycircle.getAttribute("cy");
            const thereal  = monpoint
                .matrixTransform(mycircle.getScreenCTM())
                .matrixTransform(s.getScreenCTM().inverse())
            ;
            return ([thereal.x,thereal.y]);
        }
        // ov_v_sr, partie_prenante, valeur_metier
        const tKeysMesPhases = Object.keys(tMesPhases);
        // {ov_v_sr:0, partie_prenante:1, valeur_metier:2}
        const mypos          = tKeysMesPhases.reduce ((a,k,i) => {a[k]=i; return (a)},{});
        const mescoordonnees = (montype,relationships) => {
            //const ctm         = d3.select("#box-"+monid).node().getCTM();
            //const coordonnees = [ctm.e,ctm.f];
            const tkeyssides  = Object.keys(relationships);
            const side1       = tkeyssides[0];
            const side2       = tkeyssides[1];
            const monidsrc    = (montype==="source"?side1:side2);
            const monidtgt    = (montype==="source"?side2:side1);
            const monprefixe  = (mypos[relationships[monidsrc]]<mypos[relationships[monidtgt]]?"#linkRight-":"#linkLeft-");
            //(montype==='source'?"#linkRight-":"#linkLeft-");
            //console.error ("MYCIRCLE",monprefixe,monidsrc,monidtgt,tkeyssides,montype,side1,side2);
            const coordonnees = abscoordxy(d3.select(monprefixe+monidsrc).node());
            return (coordonnees);
        }
        const linkHorizontal = d3.linkHorizontal()
            .source(d => mescoordonnees('source',d.relationships))
            .target(d => mescoordonnees('target',d.relationships))
        ;
        d3.select("#meslinks")
            .selectAll("g")
            .data(myelems)
            .enter()
            .append("path")
            .attr("d",linkHorizontal)
            .attr('stroke', 'black')
            .attr('fill', 'none')
        ;
        //.append("text")
        //.text(d => f_Truc(myelems,d))
        //.text(d=>{const relationships=d.relationships; Object.keys(relationships).map (s=>s+" "+relationships[s]).join(" versus ")})
        //console.log ( "TEST", d3, myelems);
    });

    const MesBoites = ({byphase}) =>
        <g id="mesboites">
            {
                Object.keys(byphase).map ((phase,i) => {
                    //const mycompo = SourceRisqueGrafik;
                    const mycompo = tMesPhases[phase].mycompo;
                    const centrex = containerScale_X(i)+viewPort/2;
                    return byphase[phase].map((elem,j)=>{
                        const centrey = containerScale_Y(j)+180;
                        const item    = elem.item;
                        return React.createElement(
                            mycompo,
                            // myuuid: myListBoites[item]['uuid'], type: MyType, doc: null, idatelier: idatelier,
                            { key:i+'-'+j, item, fieldname, fieldvalue, centrex, centrey },
                            null
                        )
                    });
                })
            }
        </g>
    MesBoites.propTypes = {
        byphase: PropTypes.array,
    };

    //console.log ("BYPHASE", byphase, mesdata, tMesPhases);
    return (
        <div style={{'maxWidth': "1024px", margin: 'auto'}}>
        <svg id={idatelier} viewBox="0 0 1000 1000"
        xmlns="http://www.w3.org/2000/svg">
        <MesPhases idatelier={idatelier} tMesPhases={tMesPhases} />
        <MesBoites byphase={byphase}/>
        <g id="meslinks">
        </g>
        </svg>
        </div>
    );
}
GrafikAtelier.propTypes = {
    idatelier:  PropTypes.string,
    fieldname:  PropTypes.string,
    fieldvalue: PropTypes.string,
    tMesPhases: PropTypes.object,
    rowdata:    PropTypes.object,
    data:       PropTypes.array,
};

//    const graph = ` 
//     graph LR
//      A --- B
//      B-->C[fa:fa-ban forbidden]
//      B-->D(fa:fa-spinner);
//    ` 
//<Mermaid graph={text}/>
mermaid.initialize({startOnLoad: true});
export const Mermaid = ({graph}) => {
    React.useEffect(() => { mermaid.contentLoaded() }, [graph])
    return (
        <div className="mermaid">
        {graph}
        </div>
    );
}
Mermaid.propTypes = {
    graph: PropTypes.string,
};

