import React           from 'react';
import {Suspense}      from 'react';
import ReactDOM        from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import {Route}         from "react-router-dom";
import {Routes}        from "react-router-dom";
import {Navigate}      from 'react-router-dom';

import {connect}       from 'react-redux';
import {Provider}      from 'react-redux';

import logo            from './assets/images/logo_landing.svg';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import 'bootstrap/dist/js/bootstrap.min.js';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import '@fortawesome/fontawesome-free/css/brands.min.css';
import './index.css';

import {ListeMetaToasts}              from './methanol/MetaToast';
import {SaltAndPepper}                from './methanol/Modal';
import {getdefaultbddentry}           from './methanol/Bdd';
import {setdefaultbddentry}           from './methanol/Bdd';
import {okstartapp}                   from './methanol/Bdd';
import {F_PrepareBdd}                 from './methanol/Bdd';
import {F_InjectStore}                from './methanol/Bdd';
import {F_RunBdd}                     from './methanol/Bdd';
import {closemydbs}                   from './methanol/Bdd';
import {closeremainingabonnements}    from './methanol/Bdd';
import {F_InitWorkHooks}              from './methanol/Actions';
import {SilentlyTriggerFrom}          from './methanol/Actions';
import {F_vInjectFabrik}              from './methanol/LaFabrik';
import {F_SetEchelles}                from './model/ListesEtEchelles';
import {F_SetModels}                  from './model/Models';
import {tWorkHooks}                   from './model/Actions';
import {F_InitComponents}             from './model/Components';
//import {F_vInitOeuvres}               from './components/Oeuvres';
import {LoginBanner}                  from './components/LoginBanner';
import {ForgotPasswd}                 from './components/LoginBanner';
import {RecoverPasswd}                from './components/LoginBanner';
import {Welcome}                      from './components/LoginBanner';

import {store}                        from './Store';
import {couchcst}                     from './Communs';
import {bddcommons}                   from './Communs';
import {Patientez}                    from './Communs';
import {initappvalues}                from './App';
import {App}                          from './App';
// import {Identity}          from './components/Identity';
//import {mymetalog}                     from './methanol/Bdd';

//window.Buffer = window.Buffer || Buffer;
const Promise   = require('lie');

const AppLogged = () => {
    // Initialisation des composants et du store dans methanol
    F_SetEchelles     ();
    F_SetModels       ();
    F_vInjectFabrik   (F_InitComponents());
    //F_vInitOeuvres    ();
    initappvalues();

    return (
        <Suspense fallback="loading...">
        <App />
        </Suspense>
    );
}

let tListenPtrs = [];
const thisisthebeginning = (logged) => {
    const bddentry   = (logged && 'bddentry' in logged && logged.bddentry?logged.bddentry:getdefaultbddentry())

//console.log('bddentry', bddentry);
//mymetalog ("INFOSDBS 1");
    Promise.all([
        F_PrepareBdd       ({bddserver: couchcst,  bddentry            }),
        F_PrepareBdd       ({bddserver: couchcst,  bddentry: bddcommons}),
    ])
    .then  (() => {
//mymetalog ("INFOSDBS 2", bddentry);
        setdefaultbddentry (bddentry);
        return Promise.all([
            F_RunBdd       ({bddentry            }),
            F_RunBdd       ({bddentry: bddcommons})
        ])
    })
    .then  (()    => {
//mymetalog ("INFOSDBS 4", logged);
        F_InitWorkHooks ({tWorkHooks});
        // Les trigger sont définis dans tWorkHooks Cf. model/Actions.jsx
        tListenPtrs.push(SilentlyTriggerFrom ({from: 'commons',  bddentry: bddcommons}));
        tListenPtrs.push(SilentlyTriggerFrom ({from: 'customer', bddentry}));
        return;
//mymetalog ("INFOSDBS 5");
    })
    .then  (()    => { return okstartapp (bddentry); })
    .catch (error => console.error (error));
}
const thisistheend       = () => {
//console.log ("THISISTHEEND DEBUT", tListenPtrs);
    if (tListenPtrs && tListenPtrs.length > 0) {
        tListenPtrs.forEach (t => {
//mymetalog ("THISISTHEEND", t);
            t && t.cancel();
        });
    }
    tListenPtrs = [];
    closemydbs ();
    closeremainingabonnements ();
}

const WaitLoading = () =>
    <div style={{height:"600px"}}>
        <div>
            <span className="d-flex col-5 col-lg-1 p-0 w-20 m-auto"
                style={{position: 'relative', animation: 'heartbeat 3s linear 2s infinite alternate'}}>
                <img id="logo" src={logo} alt="/" className="w-100 m-auto" />
            </span>
        </div>
        <Patientez/>
    </div>

const MaRoutePincipaleConnected = (props) => {
    const wecanstart=(props && 'wecanstart'       in props  && props.wecanstart?props.wecanstart:null);
    return (
        wecanstart
        ?   <AppLogged   />
        :   <WaitLoading />
    );
}

const MaRoutePincipale = connect(
    state => ({ wecanstart: state.wecanstart })
)(MaRoutePincipaleConnected);

const NotFound = () => {
    return (
        <Navigate to='/welcome' />
    );
}

/**
 * Fonction principale de l'application
 * */
const FenetreApplicationConnected = (props) => {
    const logged      = (props && 'logged'           in props  && props.logged    ?props.logged    :null);
    const iamlogged   = (props && logged && 'logged' in logged && logged.logged   ?logged.logged   :false);

    if (iamlogged) { thisisthebeginning (logged); }
    else           { thisistheend       (logged); }

    return (
        iamlogged
        ?   <MaRoutePincipale/>
        :   <Routes>
                <Route exact path="/"                   element={<LoginBanner  />}/>
                <Route path="/forgot"                   element={<ForgotPasswd />}/>
                <Route path="/welcome"                  element={<Welcome      />}/>
                <Route path="/recover/:email/:tokencfm" element={<RecoverPasswd/>}/>
                <Route                                  element={<NotFound     />}/>
            </Routes>
    );
}
const FenetreApplication = connect(
    state => ({ logged: state.logged })
)(FenetreApplicationConnected);

const Root = () => {
    return (
        <BrowserRouter>
            <FenetreApplication />
            <ListeMetaToasts />
            <SaltAndPepper   />
        </BrowserRouter>
    );
}

const MyProvider = () => {
    F_InjectStore(store);
    return (
        <Provider store={store}>
            <Root />
        </Provider>
    );
}

try {
    ReactDOM.render(
        <MyProvider />,
        document.getElementById('root'),
    );
}
catch (error) {
    console.log ({text: "ERASM gracefully exiting on", error});
}

