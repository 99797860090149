//import React                  from 'react';
import {ARRAY_ERROR}          from 'final-form';
//import {Field}                from 'react-final-form';

//import {MonPays}              from '../components/Protection';
import {required}             from '../components/noRender/validate';
import {alphaNumeric}         from '../components/noRender/validate';
//import {email}                from '../components/noRender/validate';
import {phoneNumber}          from '../components/noRender/validate';
//import {secteurList}          from '../components/noRender/secteur';
//import {MenuItemConnectedMonProfil} from '../components/SideBar';

import {CRUDcompo}            from '../methanol/Bdd';
import {getdefaultbddentry}   from '../methanol/Bdd';
import {ListFields}           from '../methanol/MetaDoc';
//import {closeMetaToast}       from '../methanol/MetaToast';
import {RenderMetaToast}      from '../methanol/MetaToast';

import {store}                from '../Store';
import {gotoMenu}             from '../Communs';
//import {F_vDemarrerProtection} from '../Communs';
//import {EditFichier}          from '../components/Fichiers';
import {EditProfilCompo}      from '../components/Profils';
import {PGPmodale}            from '../components/Modales';
//import {MyField}              from '../components/Protection';
import {BlocAuteurs}	      from '../components/Protection';
import {BlocOeuvre}           from '../components/Protection';

//import {compoProfilVerif}       from './ProfilsVerif';
//import {injection_props_profil} from './ProfilsVerif';
//import {RenderDropdown}         from '../methanol/Diesel';
//import {compoPasswordVerif}     from './ProfilsVerif';
//import {PasswordChange}         from '../components/Modales';
//import {mymetalog}              from '../methanol/Bdd';

const Promise   = require('lie');

let _tListPropsCompos=null;

//const f_vInjecterPdf = (doc) => {
//    const myattachments = store.getState().oeuvreFiles;
//    doc['_attachments'] = myattachments;
//    store.dispatch({ type: "SET_OEUVRE_FILES", oeuvreFiles: null });
//    return (doc);
//}

// Créer une 'notification' from 'front' pour lancer la protection
const f_vCreerNotifAction = (fromdoc) => {
    const docid  = (fromdoc && '_id' in fromdoc?fromdoc._id:null);
    const date   = new Date().toISOString();
    const myCrud = new CRUDcompo({type:'notification'});
    const doc    = {
        type:   'notification',
        action: 'draft', // draft va faire popper la fenêtre de validation de la protection
        date,
        docid,
        from: 'front'
    }
//console.log ("F_VCREERNOTIFACTION", doc);
    return myCrud.save ({doc})
    .then  (res   => {
        //console.log ("F_VCREERNOTIFACTION OK", res)
        return res;
    })
    .catch (error => {
        //console.log ("F_VCREERNOTIFACTION ERROR", error);
        RenderMetaToast("Notification","Impossible de procéder à l'action "+JSON.stringify(error),"danger");
        throw error; // Propager l'erreur
    })
    ;
}

const f_vInjecterMetaInfosOeuvres = (doc) => {
    const infosoeuvres     = store.getState().oeuvreFiles;
    // Convertir les informations du store (incluent le file et le file format browser);
    //     <hash>: {hashtype, filename, content_type, filesize, file, browserfile};
    // En:
    //     <hash>: {hashtype, filename, content_type, filesize};
    // TBC JFBJFB: c'est ici qu'il faudrait créer des enregistrements séparés
    // de type 'oeuvre' si option de sauvegarde
    //doc['_attachments'] = infosoeuvres;
    return Promise.resolve()
        .then (() => {
            const metainfosoeuvres = (infosoeuvres
                ?   Object.keys(infosoeuvres).reduce((a,k) => {
                    const elem = infosoeuvres[k];
                    delete elem.file;
                    delete elem.browserfile;
                    a[k]=elem;
                    return a;
                },{})
                :   {}
            );
            doc.metainfosoeuvres = metainfosoeuvres;
            store.dispatch({ type: "SET_OEUVRE_FILES", oeuvreFiles: null });
            return (doc);
        })
}

const f_vPreparerProtection = (doc) => {
    const madate = new Date().toISOString();
    if (doc && !('status' in doc)) { doc['status'] = 'draft'; }
    if (doc && !('log'    in doc)) { doc['log'   ] = [];      }
    doc['log'].push({'date_front': madate});
    // JFB à reprendre avec option sauvegarde des données
    // Cf. note dans f_vInjecterMetaInfosOeuvres
    //const docwithpdf = f_vInjecterPdf(doc);
    return (f_vInjecterMetaInfosOeuvres(doc));
}

//const MesSecteurs = () => {
//    return (
//        <Field name='secteur' className="form-control mb-3" component="select">
//            {secteurList.map((s, i) =>
//                <option key={i} value={s.code}>{s.name}</option>
//            )}
//        </Field>
//    )
//}

//let uuidtelwarning = null;
const validateprotection = (values) => {
//console.log ("VALUES", {values});
    const errors = {};
    // TBX JFBJFB check du formulaire
    const signataires  = (values && 'signataires' in values?values['signataires']:null);
    const nbsign       = (signataires?signataires.length:0   );
    const premier      = (nbsign>0   ?signataires[0]    :null);
    const phone        = (premier && 'phone' in premier && premier.phone?premier.phone:null);
    errors.fichiers    = []
    errors.signataires = []
    if (!phone) {
        //errors.signataires = [{phone: 'Le 1er signataire doit avoir un numéro de téléphone'}];
        //errors.signataires = {_error: 'Le 1er signataire doit avoir un numéro de portable'};
        errors.signataires[ARRAY_ERROR] = 'Le 1er signataire doit avoir un numéro de portable';
    } else if (phoneNumber(phone)!==null) {
        //errors.signataires = [{phone: 'Le 1er signataire a un numéro de téléphone mal formatté'}];
        //errors.signataires = {_error: 'Le 1er signataire a un numéro de portable mal formatté'};
        errors.signataires[ARRAY_ERROR] = 'Le 1er signataire a un numéro de portable mal formatté';
    }
    // Cf. https://redux-form.com/6.0.0-rc.1/examples/fieldarrays/ pour les fields
    if (!(values['denomination'] && values.denomination.length>0)) { errors.denomination                = "Une dénomination est requise"    ; }
    //if (!(values['secteur'     ]                                )) { errors.secteur                     = "Le secteur d'activité est requis"; }
    if (!(values['fichiers'    ] && values.fichiers.length    >0)) { errors.fichiers[ARRAY_ERROR]       = 'Au moins un document requis'     ; }
    if (!(values['signataires' ] && values.signataires.length >0)) { errors.signataires[ARRAY_ERROR]    = 'Au moins un signataire requis'   ; }
//    if (!phone) {
//        uuidtelwarning = RenderMetaToast(
//            "Téléphone",
//            <div>Indiquer un <b>numéro de téléphone</b> dans votre profil pour recevoir le <b>SMS</b> de notification en cliquant sur: <MenuItemConnectedMonProfil autoFocus className="bg-warning text-dark text-center m-auto" handleClose={()=>{closeMetaToast(uuidtelwarning)}}/></div>,
//            "warning",
//            uuidtelwarning);
//    }
//console.log ("Test VALID", {errors, values});
    // Retour ok si le 1er signataire a un n° de téléphone valide
    return (errors);
}

export const F_InitComponents = () => {
    _tListPropsCompos = {
        //'profil_verif' : compoProfilVerif(),
        'profil'    : EditProfilCompo,
        'protection': {
            formname: 'protection',
            type:     'protection',
            //title: '1-2-3: fichiers, description, auteurs...',
            //displaytype: 'tabs',
            displaytype:  'stepper',
            typeedit :    'inmult',
            bddentry:     getdefaultbddentry(),
            validate:     validateprotection,
            nosavebutton: true,
            tCompos: {
                'step1'       :         { component: BlocOeuvre,  intitule: "Documents",         others: {} },
                'step2'       :         { component: ListFields,  intitule: "Informations",      others: {
                    tFields : {
                        'denomination': { component: 'input'    , intitule: "Dossier",           validation: [ required, alphaNumeric ] },
                        'description' : { component: 'textarea' , intitule: "Description",       others: { rows    : '5'   } },
//                        'secteur'     : { component: MesSecteurs, intitule: "Secteur",           others: {  } },
                    }
                } },
                'step3'       :         { component: BlocAuteurs, intitule: 'Auteurs',           others: {} },
            },
            initialValues: () => ({
                // TBC JFBJFB mise à jour du n° de portable au démarrage
                signataires: [],
                //signataires: [store.getState().verifiedProfil], // Par défaut sélectionner en signataire le profil actuellement loggé
            }),
            __workflow__: {
                //amidisplayed: referentiel_buttondisplayed,
                //extraprops  : referentiel_extraprops,
                //tButtons: [
                //    { status: 'draft'     , intitule: <span>Valider {IconFromData({rowdata:{status:'production'},myField:'status',tracktype:'status'})}</span>, triggers: [f_vValiderReferentiel , f_vActiverReferentiel] },
                //    { status: 'production', intitule: <span>Archiver{IconFromData({rowdata:{status:'archive'   },myField:'status',tracktype:'status'})}</span>, triggers: [f_vArchiverReferentiel, ] },
                //    { status: 'archive'   , intitule: <span>Activer {IconFromData({rowdata:{status:'production'},myField:'status',tracktype:'status'})}</span>, triggers: [f_vActiverReferentiel , ] },
                //],
                hooks: {
                    // beforesave injecte également le pdf
                    beforesave: (doc)   => {
                        return f_vPreparerProtection (doc);
                    },
                    //beforedelete: f_vSupprimerExigencesCible,
                    postsave  : (doc)   => {
                        gotoMenu ('lister_oeuvres');
                        //console.log ("POST SAVE", doc);
                        return f_vCreerNotifAction (doc);
                    },
                    errorsave : (error) => {
                        RenderMetaToast("Certificat","Impossible de sauvegarder "+JSON.stringify(error),"danger","Certificat");
                        //console.error (error);
                    }
                    //postupdate
                    //postupdate  : f_vCreerExigencesCibleFromReferentiel,
                    // TBC JFBJFB: réactiver ces lignes pour les reprises en erreur
                    //onrowdisplay: (doc) => {
                    //if (doc && 'status' in doc && doc.status==='draft'      ) { showModal({modal: "ok_protection_modale", param: {doc}}); }
                    //if (doc && 'status' in doc && doc.status==='signstarted') { F_vDemarrerProtection (doc);                              }
                    //if (doc && 'status' in doc && doc.status==='otpsent'    ) { showModal({modal: "otp_modale",          param: {doc}}); }
                    //}
                }
            }
        },
//        'fichier'  : {
//            form:  'fichier',
//            type:  'fichier',
//            title: 'Fichier',
//            displaytype: 'tabs',
//            typeedit : 'inline',
//            bddentry: getdefaultbddentry(),
//            tCompos: {
//                'fichier'     : { component: EditFichier, intitule: 'Fichier', others: {} },
//            },
//        },
        'a_propos' : PGPmodale,
    };
    return (_tListPropsCompos);
}
