import React                     from 'react';
import PropTypes                 from 'prop-types';
import {Field}                   from 'react-final-form';
//import Multiselect               from 'react-widgets/Multiselect'
//import DropdownList              from 'react-widgets/DropdownList'
import prettyBytes               from 'pretty-bytes';

import {FontAwesomeIcon}         from '@fortawesome/react-fontawesome';
import {faArrowCircleDown}       from '@fortawesome/free-solid-svg-icons';

import {useDocsFromSelector}     from './Bdd';
import {CRUDcompo}               from './Bdd';
import {infosdbs}                from './Bdd';
import {defoltdb}                from './Bdd';
import {getdefaultbddentry}      from './Bdd';
import {myFabrik}                from './LaFabrik';
import {appstore}                from './Communs';
import {hEchelle}                from './Communs';
import {hKeysEchelle}            from './Communs';
import {Error}                   from './Datatable';
import {TipsFromData}            from './Datatable';
import {IconFromData}            from './Datatable';
import {RenderSelectField}       from './MetaDoc';

//import 'react-widgets/dist/css/react-widgets.css';
//import 'react-widgets/styles.css';

/**
 * Diesel s'occupe de fournir divers composants et fonctions pour intégrer à l'application.
 * Nos dropdown lists
 * @param {props} fieldname Nom du formulaire
 * @param {props} data Sous forme d'objet : {}
 */
export const RenderDropdownInTable = ({rowdata,fieldname}) => {
    const crudCompo   = (rowdata?new CRUDcompo({...myFabrik.getCompo(rowdata['type'])}):null);
    const onMyToggle  = (e) => {
        let   doc     = {...rowdata};
	doc[fieldname]= e.target.value;
        crudCompo && crudCompo.save ({doc});
    };
    const pEchelle   = (fieldname in hEchelle    ?hEchelle[fieldname]    :null);
    const pKeysEcl   = (fieldname in hKeysEchelle?hKeysEchelle[fieldname]:null);
    const myfield    = (rowdata && fieldname in rowdata && pEchelle && rowdata[fieldname] in pEchelle?rowdata[fieldname]:null);
    return (
	<div>
	{myfield && pEchelle?pEchelle[myfield].icon:<span className='m-1'>...</span>}
	<select name={fieldname} onChange={onMyToggle} value={myfield?myfield:""}>
        <option></option>
        { pKeysEcl && pKeysEcl.map ((e,i)=><option key={i} value={e}>{pEchelle[e].label}</option>) }
	</select>
	</div>
    );
}
RenderDropdownInTable.propTypes = {
    rowdata:   PropTypes.object,
    fieldname: PropTypes.string,
};

const RenderDropdownCible = (props) =>  {
    const {data,fieldname,target,target2=null,onchange=null} = props;
    return (
        <Field component="select" name={fieldname} className="col-9 my-1 form-control" onChange={(e)=>{onchange && onchange({_id:e.target.value});}} >
            <option></option>
        { Object.keys(data).map ((d,i)=>
            <option key={i} value={data[d]._id}>
                {(target in data[d]?data[d][target]:"---loading---")}
                {(target2 && target2 in data[d]?' ('+data[d][target2]+')':"")}
            </option>)
        }
        </Field>
    );
}
RenderDropdownCible.propTypes = {
    data:      PropTypes.array,
    fieldname: PropTypes.string,
    target:    PropTypes.string,
    target2:   PropTypes.string,
    onchange:  PropTypes.func,
};

export const RenderDropdownFromSelector = (props) => {
    const {type,partof=null,fieldname,target='denomination',target2=null,select=null,bddentry=getdefaultbddentry(),onchange=null} = props;
    const mystate     = appstore.getState();
    const lebonfil    = ('fildAriane' in mystate?mystate.fildAriane:null);
    const uuid_partof = (lebonfil && partof && partof in lebonfil?lebonfil[partof]:null);
    const selector    = { ...select, type };
    // Donne { etude: N° d'étude } pour le filtre
    if (partof && uuid_partof) { selector[partof] = uuid_partof; }
//console.log ("PROPS", tListe, selector, props);
    //const iindex = 6;
    const tListe = useDocsFromSelector ({bddentry, selector, /*iindex,*/});
    const error  = (tListe && "error" in tListe?tListe:null);

    return (error
        ?   <Error error={error}/>
        :   tListe
        ?   <RenderDropdownCible data={tListe} fieldname={fieldname} target={target} target2={target2} onchange={onchange} />
        :   "-"
    );
}
RenderDropdownFromSelector.propTypes = {
    type:      PropTypes.string,
    partof:    PropTypes.string,
    target:    PropTypes.string,
    target2:   PropTypes.string,
    fieldname: PropTypes.string,
    bddentry:  PropTypes.string,
    select:    PropTypes.object,
    onchange:  PropTypes.func,
};

const myrender = ({input, data, groupBy=null, torender=RenderSelectField}) => {
    const MonCompo = torender;
    return (
    <MonCompo
        {...input}
        onBlur={() => input.onBlur()}
        value={input.value || null}
        data={data}
        dataKey='value'
        textField='text'
        groupBy={groupBy}
        caseSensitive={false}
        minLength={3}
        filter='contains'
        className="w-100"
    />
    );
}

export const RenderMyDropdown = ({data,fieldname,dropdowntype='simple',groupBy=null}) =>
    <Field
        component={myrender}
        torender={(dropdowntype==='simple'?RenderSelectField:RenderSelectField)}
        name={fieldname}
        data={data}
        groupBy={groupBy}
        className="w-100"
    />
        //component={(dropdowntype==='simple'?renderDropdownList:renderMultiselect)}
        //className="col-9 my-1 form-control"
RenderMyDropdown.propTypes = {
    data:         PropTypes.object,
    fieldname:    PropTypes.string,
    dropdowntype: PropTypes.string,
    groupBy:      PropTypes.string,
};

// dropdowntype peut prendre les valeurs ['simple', 'multiple']:
// - simple: dropdown classique,
// - multiple: dropdown à sélection multiple
export const RenderDropdown = (fieldname, intitule, echelleentry, dropdowntype="simple") => {
    const pEntry = (echelleentry in hEchelle?hEchelle[echelleentry]:null);
    if (pEntry) {
        return ({
            component: RenderMyDropdown,
            intitule : intitule,
            others: {
                data      : hKeysEchelle[echelleentry].reduce ((a,e) => {a.push({value: e, text: pEntry[e].label}); return a;},[]),
                //icon      : hKeysEchelle[echelleentry].reduce ((a,e) => {a[e] = pEntry[e].icon ; return a;},{}),
                fieldname,
                dropdowntype,
            }
        });
    }
    else {
        console.error ("ERREUR ECHELLE",fieldname,intitule,echelleentry,hKeysEchelle,hEchelle);
    }
}

export const downloadfromid = ({doc, filename}) => {
    const bddentry = getdefaultbddentry(doc)
    const pDB      = (bddentry && bddentry in infosdbs?infosdbs[bddentry][defoltdb]:null);
    //console.error ("DOWNLOADFROMID", doc, filename);
    if (pDB) {
        pDB.getAttachment (doc['_id'], filename)
            .then (blobOrBuffer => {
                const myurl     = window.URL.createObjectURL(blobOrBuffer);
                const tmplink   = document.createElement('a');
                tmplink.href    = myurl;
                tmplink.setAttribute ('target',   "_blank");
                tmplink.setAttribute ('rel',      "noopener noreferrer");
                tmplink.setAttribute ('download', filename);
                tmplink.click();
                tmplink.remove();
            })
            .catch (err => {
                console.error (err, bddentry, filename, doc);
            })
    }
    else {
        console.error ("downloadfromid", bddentry, filename, doc);
    }
}

export const FileSize = ({rowdata}) => {
    const taille = ('length' in rowdata && rowdata.length?prettyBytes (rowdata.length):'unknown');
    return (taille);
}

//const sizeprops = {height: "30px", width:"30px"};

// Doit contenir à plat: _id, filename, et champs de l'attachment (content_type, length, ...)
export const DownloadFile = ({attachment}) => {
    const filename = (attachment && 'filename' in attachment?attachment.filename:null);
    //console.log ("ATTACHMENT", attachment, filename);
    return (
        filename
        ?
            <button className="p-1 btn btn-link" onClick={()=>{
//console.log ("Please download", attachment, filename);
                downloadfromid({doc: attachment, filename})}
            }>
                <TipsFromData rowdata={attachment} myField={'content_type'} myCompo={IconFromData} tipField="filename" />
                <FontAwesomeIcon className='m-1 text-primary' icon={faArrowCircleDown} />
            </button>
        :   "Error"
    );
}
DownloadFile.propTypes = {
    attachment: PropTypes.object,
};

export const FilesFromData = ({rowdata}) => {
    //console.log ("FILESFROMDATA", rowdata);
    const _id = (rowdata && '_id' in rowdata?rowdata._id:null);
    const attachments = (rowdata && '_attachments' in rowdata && rowdata._attachments?rowdata._attachments:{});
            //<button className="p-1 btn btn-link" key={i} onClick={()=>downloadfromid({doc: rowdata, filename: k})}>
            //<TipsFromData rowdata={{...rowdata._attachments[k], filename:k}} myField='content_type' myCompo={IconFromData} tipField='filename' />
            //<FontAwesomeIcon className='m-1 text-primary' icon={faArrowCircleDown} {...sizeprops}/>
            //</button>
    const keysattachmt= Object.keys (attachments);
    //console.log ("FILESFROMDATA", keysattachmt);
    return (
        keysattachmt.length>0
        ?
            <div className="container">
                {keysattachmt.map((k,i) =>
                    // Passage du contenu de rowdata contient _id et bddentry
                    <DownloadFile key={i} attachment={{_id, ...attachments[k], filename:k}}/>
                )}
            </div>
        :   "No files"
    );
}
FilesFromData.propTypes = {
    rowdata: PropTypes.object,
};

