import React                  from 'react';
import {useState}             from 'react';
import ReactMarkdown          from 'react-markdown'
import {connect}              from 'react-redux';
import {Button}               from 'react-bootstrap';
import {Modal}                from 'react-bootstrap';
import {Spinner}              from 'react-bootstrap';
//import LinearProgress         from '@material-ui/core/LinearProgress';

import {hideModal}            from '../methanol/Modal';
import {IsShowed}             from '../methanol/Modal';
import {SaltAndPepper}        from '../methanol/Modal';
import {MetaDoc}              from '../methanol/MetaDoc';
import {myFabrik}             from '../methanol/LaFabrik';
import {RenderMetaToast}      from '../methanol/MetaToast';
import {infosdbs}             from '../methanol/Bdd';
//import {defoltdb}             from '../methanol/Bdd';
import {getdefaultbddentry}   from '../methanol/Bdd';
import {CRUDcompo}            from '../methanol/Bdd';
import {useDocsFromSelector}  from '../methanol/Bdd';
import {CellAction}           from '../methanol/Datatable';
import {EyePasswordInput}     from '../methanol/Login';
//import {DownloadFile}         from '../methanol/Diesel';
//import {FileSize}             from '../methanol/Diesel';

//import {readMyFile}               from './Fichiers';
import {TableauFichiers}          from './Fichiers';
import {TableauProfils}           from './Profils';
import {processcommons}           from '../Communs';
import {F_EnvoyerOtp}             from '../Communs';
import {F_vDemarrerProtection}    from '../Communs';
import {mysha256sum}              from '../Communs';
import {defaultAction}            from '../Communs';
//import {file2base64}              from '../Communs';
import {F_vAcquitterNotification} from '../model/Actions';
//import {MyCredits}                from '../components/Credits';
import {regOtp}                   from './noRender/validate';

const Promise   = require('lie');

//export const initModals = { 'presentation'   : {show:true ,param:null}, };

//const ListeFichiersJoints = ({doc}) => {
//    const _id            = doc._id;
//    const bddentry       = getdefaultbddentry(doc);
//    const mydownloadfile = r => <DownloadFile attachment={r} />;
//    const mylength       = r => <FileSize     rowdata={r} />;
//    return (
//        <div className="m-4">
//        <MetaDoc {...myFabrik.getCompo('fichiers_joints')}
//            thClass     = 'MuiAppBar-colorPrimary whiteColor'
//            bddentry    = {bddentry}
//            dig_id_field= '_attachments'
//            _id         = {_id}
//            addbutton   = {null} // Pas de bouton add
//            hDescDt     = {{
//                "filename"    : { title: "Nom du fichier", contenu: mydownloadfile },
//                "length"      : { title: "Taille"        , contenu: mylength       },
//                "digest"      : { title: "Somme de contrôle" },
//                //"action"      : { title: "Action"        , contenu: r => <CellAction   rowdata={r} actions={['trash']} /> },
//            }}
//                />
//        </div>
//    );
//}

const ShowMyDocForUser = ({doc=null}) => {
    //const tattachments = (doc && '_attachments' in doc?doc._attachments:null);
    //const tkeysattchmts= (tattachments?Object.keys (tattachments):null);
    //const attach0      = (tkeysattchmts && tkeysattchmts.length>0?tkeysattchmts[0]:'- No attachment -');
    const denomination = (doc && 'denomination' in doc?doc.denomination:'-');
    const description  = (doc && 'description'  in doc?doc.description :'-');
    const secteur      = (doc && 'secteur'      in doc?doc.secteur     :'-');
    return (doc
	?
        <div className="container">
            <div className="row"> <h6 className="col">Titre :      </h6> <h6 className="col">{denomination}</h6> </div>
            <div className="row"> <p  className="col">Description :</p>  <p  className="col">{description} </p>  </div>
            <div className="row"> <p  className="col">Secteur :    </p>  <p  className="col">{secteur}     </p>  </div>
            <hr />
            {/*
            <div className="row"> <p  className="col">Document :   </p>  <p className="col">{strong>{attach0}</strong></p>
            <ListeFichiersJoints doc={doc}/>
            */}
        </div>
	:
	"- No doc -"
    );
}

const ValidationIntegriteCommonConst = ({show, typcmn, modal, doccmntoconfirm, monProfil}) => {
//console.log ("VALIDATION", show, doccmntoconfirm, monProfil);
    const myCloseAction = () => hideModal({modal});
    const lctypcmn      = typcmn.toLowerCase();
    const cmn           = (doccmntoconfirm && lctypcmn in doccmntoconfirm && doccmntoconfirm[lctypcmn]?doccmntoconfirm[lctypcmn]:null);
    const text          = (cmn && 'text' in cmn && cmn.text?cmn.text:"Loading...");
    const validercmn    = () => {
        // Le robot a mis à disposition doccmntoconfirm contenant:
        //   "dateback": "2021-03-01T17:51:14.351Z",
        //   "phase": "confirm",
        //   "cgu"/"pgp": {
        //     "_id": "cc3192ceb2201c484a1cc53933005eb6",
        //     "_rev": "5-3a1b691e03ab209a56b1ce9369bc087b",
        //     "datefront": "2021-03-01T17:51:13.370Z",
        //     "sha256cedd": "0be9ab4ffda137b6e456ee4e549c8fa1dc0734ec48809b0dbe854e8def58398a",
        //   }
        // Pour confirmer il nous faut hasher ces éléments qui seront vérifiés par le robot:
        // sha256confirm = sha256cedd+email+dbname+dateback+dateconfirmfront

        const dbname          = getdefaultbddentry();
        const {inscription    = null} = monProfil;
        const {email          = null} = inscription;
        const sha256          = (doccmntoconfirm && 'sha256'      in doccmntoconfirm && doccmntoconfirm.sha256    ?doccmntoconfirm.sha256    :null);
        const sha256cedd      = (cmn             && 'sha256cedd'  in cmn             && cmn.sha256cedd            ?cmn.sha256cedd            :null);
        const dateback        = (doccmntoconfirm && 'dateback'    in doccmntoconfirm && doccmntoconfirm.dateback  ?doccmntoconfirm.dateback  :null)
        const dateconfirmfront= new Date().toISOString();
        const proof           = sha256cedd+email+dbname+dateback+dateconfirmfront;

        RenderMetaToast(typcmn, "Validation "+typcmn, "info", typcmn);

        mysha256sum(proof)
            .then (sha256confirm => {
                const params = {email, dbname, sha256, confirm: {dateconfirmfront, sha256confirm}};
//console.log ("CALCUL", params, doccmntoconfirm, monProfil);
//console.log ("PROOF", proof);
                processcommons (typcmn, 'confirm', params)
                .then  (() => {
                    RenderMetaToast(typcmn, "Validation enregistrée", "success", typcmn);
                    myCloseAction();
                })
                .catch (err => {
                    console.error (err);
                    RenderMetaToast(typcmn, "ERROR (network)", "danger", typcmn);
                });
            })
            .catch(err => {
                console.error (err);
                RenderMetaToast(typcmn, "ERROR (hash)", "danger", typcmn);
            });
    }
    return (
        <Modal show={show} onHide={myCloseAction} backdrop="static" keyboard={false} dialogClassName="modal-lg">
            <Modal.Header>
            <Modal.Title id="title-userinfos">Validation {typcmn}</Modal.Title>
        {
        /*
         * JFB: A décommenter pour aller plus vite en test d'intégration
            <Button variant="success" onClick={validercmn}>Accepter {typcmn}</Button>
         * */
        }
            </Modal.Header>
            <Modal.Body>
                <ReactMarkdown>{text}</ReactMarkdown>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="success" onClick={validercmn}>Accepter {typcmn}</Button>
            </Modal.Footer>
        </Modal>
    );
};

const ValidationCgu = connect(
    state    => ({
        doccmntoconfirm: state.doccgutoconfirm,
        typcmn:          'CGU',
        modal:           'validation_cgu',
        monProfil:       state.monProfil,
        show:            IsShowed (state, 'validation_cgu')
    }),
)(ValidationIntegriteCommonConst);
const ValidationPgp = connect(
    state    => ({
        doccmntoconfirm: state.docpgptoconfirm,
        typcmn:          'PGP',
        modal:           'validation_pgp',
        monProfil:       state.monProfil,
        show:            IsShowed (state, 'validation_pgp')
    }),
)(ValidationIntegriteCommonConst);

const CommonConst = ({show,cmn,typcmn,modal}) => {
    const myCloseAction = () => hideModal({modal});
    const text = (cmn && 'text' in cmn && cmn.text?cmn.text:"Loading...");
    return (
    <Modal show={show} onHide={myCloseAction} dialogClassName="modal-lg">
        <Modal.Header closeButton>
        <Modal.Title id="title-userinfos">{typcmn}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ReactMarkdown>{text}</ReactMarkdown>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={myCloseAction}>Fermer</Button>
        </Modal.Footer>
    </Modal>
    );
};

export const CGUmodale = connect(
    state    => ({
        cmn:    state.cgu,
        typcmn: 'CGU',
        modal:  'CGU',
        show:   IsShowed (state, 'CGU')
    }),
)(CommonConst);
export const PGPmodale = connect(
    state    => ({
        cmn:    state.pgp,
        typcmn: 'PGP',
        modal:  'PGP',
        show:   IsShowed (state, 'PGP')
    }),
)(CommonConst);

const LoadingConst = ({show}) => {
    const myCloseAction = () => hideModal({modal: "loading"});
    return (
    <Modal show={show} onHide={myCloseAction} dialogClassName="modal-lg">
        <Modal.Header closeButton>
            <Modal.Title>Loading...</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
            <Spinner animation="border" />
            <p>Chargement en cours...</p>
        </Modal.Body>
    </Modal>
    );
}

export const Loading = connect(
    state    => ({ show: IsShowed(state, 'loading') }),
)(LoadingConst);


const PasswordChangeConst = ({show, info}) => {
    const myCloseAction = () => hideModal({modal: "password_verif"});
    const email         = (info && 'email' in info?info['email']:null);
    const [newPassword, setNewPassword] = useState('');

    const getData = e => {
        const data = e.target.value;
        setNewPassword(data);
    }

    // Nous vient de User.jsx
    const handleChangingPassword = () => {
        if (newPassword){
            const bddentry = getdefaultbddentry();
            // TBCTBC JFB à reprendre via l'API rest
            infosdbs[bddentry].remotedb.getUser(email, function(err/*, response*/) {
                if (err) {
                    RenderMetaToast("Password_change","Error during password change","danger","Password_change");
                } else {
                    // TBCTBC JFB à reprendre via l'API rest
                    infosdbs[bddentry].remotedb.putUser(email, {
                        metadata : {
                            email,
                            password : newPassword,
                        }
                    }, function (/*err, response*/) {
                        RenderMetaToast("Password_change", "Le changement de votre mot de passe est bien pris en compte", "success", "Password_change");
                        myCloseAction();
                    });
                }
            })
                .then  (() => {
                    //RenderMetaToast("Login","Login réussi","success",myTid);
                    // store.dispatch ({type: "LOGIN", res});
                })
                .catch (err  => {
                    //RenderMetaToast("Login","Connexion impossible (login, mot de passe ou pas de connexion à la base de données)","danger",myTid);
                    console.error ("ERR",err);
                });
        } else {
            RenderMetaToast("Password_change","Erreur lors du changement de passe. Veuillez ré-essayer plus tard.","danger");
        }
    }
    return(
        <Modal show={show} onHide={myCloseAction} dialogClassName="modal-lg">
            <Modal.Header closeButton>
                <Modal.Title>Changement de mot de passe</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <div>
                    <label>Nouveau mot de passe: </label>
                    <div className="w-75 m-auto">
                        <EyePasswordInput name='password1' onChange={e => getData(e)} className="w-100" />
                    </div>
                </div>
                <hr/>
                <Button variant="primary"   onClick={handleChangingPassword}>Valider</Button>
                <Button variant="secondary" onClick={myCloseAction}         >Fermer</Button>
            </Modal.Body>
        </Modal>
    );
}

export const PasswordChange = connect(
     state    => ({ show: IsShowed(state, 'password_verif'), info: state.logged.info          }),
)(PasswordChangeConst);

export const MyCard = (props) => (
    <div className="card col-12 mb-3 text-center">
        <div className={"card-header "+props.mycolor}>
            {props.header}
        </div>
        <div className="card-body">
            {props.children}
        </div>
    </div>
);

//const PdfInline = ({doc}) => {
//    const bddentry              = getdefaultbddentry(doc);
//	const [hpdfinline, sethPDF] = useState({});
//    React.useEffect (() => {
//        if (doc && "_attachments" in doc) {
//            Object.keys(doc._attachments).forEach (k => {
//                //const myattach = doc._attachments[k];
//                //console.log ("MESPDF", bddentry, doc._id, k, hpdfinline, myattach);
//                //console.log ("MESPDF", infosdbs[bddentry], infosdbs[bddentry].remotedb, );
//                //readMyFile (new Blob ([myattach]))
//                //readMyFile (myattach)
//                infosdbs[bddentry][defoltdb].getAttachment(doc._id, k)
//                    .then(blob => {
//                        //var url = URL.createObjectURL(blob);
//                        //var img = document.createElement('img');
//                        //img.src = url;
//                        //document.body.appendChild(img);
//                        return readMyFile (blob)
//                    })
//                    .then (asdataurl => {
//                        //const result = asdataurl.split('base64,');
//                        //const data /* base64 */ = (result.length>1?result[1]:asdataurl);
//                        sethPDF(t => { const newt = {...t}; newt[k]=asdataurl; return (newt); })
//                    })
//                    .catch (error => console.error (error));
//            });
//        }
//    }, [bddentry, doc]);
//    return (
//        <>
//        {   Object.keys(hpdfinline).length>0
//        ?   Object.keys(hpdfinline).map ((k,i) =>
//            <object key={i}
//                aria-label={k}
//                data={hpdfinline[k]}
//                style={{width:'100%', height:'56em'}}
//            />
//        )
//        :   <LinearProgress />
//        }
//        </>
//    )
//}

// Confirmer la protection => lancer la requête de protection sur l'API
// Processus:
// Front      Front           Robot          Robot            Robot
// draft =>
//          signcanceled
//          signstarted    => signerror
//                         => otpsent      => signerror
//                         => otpconfirmed => signerror
//                                         => signendedok => proofdoc
//                                 */
const uuidprotection = "Certificat";
const myCloseProtectionModale = () => hideModal({modal: "ok_protection_modale"});
const goProtection       = ({doc,notifdoc}) => {
    return Promise.resolve()
    .then (() => {
        return F_vDemarrerProtection (doc);
    })
    .then  (() => {
        RenderMetaToast("Service","Demande de certificat ok...","success",uuidprotection);
        // Acquitter la notification provoque ensuite un autre évènement
        // dans le workflow de traitement.
        return F_vAcquitterNotification (notifdoc);
    })
    .then  (() => {
        RenderMetaToast("Service","Certificat acquitté ok...","success",uuidprotection);
        return
    })
}
const setConfirmation         = ({iconfirm,doc,notifdoc}) => {
    myCloseProtectionModale();
    const madate = new Date().toISOString();
    const myCrud = new CRUDcompo({...doc});
    const myconfirmstatus = (iconfirm?'signstarted':'signcanceled');
    doc['status'] = myconfirmstatus;
    doc['log'   ].push({'status': myconfirmstatus, 'date_front': madate});
    // Enregistrer le document
    RenderMetaToast("Service","Acquittement de la demande de certificat","info",uuidprotection);
    myCrud.save ({doc})
    .then  (() => {
        if (!iconfirm) {
            RenderMetaToast("Service","Abandon du certificat...","warning",uuidprotection);
        } else {
            RenderMetaToast("Service","Demande de certificat...","info",   uuidprotection);
            return goProtection ({doc,notifdoc});
        }
    })
    .catch (error => {
        RenderMetaToast("Service","Erreur demande de certificat...","danger",uuidprotection);
        console.error ("ERROR F_vDemarrerProtection", error, doc);
    })
}
const MyConfirmProtectionConnected = ({show, param}) => {
    const doc      = (param && 'doc'      in param?param.doc     :{}); // Document en demande de protection / certificat
    const notifdoc = (param && 'notifdoc' in param?param.notifdoc:{}); // Notification
    return(
        <Modal show={show} onHide={myCloseProtectionModale} backdrop="static" keyboard={false} dialogClassName="modal-lg">
            <Modal.Header>
                <Modal.Title>Document de preuve électronique</Modal.Title>
            </Modal.Header>
            <Modal.Body className="m-4 text-center">
                <p>Vous allez générer votre preuve - coût 1 crédit</p>
                <hr />
                <ShowMyDocForUser doc={doc} />
                <div>
                    <p>Un code SMS va vous être envoyé après confirmation</p>
                    <Button className="m-2" variant="danger"  onClick={() => setConfirmation({iconfirm:false,doc,notifdoc})}>Annuler  </Button>
                    <Button className="m-2" variant="success" onClick={() => setConfirmation({iconfirm:true ,doc,notifdoc})}>Confirmer</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
                //<PdfInline        doc={doc} />
};

const MyConfirmProtectionModale = connect(
    state    => (
        'modal' in state && 'ok_protection_modale' in state.modal
        ?{show: state.modal['ok_protection_modale']['show'], param: state.modal['ok_protection_modale']['param']}
        :{show: false                                      , param: null                                       }
    ),
)(MyConfirmProtectionConnected);

const toastotp = "OTP";
const otpCloseAction = () => { hideModal({modal: "otp_modale"}); defaultAction('lister_oeuvres'); }
const reconcileOTP   = ({doc,otp,notifdoc}) => {
    otpCloseAction ();
    RenderMetaToast("OTP","Envoi OTP...","info",toastotp);
    F_EnvoyerOtp ({doc, otp})
        .then  (() => {
            RenderMetaToast("OTP","Envoi OTP ok...","success", toastotp);
            return F_vAcquitterNotification (notifdoc);
        })
        .then  (() => {
            RenderMetaToast("OTP","OTP en cours d'analyse...","success", toastotp);
            return;
        })
        .catch (error => {
            RenderMetaToast("OTP","Erreur validation OTP...","danger", toastotp);
            console.log ("MyOTPModaleConnected", error);
        })
}
const dismissOTP     = ({doc,notifdoc}) => {
    otpCloseAction ();
    const madate          = new Date().toISOString();
    const myCrud          = new CRUDcompo({...doc});
    const myconfirmstatus = 'signcanceled';
    doc['status'] = myconfirmstatus;
    doc['log'   ].push({'status': myconfirmstatus, 'date_front': madate});
    // Enregistrer le document
    RenderMetaToast("OTP","Annulation de la demande de certificat...","info",toastotp);
    myCrud.save ({doc})
        .then  (() => {
            RenderMetaToast("OTP","Mise à jour du certificat...","success", toastotp);
            return F_vAcquitterNotification (notifdoc);
        })
        .then  (() => {
            return F_EnvoyerOtp ({doc, otp:'cancel'})
        })
        .then  (() => {
            RenderMetaToast("OTP","Annulation de la demande de certificat ok...","success", toastotp);
            return;
        })
        .catch (error => {
            RenderMetaToast("OTP","Erreur annulation du certificat...","danger", toastotp);
            console.log ("MyOTPModaleConnected", error);
        })
}
const MyOTPModaleConnected = ({show, param}) => {
    const doc           = (param && 'doc'      in param?param.doc     :{});
    const notifdoc      = (param && 'notifdoc' in param?param.notifdoc:{});
    const [otp, setOtp] = useState (null);
    const [msg, setMsg] = useState ("-");

//<button className="btn btn-primary col-lg-4 m-auto" onClick={() => {F_EnvoyerOtp (otp);} }>Valider OTP</button>
//<div className="card col-12 mb-3 text-center py-3">
    React.useEffect (() => {
        const rotp = regOtp (otp);
        setMsg (rotp.message)
        if (rotp.isvalid) { // Dès qu'on a les 6 caractères, on submit
            reconcileOTP ({doc,otp,notifdoc})
        }
    },[otp])

    return (
        <Modal show={show} onHide={()=>dismissOTP({doc,notifdoc})} dialogClassName="modal-lg">
            <Modal.Header closeButton>Validation par SMS</Modal.Header>
            <Modal.Body className="m-4 text-center">
                <div>
                    <p>Recopiez le code reçu par sms :</p>
                    <input id="id-input-otp-code" name="input-otp-code" type="text"
                        autoFocus
                        className="text-center col-lg-4 m-3"
                        onChange={(e) => {setOtp (e.target.value);}}
                    />
        {/*
                    <Button id="button-otp-validate" className="m-2" variant="success"
                        style={rotp.isvalid?{}:{display:'none'}}
                        onClick={reconcileOTP}>
                        Valider l&apos;OTP
                    </Button>
        */}
                </div>
                    {msg}
                <hr/>
                <ShowMyDocForUser doc={doc} />
                <hr/>
                {doc && 'status' in doc && doc['status'] && doc.status!=='otpsent'?
                <Button className="m-2" variant="danger" onClick={()=>dismissOTP({doc,notifdoc})}>Annuler</Button>
                :null}
            </Modal.Body>
        </Modal>
    );
                //<PdfInline        doc={doc} />
};

const MyOTPModale = connect(
    state    => (
        'modal' in state && 'otp_modale' in state.modal
        ?{show: state.modal['otp_modale']['show'], param: state.modal['otp_modale']['param']}
        :{show: false                            , param: null                              }
    ),
)(MyOTPModaleConnected);

const MonProfil = (props) =>
    //<MetaDoc {...myFabrik.getCompo('profil')} typeedit='modale' _id='mon_profil' />
    <MetaDoc {...myFabrik.getCompo('profil')} modal='profil' _id='mon_profil' {...props} />

const ManageProfilsConnected = ({show, /*param,*/}) => {
    const myCloseAction = () => hideModal({modal: "liste_profils"});
    //const myaction      = r  => <CellAction rowdata={r} actions={['edit','trash']} />;
    return (
        <Modal show={show} onHide={myCloseAction} dialogClassName="modal-xl">
            <Modal.Header closeButton>Profils</Modal.Header>
            <Modal.Body className="m-4 text-center">
                <TableauProfils />
            </Modal.Body>
        </Modal>
    );
}
const ManageProfils = connect(
    state    => (
        'modal' in state && 'liste_profils' in state.modal
        ?{show: state.modal['liste_profils']['show'], /* param: state.modal['liste_profils']['param']*/ }
        :{show: false                               , /* param: null                                 */ }
    ),
)(ManageProfilsConnected);

const MonFichier = (props) =>
    <MetaDoc {...myFabrik.getCompo('fichier')} modal='fichier' {...props} />

const ManageFichiersConnected = ({show, /*param,*/}) => {
    const myCloseAction = () => hideModal({modal: "liste_fichiers"});
    //const myaction      = r  => <CellAction rowdata={r} actions={['edit','trash']} />;
    return (
        <Modal show={show} onHide={myCloseAction} dialogClassName="modal-xl">
            <Modal.Header closeButton>Liste de vos fichiers</Modal.Header>
            <Modal.Body className="m-4 text-center">
                <TableauFichiers />
            </Modal.Body>
        </Modal>
    );
}
const ManageFichiers = connect(
    state    => (
        'modal' in state && 'liste_fichiers' in state.modal
        ?{show: state.modal['liste_fichiers']['show'], /* param: state.modal['liste_fichiers']['param']*/ }
        :{show: false                                , /* param: null                                 */ }
    ),
)(ManageFichiersConnected);

const MyError  = ({rowdata}) => {
    const error = (rowdata && 'error' in rowdata?rowdata.error:null);
    return (error ?
	JSON.stringify (error)
	:null
    )
}

const selector_messages = {type: 'log', status: {"$exists": false}};
const Messages = () => {
    const bddentry = getdefaultbddentry();
    const selector = selector_messages;
    const myerror  = r => <MyError rowdata={r}/>;
    const myaction = r => <CellAction rowdata={r} actions={['check']}/>;
    return (
        <MetaDoc {...myFabrik.getCompo('log')}
            thClass     = 'MuiAppBar-colorPrimary whiteColor'
            bddentry    = {bddentry}
            selector    = {selector}
            addbutton   = {null} // Pas de bouton add
            hDescDt     = {{
                "level" : { title: "Niveau"     },
                "from"  : { title: "Expéditeur" },
                "docid" : { title: "Document"   },
                "error" : { title: "Erreur", contenu: myerror  },
                "action": { title: "Action", contenu: myaction },
            }}
        />
    );
}

const AppMessages  = () => {
    const selector = selector_messages;
    const mydocs   = useDocsFromSelector ({selector});
    const error    = (mydocs && 'error' in mydocs?mydocs:null)
    const nbdocs   = (mydocs?error?-1:mydocs.length:0);
    const show     = (nbdocs>0);
//console.log ("JFBJFB", selector, nbdocs, mydocs);
    if (error) { RenderMetaToast("Connect error", "Impossible de récupérer les notifications "+JSON.stringify(selector), "danger"); }
    return (
        <Modal show={show} dialogClassName="modal-xl">
            <Modal.Header closeButton>Messages</Modal.Header>
            <Modal.Body className="m-4 text-center">
                <Messages/>
            </Modal.Body>
        </Modal>
    );
}

const AppModalesConnected = ({wecanstart}) => {
    return (
        <div id="fenetres_modales">
            <Loading                  />
            <MonProfil                />
            <MonFichier               />
            <ManageProfils            />
            <ManageFichiers           />
            <MyConfirmProtectionModale/>
            <MyOTPModale              />
            <PasswordChange           />
            <CGUmodale                />
            <PGPmodale                />
            <ValidationCgu            />
            <ValidationPgp            />
            {wecanstart?
                <AppMessages/>
                :null
            }
            <SaltAndPepper            />
        </div>
    );
}

export const AppModales = connect(
    state => ({ wecanstart: state.wecanstart })
)(AppModalesConnected);

