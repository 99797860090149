import React                        from 'react';
import {Field}                      from 'react-final-form';
import {FieldArray}                 from 'react-final-form-arrays';
//import {reduxForm}                  from 'redux-form'
import {connect}                    from 'react-redux';
//import DropdownList                 from 'react-widgets/DropdownList'
// import validate from './validate'
//import {SelectList}                 from 'react-widgets'

//import Tooltip                      from 'react-bootstrap/Tooltip';
//import OverlayTrigger               from 'react-bootstrap/OverlayTrigger';
import {Button}                     from 'react-bootstrap';
//import Button                       from '@material-ui/core/Button';
//import TextField                    from '@material-ui/core/TextField';
//import MenuItem                     from '@material-ui/core/MenuItem';
import {makeStyles}                 from '@material-ui/core/styles';
import Select                       from '@material-ui/core/Select';
import FormControl                  from '@material-ui/core/FormControl';
import InputLabel                   from '@material-ui/core/InputLabel';


import {onChangeCorP}               from '../model/Actions';

import {RenderMetaToast}            from '../methanol/MetaToast';
//import {MyLecteurPdf}               from './LecteurPdf';
import {showModal}                  from '../methanol/Modal';
//import {RenderSelectField}          from '../methanol/MetaDoc';
//import {getValueFromFormUnderEdit}  from '../methanol/MetaDoc';
//import {delfuel}                    from '../methanol/Bdd';
import {CellAction}                 from '../methanol/Datatable';
import {Error}                      from '../methanol/Datatable';
import {useDocFromId}               from '../methanol/Bdd';
import {useDocsFromSelector}        from '../methanol/Bdd';
import {CRUDcompo}                  from '../methanol/Bdd';
import {MetaDoc}                    from '../methanol/MetaDoc';
import {myFabrik}                   from '../methanol/LaFabrik';
//import {store}                      from '../Store';
import {nbmaxparpreuve}             from '../Communs';
import {AskForCredits}              from '../Communs';
//import {RenderField}                from '../Communs';
import {defaultAction}              from '../Communs';
import {tprofilfields}              from './Profils';
//import {MonGenre}                   from './Profils';
import {tfilefields}                from './Fichiers';
import {handleUploadFile}           from './Fichiers';
//import {EditFichier}                from './Fichiers';
import {TableauFichiers}            from './Fichiers';
//import {email}                      from './noRender/validate';
//import {required}                   from './noRender/validate';
//import {phoneNumber}                from './noRender/validate';
//import {alphaNumeric}               from './noRender/validate';

//Style de material/ui, à voir ce qu'on garde.
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            //width: '40ch',
            // border:'1px solid #DAAC51',
        },
    },
}));

//export const MyField = ({validation, name, intitule}) => {
//    return (
//        <Field validate={validation} name={name} type="text" component={RenderField} placeholder={intitule} />
//    );
//}

//const renderSelect = ({ input, label, placeholder, type, blocRenderField="col-12", meta: { touched, error } }) => (
//    <div className={blocRenderField}>
//        <input {...input} type={type} placeholder={placeholder} className="form-control"/>
//        {touched && error && <span>{error}</span>}
//    </div>
//);

const myColClass       = "col-6 my-2";
const myAlignmentClass = "d-flex flex-wrap justify-content-between mt-3 mb-5";

// export const FieldGenre = (props) => {
//     const {input, className=null, validation=null, required=null} = props;
//     const textField  = 'text';
//     const valueField = 'value';
//     const data = [
//         {text: "Homme", value: "M"},
//         {text: "Femme", value: "F"},
//     ]
//     return (
//         <DropdownList {...input}
//             {...{className, validation, required}}
//             data={data}
//             textField={textField}
//             valueField={valueField}
//             validate={[ required ]}
//             placeholder="Genre"
//             onChange={() => input.onChange()}
//         />
//     );
// }

// JFBJFB: à reprendre pour le profil principal ou séparer id de checkedid...
// const mydisabled = (_id, name) => injection_props_profil({confront: 'field', formname: 'profil', doc: {_id}, name});
//const Auteur =  ({coauteur=null}) =>  {
//    const myprefix = (coauteur?coauteur+'.':"");
//    const _id = getValueFromFormUnderEdit ('protection', myprefix+'_id');
//    const dis = (name) => mydisabled(_id, name)
//    return (
//	<section>
//        <div className={myAlignmentClass}>
//            <Field disabled validate={[required]} name={myprefix+'_id'} type="hidden" component='input' />
//            <MonGenre {...dis('checkedid.gender')} name={myprefix+'checkedid.gender'} className="form-control col-lg-6 my-2"/>
//            <Field {...dis('pseudo')} validate={[ required, alphaNumeric ]} name={myprefix+'pseudo'             } type="text" blocRenderField={myColClass} component={RenderField} placeholder="Pseudo"                      />
//        </div>
//        <div className={myAlignmentClass}>
//            <Field {...dis('checkedid.firstname')} validate={[ required, alphaNumeric ]} name={myprefix+'checkedid.firstname'} type="text" blocRenderField={myColClass} component={RenderField} placeholder="Prénom"         />
//            <Field {...dis('checkedid.lastname')}  validate={[ required, alphaNumeric ]} name={myprefix+'checkedid.lastname' } type="text" blocRenderField={myColClass} component={RenderField} placeholder="Nom"            />
//        </div>
//        <div className={myAlignmentClass}>
//            <Field {...dis('inscription.email')}   validate={[ required, email ]}        name={myprefix+'inscription.email'  } type="text" blocRenderField={myColClass} component={RenderField} placeholder="Email"          />
//            <Field                 validate={[ required, phoneNumber  ]} name={myprefix+'phone'              } type="text" blocRenderField={myColClass} component={RenderField} placeholder="Téléphone (forme +33601234567)" />
//        </div>
//        <div className={myAlignmentClass}>
//            <Field                 validate={[ required, alphaNumeric ]} name={myprefix+'address.street'     } type="text" blocRenderField={myColClass} component={RenderField} placeholder="Adresse"                        />
//            <Field                 validate={[ alphaNumeric           ]} name={myprefix+'address.complements'} type="text" blocRenderField={myColClass} component={RenderField} placeholder="Adresse (complément)"           />
//            <Field                 validate={[ required, alphaNumeric ]} name={myprefix+'address.postalCode' } type="text" blocRenderField={myColClass} component={RenderField} placeholder="Code postal (31000)"            />
//            <Field                 validate={[ required, alphaNumeric ]} name={myprefix+'address.city'       } type="text" blocRenderField={myColClass} component={RenderField} placeholder="Ville"                          />
//        </div>
//        <div className={myAlignmentClass}>
//            <MonPays name={myprefix+'address.country'    } className={"form-control "+myColClass} />
//        </div>
//    </section>
////	    <TextField name={"place_birth_"+i} label="Lieu de naissance" value={name} onChange={handleChange} />
////	    <TextField name={"date_birth_"+i} label="Date de naissance" />
//    )
//}

const AddMonProfil= ({fields, meta: {error, submitFailed}}) => {
    const verifiedProfil = useDocFromId ({_id: 'mon_profil'});

    //const nbfields  = (fields?fields.length:0);
    //const selector = {_: 'profil'};
    //const tListe   = delfuel(useDocsFromSelector ({selector, /*iindex,*/ fields: tFields}));
    const infields  = (fields && fields.length>0?fields.value.filter(f => f['_id']==='mon_profil'):[]).length;
//console.log ("ADDMONPROFIL", {verifiedProfil, error});
    return (
        !infields
        ?
        <li>
        <Signataire verifiedProfil={verifiedProfil}/>
		<button className="btn btn-primary m-1" id="btn-add-myself" type="button"
            onClick={() => {
                if (verifiedProfil)
                { fields.push(verifiedProfil); }
                else {
                    console.error("AddMonProfil error", {verifiedProfil});
                    alert("Error - profil undefined");
                }
            } }>
            Mon profil
            <i className="fas fa-plus-square mx-1"></i>
		</button>
		{submitFailed && error && <span>{error}</span>}
        </li>
        :   null
    );
}
//export const AddMonProfil = connect(
//    state    => ({ verifiedProfil: state.verifiedProfil }),
//)(AddMonProfilConnected);

//const renderCoAuteurs = (member, index, fields) => {
//    const nbelems     = (fields?fields.length:0);
//    const auteursetco = (nbelems>1?'Co-auteur':'Auteur');
//    return (
//    <li key={index}>
//        <div className="card-author col-lg-10">
//            <h4>
//                {auteursetco}{(nbelems>1?" n°"+(index+1):"")}
//                <i className="fas fa-times-circle float-right" onClick={() => fields.remove(Number(index))}></i>
//            </h4>
//            <Auteur coauteur={member} fields={fields} />
//        </div>
//    </li>
//    );
//}

const EditList = ({modal, text}) => (
    <div>
    <button className="btn btn-primary m-auto p-2 mt-4 whiteColor" onClick={e=>{e.preventDefault();showModal({modal})}}>
        {text}
    </button>
    </div>
)

//    <button className="btn btn-warning my-1" type="button" onClick={() => fields.push({})}>
//        Ajouter un coauteur
//        <i className="fas fa-plus-square mx-1"></i>
//    </button>
const myinfos  = (t) => {
    const firstname = (t && 'checkedid'   in t && t.checkedid   && 'firstname' in t.checkedid  ?t.checkedid.firstname:"nofirstname");
    const lastname  = (t && 'checkedid'   in t && t.checkedid   && 'lastname'  in t.checkedid  ?t.checkedid.lastname :"nolastname" );
    const email     = (t && 'inscription' in t && t.inscription && 'email'     in t.inscription?t.inscription.email  :"noemail"    );
    return (lastname+" "+firstname+" "+email);
}

const MySelect = ({name, busy, busySpinner, label, onChange, children}) => {
    const classes    = useStyles();
    return (busy
        ?
        {busySpinner}
        :
        <FormControl className={classes.formControl+" col-9"}>
            <InputLabel htmlFor="color-native-simple">{label}</InputLabel>
            <Select
                native
                inputProps={{
                  name: name,
                  id:   'color-native-simple'
                }}
                onChange={onChange}
            >
                {children}
            </Select>
        </FormControl>
    );
}

const compareByFilename = (a,b) => {
    const myfield = 'filename';
    const res = 
        (
                a[myfield]>b[myfield]
            ?   1
            :   a[myfield]<b[myfield]
            ?  -1
            :   0
        )
    return res;
}

const AddOneMore   = ({tToAdd, fields, label, names=['name']}) =>  {
    const nbelems  = (tToAdd?tToAdd.length:0);
    const busy     = (nbelems>0?null:{busy: true, busySpinner: <span className="fas fa-sync fa-spin" />});
            //textField={item => myinfos(item)}
            //allowCreate="onFilter"
    const empty    = "";

    return (nbelems>0
        ?
        <MySelect name="AddOneMore" style={{width: '70%'}}
            {...busy}
            label={label}
            onChange={e => {
                const value = e.target.value;
                // 1ère ligne vide volontairement => pas de trogger avec cette ligne
                if (value!==empty) { fields.push(tToAdd[value]); }
            }}
        >
            <option value={empty} />
            {tToAdd.sort(compareByFilename).map ((o,i) =>
                <option key={i} value={i}>
                {
                    names.map (n => {
                        const mypath = n.split("."); // checkedid.firstname par exemple
                        let feuille = o;
                        mypath.forEach(p => (feuille && p && p in feuille ? feuille=feuille[p] : '---' ));
                        return feuille;
                    })
                    .join(' - ')
                }
                </option>
            )}
        </MySelect>
        :
        "-"
    );
}

const CoAuteurs = (props) => {
    const {tList, fields, meta: {error, submitFailed}, /*verifiedProfil*/} = props;
    //const classes    = useStyles();
    //const nbfichiers = (tList   ?tList   .length:0);
    const erroruse   = (tList    && 'error' in tList   ?tList   :null);
    if (erroruse) { return <Error error={erroruse}/>; }
//console.log ("JFBJFB", {fields});
    const ga         = (fields && fields.length>0?fields.value:null);
//console.log ("JFBJFB ga", ga);
    const tAllreadyS = (ga?ga.map(f => f['_id']):[]);
    const tToAdd     = (tList   ?tList   .filter(p => (!(tAllreadyS.includes(p['_id'])))):[]);
    const myaction   = (r, index) => {
        return (
            <div className="container">
            <div className="row">
            <div className="col">
            <CellAction   rowdata={r} actions={['edit']}/>
            </div>
            <div className="col">
            <i className="text-danger  fas fa-times-circle float-right" onClick={() => { fields.remove(Number(index))}}></i>
            </div>
            </div>
            </div>
        ) }
//        {nbfichiers<=1
//        ?
//            <Button id="premier_profil" onClick={() => showModal({modal: 'liste_profils'})} rel="noopener noreferrer" variant="success" className="py-3 my-1 whiteColor">
//            <h6>Vous n&apos;avez pas encore de co-auteurs dans votre annuaire...</h6>
//            <p>Ajouter vos premiers profils de co-auteurs</p>
//            <i className="fas fa-upload"></i>
//            </Button>
//        :
//console.log ("COAUTEURS", props);
    const MyErrors = () => (error ? <span style={{margin: 'auto', color: "red"}}>{error}</span> : null)

            //AddMonProfil ({verifiedProfil, fields, meta: {error, submitFailed}})
    return (
        <>
        {submitFailed && error && <span>{error}</span>}
        <AddMonProfil {...{fields, meta: {error, submitFailed}}}/>
            <div className="p-2">
            {tAllreadyS.length>0
            ?   <>
                <TableauFichiers userData={ga} addbutton={null}
                    hDescDt     = {{
                        "checkedid.firstname": { title: "Nom"                        },
                        "checkedid.lastname" : { title: "Prénom"                     },
                        "inscription.email"  : { title: "Email"                      },
                        "phone"              : { title: "Téléphone"                  },
                        "action"             : { title: "Action", contenu: myaction  },
                    }}
                />
                </>
            :   null
            }
                <MyErrors/>
                <hr />
                <div className={myAlignmentClass}>
                    {tToAdd.length>0
                        ?   <AddOneMore tToAdd={tToAdd} fields={fields} myClass={myColClass}
                                names={['inscription.email', 'checkedid.lastname', 'checkedid.firstname']}
                                label="Ajouter un co-auteur"/>
                        :   null
                    }
                    <span style={{margin: 'auto'}}>
                    <EditList modal="liste_profils" text="Annuaire"/>
                    </span>
                </div>
                <ul hidden={true}>
                    { /* Champs Field apparaissent en caché, le tableau permet de grouper la liste des signataires */
                    fields.map((f,i) =>
                        <li key={i}>
                        {tprofilfields.map((tff,j) =>
                          <Field key={j} name={f+'.'+tff.fieldname} type='text' label={tff.label} component="input"/>
                        )}
                        </li>
                    )}
                </ul>
            </div>
        </>
    );
}

const Signataire = ({verifiedProfil}) => {
    return (
        "Signataire: "+myinfos(verifiedProfil)
    );
}

//const BlocAuteursConnected = (props) => {
export const BlocAuteurs = (/*props*/) => {
    //const { handleSubmit, pristine, reset, submitting } = props
    //const {verifiedProfil=null} = props;
    const selector   = {type: 'profil'};
    const defaultinit= [];
    const tList      = useDocsFromSelector ({selector, defaultinit});
//console.log ("BlocAuteursConnected", verifiedProfil);
    return (
        <FieldArray
            name="signataires"
            tList={tList}
            component={CoAuteurs}
            //verifiedProfil={verifiedProfil}
        />
    );
}
//export const BlocAuteurs = connect(
//    state    => ({ verifiedProfil: state.verifiedProfil }),
//)(BlocAuteursConnected);

//const renderFichiers  = (member, index, fields) => {
//    const nbelems     = (fields?fields.length:0);
//    const txtfichiers = (nbelems>1?'Fichiers':'Fichier');
//    return (
//    <li key={index}>
//        <div className="card-author col-lg-10">
//            <h4>
//                {txtfichiers}{(nbelems>1?" n°"+(index+1):"")}
//                <i className="fas fa-times-circle float-right" onClick={() => fields.remove(Number(index))}></i>
//            </h4>
//            <EditFichier index={index} fichier={member} fields={fields} />
//        </div>
//    </li>
//    );
//}

//const SelectFichiers = ({tAllreadyS, tToAdd, fields, myAlignmentClass, myColClass}) =>
//    <div className={myAlignmentClass}>
//        {tToAdd.length>0 && (tAllreadyS.length<nbmaxparpreuve)
//            ?   <AddOneMore tToAdd={tToAdd} fields={fields} myClass={myColClass}
//                    names={['filename','content_type']}
//                    label="Sélectionnez un fichier déja identifié"/>
//            :   null
//        }
//        <EditList modal="liste_fichiers" text="Gérer vos fichiers"/>
//    </div>

export const PremierFichier = () =>
    <>
        <p className='mt-4 mb-1'>Vous n&apos;avez pas encore de fichier</p>
        <Button id="premier_fichier"
            onClick={() => showModal({modal: 'liste_fichiers'})}
            rel="noopener noreferrer"
            variant="success"
            className="py-3 my-1 whiteColor btnTextGreen mb-5"
        >
            <i className="bi bi-upload me-2"></i>
            Ajouter votre premier fichier
        </Button>
    </>
    

export const MyHiddenFileFields = ({fields}) =>
    <ul hidden={true}>
        {fields.map((f,i) =>
            <li key={i}>
            {tfilefields.map((tff,j) =>
              <Field key={j} name={f+'.'+tff.fieldname} type='text' label={tff.label} component="input"/>
            )}
            </li>
        )}
    </ul>

const inituuid = (doc) => {
    // Préparer l'identifiant unique = _id
    const hash = (doc && 'hash' in doc?doc.hash:null);
    // Retourner un _id null provoquera une erreur, c'est souhaité
    // un fichier devrait toujours avoir un hash et unique...
    // Si une collision se produit, c'est que le fichier existe déja
    return (hash);
}

const addmyfile = ({fields, doc, tAllreadyS}) => {
    const hash     = (doc && 'hash'     in doc?doc.hash    :null     );
    const filename = (doc && 'filename' in doc?doc.filename:'unknown');
    // !!! Attention: on ne peut pas compter sur fields.length / la valeur n'est pas refresh après push
    // Limiter d'ajout de fichiers ajouté dans handleUploadFile
    // La condition suivante ne fonctionne pas à cause du parallélisme
    // et du temps de refresh des composants...
    if (fields.length>=nbmaxparpreuve || tAllreadyS.length>=nbmaxparpreuve) {
        RenderMetaToast(filename,"Nb max de fichiers atteint","warning",filename);
    } else {
        const present = (hash && tAllreadyS.includes(hash));
        if (!present) {
            fields.push(doc);
            RenderMetaToast(filename,"Fichier ajouté","success",filename);
        } else {
            RenderMetaToast(filename,"Fichier déja présent","warning",filename);
        }
    }
}

const DragAndDrop = ({nbselected, fields, tList, tAllreadyS, children=null}) => {
    const [myback, setBack] = React.useState ("no-dragover");
    const tHashes = tList.reduce((a,f) => {a.push(f['hash']); return (a);}, [])
    //const [files, setFiles] = React.useState ([]);
    //React.useEffect(() => {
    //});

    const clbk = ({doc})   => {
        const {hash=null}  = doc;
        const type         = 'fichier';

        // hash unique du fichier / permet de ne pas ajouter 2 fois un fichier
        // Enregistrement si unique seulement...
        if (tHashes.includes(hash)) {
            // Si le fichier est déja sélectionné, rien à faire,
            // Sinon le rajouter dans la liste
            if (!(tAllreadyS.includes(hash))) {
                addmyfile({fields, doc, tAllreadyS});
            }
        }
        else
        {
            const myCrud       = new CRUDcompo({type,inituuid});
            myCrud.save({doc})
                .then  (res => {
                    doc._id  = res.id;
                    doc._rev = res.rev;
                    addmyfile({fields, doc, tAllreadyS});
                })
                .catch (error => {
                    // Si error.error = 'conflict', fichier existe déja,
                    // le rajouter tout de même dans la liste à protéger
                    if (error && 'status' in error && error.status===409) {
                        const docId = (error && 'docId' in error?error.docId:null);
                        doc._id     = docId;
                        addmyfile({fields, doc, tAllreadyS});
                    } else {
                        console.error ("ERROR", error);
                    }
                })
        }
    }
    const nbmaxfichiers   = (nbselected>=nbmaxparpreuve?0:nbmaxparpreuve-nbselected);
    const handleDragEnter = e => { e.preventDefault(); e.stopPropagation();                         }
    const handleDragLeave = e => { e.preventDefault(); e.stopPropagation(); setBack("no-dragover"); }
    const handleDragOver  = e => { e.preventDefault(); e.stopPropagation(); setBack("is-dragover"); }
    const handleDrop      = e => { e.preventDefault(); e.stopPropagation(); setBack("no-dragover");
        handleUploadFile ({e, clbk, nbmaxfichiers});
        //const dragged = e.dataTransfer.files;
        //let   tTab = [];
        //for (let i = 0; i < dragged.length; i++) {
        //    tTab.push(dragged.item(i))
        //}
        //setFiles (tTab);
    };
    // lastModified: 1634670770306
    // name: "FR Passeport Natacha Specimen.jpg"
    // size: 49897
    // type: "image/jpeg"
    const message         = ["Choisissez jusqu'à",nbmaxfichiers,"documents"].join(' ');
    //const message         = (
    //    files.length<1
    //    ?   <p>Déposez vos fichiers ici</p>
    //    :   files.map((f,i) => <p key={i} index={i}>{f.name}</p>)
    //);

    return (
        <div className={myback + " mt-4"}
            onDrop     ={e => handleDrop     (e)}
            onDragOver ={e => handleDragOver (e)}
            onDragEnter={e => handleDragEnter(e)}
            onDragLeave={e => handleDragLeave(e)}
        >
            {tAllreadyS.length>0?children:null}
            {nbmaxfichiers>0
            ?
            <div
                onClick    ={()=> {
                    const input = document.getElementById("upload-my-file");
                    if (input) { input.click(); }
                }}
            >
            <input style={{display:'none'}} accept="*" name="upload-my-file" id="upload-my-file" type="file" onChange={e => handleUploadFile({e, clbk})}/>
            <div className ={"box__input mt-4"}>
                <div className="h-50 center"><p>{message}</p></div>
                <div className="center">
                    <i className="bi bi-upload me-2"></i>
                </div>
            </div>
            </div>
            :
            null
            }
        </div>
    );
}

export const MyTableFromFields = ({ga, myaction}) =>
    <TableauFichiers userData={ga} addbutton={null}
        hDescDt    = {{
            "filename"              : { title: "Nom"                        },
            "content_type"          : { title: "Type"                       },
            "filesize"              : { title: "Taille (octets)"            },
            "action"                : { title: "Action", contenu: myaction  },
        }}
    />

export const AddOrSelectFiles = (props) => {
    const {fields, meta: {error, submitFailed}} = props;
    const selector   = {type: 'fichier'};
    const defaultinit= [];
    const tList      = useDocsFromSelector ({selector, defaultinit});
    const nbfichiers = (tList    ?tList.length:0);
    const erroruse   = (tList     && 'error' in tList    ?tList    :null);
    if (erroruse) { return <Error error={erroruse}/>; }

    const ga         = (fields && fields.length>0?fields.value:[]);
    const tAllreadyS = (ga?ga.map(f => f['hash']):[]);
    const nbselected = tAllreadyS.length;
    //const tToAdd     = (tList?tList.filter(f => (!(tAllreadyS.includes(f['hash'])))):[]);
    const myaction = (r,index) => {
        return (<i className="text-danger fas fa-times-circle float-right" onClick={() => {fields.remove(Number(index))}}></i>)
    }
//console.log ("ADDORSELECTFILES", {error});
    const MyErrors = () => (error ? <span style={{margin: 'auto', color: "red"}}>{error}</span> : null)

    return (
        <div>
            <DragAndDrop        nbselected={nbselected} fields={fields} tAllreadyS={tAllreadyS} tList={tList}>
                <div className="p-2 fichiers_a_proteger">
                    {tAllreadyS.length>0 ? <div><MyTableFromFields ga={ga} myaction={myaction} /></div>:null}
                </div>
            </DragAndDrop>
            <MyErrors/>
            {nbfichiers<=0
            ?   <PremierFichier />
            //:   <div><p>Autres fichiers disponibles:</p><SelectFichiers tAllreadyS={tAllreadyS} tToAdd={tToAdd} fields={fields} myAlignmentClass={myAlignmentClass} myColClass={myColClass}/></div>
            :   null
            }
            <hr />
            <MyHiddenFileFields fields={fields} />
            {submitFailed && error && <span>{error}</span>}
        </div>
    );
}

//const BlocOeuvreConnected = (props) => {
export const BlocOeuvre = () => {
    //const filekeys = (oeuvreFiles?Object.keys(oeuvreFiles):null);
    //const filename = (filekeys && filekeys.length>0?filekeys[0]:null);
    //const mysuppr  = () => { store.dispatch({ type: "SET_OEUVRE_FILES", oeuvreFiles: null }); }

    // Type du content_type, i.e. ce qui est avant le caractère "/"
    //const { handleSubmit, pristine, reset, submitting } = props
    //const {oeuvreFiles} = props;
    return (<FieldArray name="fichiers" component={AddOrSelectFiles} />);
}

//export const BlocOeuvre = connect(
//    state    => ({ oeuvreFiles: state.oeuvreFiles }),
//)(BlocOeuvreConnected);

export const isconfirmed = (common) => {
    const ret = (common && 'datefront' in common && common.datefront?true:false)
    return (ret);
}
const MonAlerte = ({textealerte, children=null}) =>
    <div className="p-3" role="alert">
        <h2>{textealerte}</h2>
        {children}
    </div>

const MonAlerteCguPgp = ({textealerte, common, hook=null, typcmn=null}) => {
    const doc          = common;
    const errormessage = "Si vous voyez ce message, merci de prendre contact avec ERASM";
    return (
        <MonAlerte textealerte={textealerte}>
            {hook && doc?<Button onClick={() => hook({doc},typcmn)}>Parcourrir</Button>:errormessage}
        </MonAlerte>
    )
}
export const AcceptCguOrPgp = ({cgu, pgp}) => {
    const missingcgu = (isconfirmed(cgu)?null:<MonAlerteCguPgp common={cgu} hook={onChangeCorP} typcmn="CGU" textealerte="Les CGU ne sont pas accessibles ou pas signées..."                       />);
    const missingpgp = (isconfirmed(pgp)?null:<MonAlerteCguPgp common={pgp} hook={onChangeCorP} typcmn="PGP" textealerte="La Politique de Gestion de Preuve n'est pas accessible ou pas signée..." />);
    //console.log ("common / customer AcceptCguOrPgp", isconfirmed(cgu), isconfirmed(pgp));
    return (
        <div>
        {missingcgu}
        {missingpgp}
        </div>
    );
}

const verifmyprofil   = () => defaultAction('profil_verif');
const UneProtectionConnected = ({verifiedProfil, nbcredits, cgu, pgp}) => {
    const cguandpgpconfirmed = (isconfirmed(cgu) && isconfirmed(pgp));
    return (!cguandpgpconfirmed
        ?   <AcceptCguOrPgp cgu={cgu} pgp={pgp} />
        :   nbcredits>0
            ?   verifiedProfil
                ?   <MetaDoc {...myFabrik.getCompo('protection')}/>
                :   <MonAlerte textealerte="Votre profil doit être vérifié afin de pouvoir signer des documents">
                        <button onClick={verifmyprofil} className="btn btn-success mt-5">Passer à la vérification de profil</button>
                    </MonAlerte>
            :   <AskForCredits />
    );
                //<div className="alert alert-danger" role="alert">
                //    <h2>Votre profil doit être vérifié afin de pouvoir signer des documents</h2>
                //    <button onClick={verifmyprofil} className="btn btn-success mt-5">Passer à la vérification de profil</button>
                //</div>
}
export const UneProtection = connect(
    state => ({
        verifiedProfil: state.verifiedProfil,
        cgu           : state.cgu,
        pgp           : state.pgp,
        nbcredits     : state.nbcredits,
    })
)(UneProtectionConnected);

