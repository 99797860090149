import React from 'react'
import {AutColors} from '../../assets/constants/aut_colors'


export const BtnMenu = ({title, iconName, btnProps, btnBorderNone, active}) => {

    const btnBordBttomStyle = btnBorderNone ? {} : {
        borderBottomStyle: 'solid', 
        borderBottomWidth: 1,
    }

    //console.log('active: ', active)

    return (
        <button 
            style={{
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: AutColors.snow,
                padding: '20px 12px',
                color: active ? AutColors.frenchBlue : AutColors.grey,
                fontSize: 12, 
                borderStyle: 'none',
                ...btnBordBttomStyle
              }}
            {...btnProps}
        >
            <i className={iconName} 
                style={{
                    fontSize: 40,
                  }}
            />
            {title}
        </button>
    )
}
