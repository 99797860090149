import React                    from 'react';
import PropTypes                from 'prop-types';
//import {useEffect}              from 'react';
import {Form}                   from 'react-final-form';
import {Field}                  from 'react-final-form';
import {FormSpy}                from 'react-final-form'
import arrayMutators            from 'final-form-arrays';
//import {reset}                  from 'redux-form';
//import {submit as formSubmit}   from 'redux-form'
//import {formValueSelector}      from 'react-final-form';
//import {getFormError}           from 'react-final-form';
//import {isPristine}             from 'react-final-form';
//import {isInvalid}              from 'react-final-form';
//import {isSubmitting}           from 'react-final-form';
import {connect}                from 'react-redux';
import {Button}                 from 'react-bootstrap';
import {Modal}                  from 'react-bootstrap';
import {Tab}                    from 'react-bootstrap';
import {Tabs}                   from 'react-bootstrap';
import {makeStyles}             from '@material-ui/core/styles';
import Stepper                  from '@material-ui/core/Stepper';
import Step                     from '@material-ui/core/Step';
import StepLabel                from '@material-ui/core/StepLabel';
import InputLabel               from '@material-ui/core/InputLabel';
import FormHelperText           from '@material-ui/core/FormHelperText';
import FormControl              from '@material-ui/core/FormControl';
import Select                   from '@material-ui/core/Select';
//import LinearProgress           from '@material-ui/core/LinearProgress';

//import {FontAwesomeIcon}        from '@fortawesome/react-fontawesome';
//import {faSave}                 from '@fortawesome/free-solid-svg-icons';

// Interne
import {CRUDcompo}              from './Bdd';
//import {F_vRechargerFormFields} from './Bdd';
//import {subscribeToBddChanges}  from './Bdd';
//import {unsubscribeToBddChanges}from './Bdd';
import {useDocFromId}           from './Bdd';
import {getdefaultbddentry}     from './Bdd';
import {lastidfromtype}         from './Bdd';
import {Datatable}              from './Datatable';
import {CellAction}             from './Datatable';
import {myFabrik}               from './LaFabrik';
import {showModal}              from './Modal';
import {hideModal}              from './Modal';
import {RenderMetaToast}        from './MetaToast';
import {dispatchFormstate}      from './Formstate';

import {appstore}               from './Communs';
import {uuid}                   from './Communs';

const Promise          = require('lie');
const sDefaultTypeedit = 'inline';

// TBC JFBJFB
//export const getValueFromFormUnderEdit = (formname, idfield='_id') => {
//    const formselector       = (formname?formValueSelector(formname):null);
//    // _id du formulaire dans lequel on se trouve
//    const value              = (formselector?formselector(appstore.getState(), idfield):null);
//    return (value);
//}

const RenderField = (props) => {
const { input, label, type, meta: { error, warning } } = props;
//console.log ("RENDERFIELD", {error  });
//console.log ("RENDERFIELD", props);
    return (
    <div>
        <label>{label}</label>
        <div>
            <input {...input} placeholder={label} type={type} />
            {error   && <span>{error  }</span>}
            {warning && <span>{warning}</span>}
        </div>
    </div>
    )
}
RenderField.propTypes = {
    input: PropTypes.element,
    label: PropTypes.string,
    type:  PropTypes.string,
    meta:  PropTypes.object,
};

const RenderFromHelper = (props) => {
const { error, warning } = props;
//console.log ("RENDERFROMHELPER", {error, warning});
    if (!error || !warning) {
        return
    } else {
        return <FormHelperText> {error && <span>{error}</span>} {warning && <span>{warning}</span>} </FormHelperText>
    }
}
RenderFromHelper.propTypes = {
    touched: PropTypes.bool,
    error:   PropTypes.bool,
};

//Style général du stepper
const useStyles = makeStyles((theme) => ({
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        margin: theme.spacing(1),
    },
}));

export const RenderSelectField = (props) => {
    // validate en paramètre pour ne pas le propager depuis custom
    const { name, label, meta: { error, warning }, children, validate, ...custom } = props;
//console.log ("RENDERSELECTFIELD", props);
    const classes    = useStyles();
    return (
        <FormControl classes={classes.formControl}>
            <InputLabel htmlFor="color-native-simple">{label}</InputLabel>
            <Select
                native
                {...custom}
                inputProps={{ name, id: name }}
            >
                {children}
            </Select>
            {RenderFromHelper({ validate, error, warning })}
        </FormControl>
    );
}
RenderSelectField.propTypes = {
    input:    PropTypes.object,
    children: PropTypes.array,
    meta:  PropTypes.object,
    label: PropTypes.string,
};

/**
 *
 *
 */
let hTimeOuts = {}; // Hash contenant les pointeurs vers les timeout
let tidEnreg = null;
const myEnregForm = (formname) => {
//console.log ("MYENREGFORM", formname, appstore.getState().formname);
    //if (!formname)
    formname in hTimeOuts && delete (hTimeOuts[formname]);
    tidEnreg = RenderMetaToast("Enregistrement","Enregistrement...","info");
    // Par défaut, on garde le bouton du formulaire mais hidden / on click pour simuler une action de la personne
    //const formButton = document.getElementById(formname+'-submitButton');
    const formElem   = document.getElementById(formname);
//    if (formButton) { formButton.click(); }
//console.log ("onSubmit myEnregForm", {formname, formElem});
    //if (formButton && formElem) {
    if (formElem) {
        //formElem.submit();
        //formElem.requestSubmit(formButton);
        // Vous reprendrez des bulles ?
        // Cf. https://github.com/final-form/react-final-form/issues/878
        //formElem.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        formElem.requestSubmit();
    }
    else {
        RenderMetaToast("Enregistrement","Enregistrement impossible...","danger",tidEnreg);
    }
}

const DynamicButtonsConnected = (buttonprops) => {
    const {
        nosavebutton=null, _id=null, dyndoc=null, __workflow__=null, type="toto",
        modal, formname, formstate
    } = buttonprops;
    const { error, invalid, submitting, pristine } = formstate;
//console.log ("DYNAMICBUTTONSCONNECTED", buttonprops);
    const extraprops   = (__workflow__ && 'extraprops' in __workflow__ && __workflow__.extraprops?__workflow__.extraprops:null);
    const extra = (b)  =>(extraprops && formname?extraprops({_id, type, confront: 'button', formname, doc:dyndoc, name: b}):null);
    //const _id          = getValueFromFormUnderEdit (formname, '_id');
    const bool         = (
       __workflow__
    && 'amidisplayed' in __workflow__
    && 'tButtons'     in __workflow__
    && formname
    && dyndoc
    && 'type'         in dyndoc
    && formname      === dyndoc.type
    && _id
    );
    //console.log ({formname, error, invalid, submitting, pristine, buttonprops});
//console.log ("MYENREGFORM", buttonprops);

    // On parcourt les triggers avec every
    // Ce qui permet d'arrêter si une opération chaînée retourne false
    return (
	<>
        {bool
            ?__workflow__.tButtons
            .filter (button => __workflow__.amidisplayed({doc:dyndoc, entry:('status' in button?button.status:null)}))
            .map    ((button,i) =>
                <Button key={i} variant="primary"
                    onClick={() => {
                        if ('triggers' in button)
                        { button.triggers.every (trigger => trigger () ) }
                    }}>
                    {button.intitule}
                </Button>
            )
            :null
        }
        {nosavebutton?null:
            <Button variant="primary" {...extra('save'  )}
                disabled={invalid || submitting || pristine}
                onClick ={() => {
                    myEnregForm (formname);
                } }
            >
                Enregistrer 
                <i className="bi bi-cloud-upload ms-2"></i>
            </Button>
        }
        <Button variant="secondary" {...extra('cancel')} onClick={()=>hideModal({modal})}>Fermer</Button>
        {error && <span>{error}</span>}
	</>
    );
}

const DynamicButtons = connect(
    (state, props) => {
//console.log ("DYNAMICBUTTONS", state);
        const modal = (props && 'modal' in props?props.modal:null);
        return ({
            dyndoc:    (modal && props.modal in state.dyndocs  ?state.dyndocs  [modal]:{}),
            formstate: (modal && props.modal in state.formstate?state.formstate[modal]:{})
        })
    },
)(DynamicButtonsConnected);

const MyPayload = (payloadprops) => {
    const {
        doc, payload, values, form
        //formname,
        //dispatch
    } = payloadprops;
//console.log ("MYPAYLOAD", payloadprops);
    React.useEffect ( () => {
        const newrev = (doc && '_rev' in doc?doc._rev:null);
        // TBC JFBJFB
        const value  = values['_rev'];
        const oldrev = (value?value:null);
        const reinit = (newrev && oldrev !== newrev);
        if (reinit) {
            //const keepDirty = false;
            //const options   = {
            //    updateUnregisteredFields: true,
            //    keepValues:               false
            //};
            //dispatch (initialize (doc, keepDirty, options));
//console.log ("INITIALIZE DOC", {values, doc});
            form.initialize (doc);
            //form.restart (doc);
        }
    })
    return (
        payload(payloadprops)
    );
}

const CommonContent = (commoncontentprops) => {
    const {
        _id, bddentry,
        formname,
        error, warning
    } = commoncontentprops;
    const beforecleanup    = () => {
        if (formname && formname in hTimeOuts) {
            clearTimeout (hTimeOuts[formname]);
            myEnregForm  (formname);
        }
    }
    const newdoc   = useDocFromId ({bddentry, _id, beforecleanup});
    const myerror  = (newdoc && 'error' in newdoc && 'message' in newdoc && newdoc.message==="missing");
    const okok     = (newdoc && (myerror || Object.keys(newdoc).length>0));
        //:   <><span>Coucou</span><LinearProgress style={{minWidth: "200px"}}/></>
//if (myerror) { console.log ("READ  ERROR", _id, newdoc); }
//else { console.log ("READ OK", _id, newdoc); }
//console.log ("COMMONCONTENT", {bddentry, _id, newdoc, myerror, okok});
    return (
        <>
        <MyPayload doc={okok?newdoc:{}} {...commoncontentprops}/>
        {error   && <span>{error  }</span>}
        {warning && <span>{warning}</span>}
        </>
    );
}

//const CommonFormConnected = (commonformprops) => {
const CommonForm = (commonformprops) => {
    const dfltsavebtnstyle = {display:'none'};
    const {
        savebtnstyle=dfltsavebtnstyle, myClass=null, modal,
        formname, handleSubmit, error, invalid, submitting, pristine
    } = commonformprops;
//console.log ("JFBJFB CommonForm", {commonformprops,savebtnstyle});
    return (
        <form name={formname} id={formname} className={myClass?myClass:"d-flex flex-column"}
            onSubmit={handleSubmit}
        >
            <CommonContent {...commonformprops}/>
            {error && <strong>{error}</strong>}
            <div style={modal?dfltsavebtnstyle:savebtnstyle}>
                <Button
                    id={formname+'-submitButton'}
                    disabled={invalid || submitting || pristine}
                    variant="primary" className="m-1"
                    type="submit"
                >
                    Enregistrer
                    <i className="bi bi-cloud-upload ms-2"></i>
                    { /*<FontAwesomeIcon icon={faSave} />*/ }
                </Button>
            </div>
        </form>
    );
}


/**
 * Correspond au tAffichage de type 'inline'.
 */
const MyHtmlSimpleCompo = (formprops) => {
    const savebtnstyle = (('autosave' in formprops && formprops.autosave)?{display:'none'}:{});
//console.log ("JFBJFB MYHTMLSIMPLECOMPO",{formprops,savebtnstyle});
    const myClass      = "d-flex flex-wrap justify-content-center";
    const np           = {savebtnstyle, myClass, ...formprops};
    return(
        <CommonForm {...np}/>
    );
};
MyHtmlSimpleCompo.propTypes = {
    autosave: PropTypes.bool,
};

/**
 * Correspond au tAffichage de type 'inmult'.
 */
const MyHtmlStepperCompo = (formprops) => {
    const mycondhidden = (
           ('displaytype'  in formprops && formprops.displaytype === 'stepper')
        || ('autosave'     in formprops && formprops.autosave)
    )
    const savebtnstyle = (mycondhidden?{display:'none'}:{});
//console.log ("JFBJFB MYHTMLMULTIPLECOMPO", {formprops,savebtnstyle});
    const np           = {savebtnstyle, ...formprops};
    return (
        <CommonForm {...np}/>
    );
};
MyHtmlStepperCompo.propTypes = {
    displaytype: PropTypes.string,
    autosave:    PropTypes.bool,
};

/**
 * Enrobage avec une fenêtre modale / show/hide avec modal
 */
const MyHtmlModaleCompoConst = (modaleprops) => {
    // initialize dans les props permet de mettre à jour l'ensemble des champs du fomulaire
    // param peut contenir {_id} ce qui permet de traquer un enregistrement spécifique
    const {modal, mycontent, show, param, title=null, classModal='modal-lg'} = modaleprops;
//const state = appstore.getState();
//console.log ("JFBJFB MYHTMLMODALECOMPOCONST", {modal, show, state, modaleprops});
    return (
        <Modal show={show}
            onHide={()=>hideModal({modal})}
            dialogClassName={classModal}
        >
            <Modal.Header closeButton>
                {title?
                <Modal.Title>{title}</Modal.Title>
                :null}
            </Modal.Header>
            <Modal.Body>
                {mycontent(param)}
            </Modal.Body>
            <Modal.Footer>
                <DynamicButtons {...modaleprops} />
            </Modal.Footer>
        </Modal>
    );
}
MyHtmlModaleCompoConst.propTypes = {
    title:      PropTypes.string,
    classModal: PropTypes.string,
};

const MyHtmlModaleCompoConnected = connect(
    (state,props) => {
        const modal = (props && 'modal' in props && props.modal?props.modal:null);
//console.log ("UPDATE", {modal, state, props});
        return ({
            show:  (modal && modal in state.modal?state.modal[modal]['show' ]:false),
            param: (modal && modal in state.modal?state.modal[modal]['param']:null)
        });
    },
)(MyHtmlModaleCompoConst);

const InModale = (formprops) => <div><MyHtmlModaleCompoConnected {...formprops}/></div>

/**
 * Au bout de 500 ms sans frappe de touche, enregistrer le formulaire "formname".
 */
const f_TimerautoSave = (formname) => {
    if (formname in hTimeOuts) {clearTimeout (hTimeOuts[formname]);}
    hTimeOuts[formname] = setTimeout(()=>{myEnregForm(formname)}, 500);
}

const MyCompo = (props) => {
//console.log ("MYCOMPO", props);
    const {k, formname, pCompo, type, displaytype} = props;
    return (
        React.createElement(
            pCompo.component,
            { name: k, formname, type, displaytype, className: "my-1 form-control", ...('others' in pCompo && pCompo.others?pCompo.others:null), ...props },
            null)
    );
}
MyCompo.propTypes = {
    k:           PropTypes.string,
    formname:    PropTypes.string,
    type:        PropTypes.string,
    displaytype: PropTypes.string,
    pCompo:      PropTypes.object,
};

/**
 * Formulaire d'édition.
 * @param {props} formname Par exemple "date"
 * @param {props} tFields Par exemple
 * "{ details:
        component: "textarea"
        intitule: "resume"
        others: {rows: "5"}
    }"
 * @param {props} tCompos {} ???.
 * @param {props} inline Boolean. Donc si !inline alors on a une div avec le label à gauche.
 * @param {props} otherProps Permet de flagguer les autres propriétés des inputs (validations, longueurs, classes...).
 *
 * tGenCompos permet de disposer les champs sous formes de plusieurs tabs si jamais on a plus de deux ... ???
 *
 * PROPS en plus : pour savoir si on a un stepper ou une tab : displayType=["tab", "stepper", "none"]
 */
const InTabs     = (props) => {
    const {formname, tKeysCompos, tCompos, type, displaytype} = props;
//console.log ("INTABS", props);
//	const tLocalParams = {
//	    'stepper': {activeStep: 0, className:"m-auto"},
//	}
// Impossible de générer un map avec des <> pour Tabs via un autre compos
// Tab doit être descendant direct de Tabs.
    return (
        <Tabs defaultActiveKey={tKeysCompos[0]}>
            {tKeysCompos.map ((k,i) =>
                <Tab key={i} eventKey={k} title={tCompos[k].intitule} >
                    <MyCompo k={k} formname={formname} pCompo={tCompos[k]} type={type} displaytype={displaytype} {...props} />
                </Tab>
            )}
        </Tabs>
    );
}
InTabs.propTypes = {
    formname:    PropTypes.string,
    displaytype: PropTypes.string,
    tCompos:     PropTypes.object,
    tKeysCompos: PropTypes.array,
};

const InStepper  = (stepperprops) => {
    const {formname, tKeysCompos, tCompos, type, displaytype, title,
           invalid, submitting, pristine} = stepperprops;
//console.log ("STEPPERPROPS", invalid, stepperprops);
    const iNbSteps   = tKeysCompos.length;
    const [activeStep, setActiveStep] = React.useState(0);
    const disabled  = (invalid || submitting || pristine);
    const handlePrev = () => { setActiveStep((prevActiveStep) => (prevActiveStep<=0?iNbSteps-1:prevActiveStep-1)); }
    const handleNext = () => { setActiveStep((prevActiveStep) => (prevActiveStep+1)%iNbSteps); }
    const iamlaststep= (iNbSteps<2 || activeStep>=iNbSteps-1);
    const myenreg    = () => myEnregForm (formname);
    const classes    = useStyles();
    //console.log ("Test VALID 2", {valid, invalid, submitting, pristine, stepperprops})
    //const canivalidate = ("canivalidate" in props?props.canivalidate:null);
    return (
        <div className="stepper-general mt-5">
            {title?
            <h4>{title}</h4>
            :null}
            <Stepper activeStep={activeStep} alternativeLabel className="m-auto">
                {tKeysCompos.map ((k,i) =>
                    <Step key={i}>
                    <StepLabel>{tCompos[k].intitule}</StepLabel>
                    </Step>
                )}
            </Stepper>
            <div>
                {tKeysCompos.map ((k,i) =>
                    <div key={i} className={activeStep !== i?'hidden':null} >
                    <MyCompo k={k} formname={formname} pCompo={tCompos[k]} type={type} displaytype={displaytype} {...stepperprops} />
                    </div>
                )}
                <div>
                    <Button disabled={activeStep<=0} onClick={handlePrev} className={classes.backButton + ' btnTextGreen me-3'} >
                        <i className="bi bi-arrow-left-circle me-2"></i>
                        Retour
                    </Button>
                    {iamlaststep
                    ?   <Button  className='btnGreen ms-3' name="validate" onClick={myenreg}    disabled={disabled}>Valider</Button>
                    :   <Button  className='btnTextGreen ms-3' onClick={handleNext} >
                            Suivant
                            <i className="bi bi-arrow-right-circle ms-2"></i>
                        </Button>
                    }
                </div>
            </div>
        </div>
    );
}

const MyTabsOrSteps = (props) => {
    const {tCompos={}, formname, type, displaytype='tabs', title=null} = props;
    //const {invalid, submitting, pristine} = props;
    const tKeysCompos     = Object.keys(tCompos);
    const nbTabs          = tKeysCompos.length;
    //const tGenSteps  = () => { return (<>{tKeysCompos.map ((k,i) => <Step    key={i}><StepLabel>{k}</StepLabel></Step>)}</>); };
    const tMydispopts     = { 'tabs': InTabs, 'stepper': InStepper };
    const pdisp           = tMydispopts[displaytype];
//console.log ("MYTABSORSTEPS", props);

    return (nbTabs < 2
        ?   <>{nbTabs<1?"---":MyCompo({k:tKeysCompos[0],formname,pCompo:tCompos[tKeysCompos[0]],type,displaytype,...props})}</>
        :   <>{pdisp({formname, tKeysCompos, tCompos, displaytype, title, ...props})}</>
    );
}
MyTabsOrSteps.propTypes = {
    tCompos:     PropTypes.object,
    title:       PropTypes.string,
    formname:    PropTypes.string,
    displaytype: PropTypes.string,
};

const tAffichage = {
     inline  : MyHtmlSimpleCompo,
     inmult  : MyHtmlStepperCompo,
};

const friskSubmit      = (doc,props) => {
    const _id          = (doc && '_id' in doc && doc._id?doc._id :null);
    const type         = ('type'       in doc           ?doc.type:('type' in props?props.type:null));
    const modal        = (props && 'modal' in props ? props.modal: null);
    const myCrud       = new CRUDcompo ({type}); // type doit être présent à minima dans les props
    const __workflow__ = (props        && '__workflow__' in props        && props.__workflow__?props.__workflow__:null);
    const hooks        = (__workflow__ && 'hooks'        in __workflow__ && __workflow__.hooks?__workflow__.hooks:null);
    const typeedit     = ('typeedit' in props && props.typeedit?props.typeedit:sDefaultTypeedit);
    const whichAffichage = (typeedit in tAffichage?typeedit:sDefaultTypeedit);
    if (whichAffichage === 'modale') { throw "modale obsolete as typeedit" }
    const beforeclbk    = (hooks && 'beforesave' in hooks && hooks.beforesave?hooks.beforesave:null);
    const thenclbk      = (hooks && 'postsave'   in hooks && hooks.postsave  ?hooks.postsave  :null);
    const errorclbk     = (hooks && 'errorsave'  in hooks && hooks.errorsave ?hooks.errorsave :null);

    return Promise.resolve()
        .then (() => {
            if (!props || !doc || Object.keys(doc).length<1) {
                console.error ("FRISKSUBMIT incohérence formname ", doc, props);
                RenderMetaToast("Enregistrement", "Enregistrement impossible", "danger", tidEnreg);
                throw new Error ("Enregistrement impossible", {_id});
            }
            //console.log ("FRISKSUBMIT", type, todo, doc, props, __workflow__, hooks);
            //console.log ("CRUD", doc, beforeclbk, thenclbk);
            // myCrud.create ou myCrud.update fonction de todo
            return (beforeclbk?beforeclbk(doc):doc);
        })
        .then  (doc => {
//console.log ("onSubmit frisk", doc);
            return myCrud.save({doc})
        })
        .then  (res => {
//console.log ("CRUD OK", res);
            // Récupérer le doc à sa dernière version
            const _id = res.id;
            RenderMetaToast("Enregistrement", "Enregistrement ok", "success", tidEnreg);
            return myCrud.read({_id});
        })
        .then (newdoc => {
//console.log ("CRUD REREAD OK", newdoc);
            if (modal) { hideModal({modal}); }
            return (thenclbk?thenclbk (newdoc):newdoc);
        })
        .catch (error => {
//console.log ("CRUD ERROR", doc, beforeclbk, thenclbk);
            if (errorclbk) { void errorclbk (error); }
            throw (error)
        });
    //console.log ("NEWDOC AFTER", doc);
}

const getMyForm            = (myformprops) => {
    const {formname, type, validate, typeedit, mytabsorsteps, props, doc=null, getlastid=null} = myformprops;
    const partof           = props.partof;
    const whichAffichage   = (typeedit in tAffichage?typeedit:sDefaultTypeedit);
    const MonContenuForm   = tAffichage[whichAffichage];
    const storestate       = appstore.getState();
    const methanol         = (doc && '__methanol__' in doc && doc.__methanol__?doc.__methanol__:null);
    const bddentry         = (methanol && 'bddentry' in methanol?methanol.bddentry:'bddentry' in props?props.bddentry:getdefaultbddentry(null));
    const lastid           = (getlastid?lastidfromtype (bddentry, type):null);
    const isid             = (doc && '_id' in doc ? doc._id : '_id' in props && props._id?props._id:lastid);
    const _id              = (isid?isid:null);
    const initialValues    = (doc ? doc : {
        ...(props.initialValues?props.initialValues():null),
        ...(partof && partof in storestate.fildAriane?monuuidpartof(partof, storestate):null),
        //...('_id' in props?newdoc:null)
    });
    const uuid_partof      = (partof   in initialValues?initialValues[partof]:null);
    const payload          = (payloadprops) => {
//console.log ("PAYLOAD" , payloadprops);
        return (
            <div className="mb-3 col-12">
                <Field component="input" type='hidden' name='_id'  />
                <Field component="input" type='hidden' name='_rev' />
                {(partof?<Field component={RenderField} type='hidden' name={partof} value={uuid_partof} required />:<></>)}
                {mytabsorsteps(payloadprops)}
            </div>
        )
    }
    //const mapStateToProps = (/*state*/) => { return { initialValues }; }
    //const mapDispatchToProps = { preLoadingDataRequest };

    /*
    JFBJFB TBC
    setSubmitFailed(formname:String, ...fields:String)
    Flips submitFailed flag true, removes submitSucceeded and submitting, marks all the fields passed in as touched, and if at least one field was passed flips anyTouched to true.

    setSubmitSucceeded(formname:String)
    Flips submitSucceeded flag true and removes submitFailed.

    startSubmit(formname:String)
    Flips the submitting flag true.

    stopSubmit(formname:String, errors:Object)
    Flips the submitting flag false and populates submitError for each field.
     * */

    const onSubmit = async values => {
//console.log ("JFBJFB onSubmit", values);
        //const valuesforsure = (values && Object.keys(values).length>0?values:appstore.getState().form[formname].values);
        //return friskSubmit (valuesforsure, props);
        return friskSubmit (values, props);
    }
//console.log ("JFBJFB GETMYFORM", {initialValues, props, myformprops});
    const MonForm =
        <Form
            mutators={{...arrayMutators}}
            onSubmit={onSubmit}
            validate={validate}
            initialValues={initialValues}
            //keepDirtyOnReinitialize
            //debug={(state, fieldStates) => { console.log ("DEBUG", {state,fieldStates}); }}
            _id={_id}
        >
        {(
            formprops,
            //{
            //    handleSubmit,
            //    form:{ mutators: {push, pop} },
            //    pristine,
            //    form,
            //    submitting,
            //    values
            //}
        ) => {
            return (
                <>
                {MonContenuForm({
                    formname,
                    payload, bddentry,
                    ...props, ...formprops // _id dans formprops
                })}
                <FormSpy onChange={formstate => { dispatchFormstate ({formname, formstate}) }} />
                </>
            )
        }}
        </Form>

    return (MonForm);
}

/**
 * @class EditMetaDocZone appellé dans la class mère MetaDocConst
 * Reçoit des props depuis la classe mère; on peut étendre ici les différents types d'affichages
 * avec inline, inmult, et modale.
 * C'est avec le payload qu'on injecte le formulaire avec un input hidden pour l'_id,
 * S'il y a un partof dans les props alors on lui crée un input hidden pour l'_id du partof.
 * On fait appel à MyTabsOrSteps en y injectant les données du formulaire.
 */
const EditMetaDoc     = (metadocprops) => {
    //return (<EditMetaDocForm formname={myForm} {...metadocprops} dyndoc={doc} />);
    const {type=null, typeedit=sDefaultTypeedit, modal=null} = metadocprops;
    //const lebonfil    = appstore.getState().fildAriane;
    //const uuid_partof = (partof in lebonfil?lebonfil[partof]:null);

    const newprops = {
       ...metadocprops,
    }
    const autosave   = ('autosave'   in newprops?newprops.autosave  :false);
    const formname   = ('formname'   in newprops?newprops.formname  :null );
    const validate   = ('validate'   in newprops?newprops.validate  :()=>true);
    const inline     = (typeedit    === 'inline'                          );
    const notafield  = ('notafield'  in newprops?newprops.notafield :false);
    const otherProps = ('otherProps' in newprops?newprops.otherProps:null );
    const tCompos    = ('tCompos'    in newprops?newprops.tCompos   :{}   );
    const argstors   = {
        autosave, formname, inline, notafield, otherProps, tCompos,
        ...('dyndoc'       in newprops ? {dyndoc:newprops.dyndoc}:null),
        ...('__workflow__' in newprops ? newprops.__workflow__   :null),
        ...newprops,
    }
    const mytabsorsteps = (tosprops) => <MyTabsOrSteps {...tosprops} {...argstors}/>
    const formprops     = {formname, validate, type, typeedit, mytabsorsteps, props:newprops};

//    return (MyForm);
    return (modal
        ?   InModale ({mycontent:(param)=>getMyForm({...param, ...formprops}), ...metadocprops})
        :   getMyForm (formprops)
    );
};
EditMetaDoc.propTypes = {
    modal:    PropTypes.string,
    type:     PropTypes.string,
    typeedit: PropTypes.string,
};

const monuuidpartof = (partof,state) => {
    let hHach={};
    hHach[partof]=state.fildAriane[partof];
    return (hHach);
}

const defFields = {
     //'denomination': {component:'input'   ,intitule:'Intitulé',others:{required: true}},
     //'description' : {component:'textarea',intitule:'Détails' ,others:{rows    :'5'  }},
     'denomination': {component:'input'   ,intitule:'Dénomination',others:{required:true}},
     'details'     : {component:'textarea',intitule:'Commentaire' ,others:{rows    :'5' }},
};

// validators est un tableau de règles de validation du Field
const composeValidators = (validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

// inline ?
//   notafield => afficher le component dans un div,
//   sinon en tant que Field pour être géré par redux
// sinon
//   Afficher un label et un Field si composant actif
const MyFieldOrNot = (myfieldornotprops) => {
    const {i, k, type="truc", formname, myfield, props, mycssclass} = myfieldornotprops;
    const {_id=null, autosave=false, notafield=false, otherProps=null} = props;
    const myTimerAutosave = (autosave?{onChange: () =>{f_TimerautoSave(formname)}}:null);
    const extra           = ('extraprops' in props?props.extraprops({_id, type, confront: 'field', formname, doc:('dyndoc' in props?props.dyndoc:{}),name:k}):null);
    const mfothers        = (myfield  && 'others'     in myfield ?myfield.others                        :null);
    const mfvalidate      = (mfothers && 'validators' in mfothers?composeValidators(mfothers.validators):null);

    return (notafield
        ?   <div   key={i} name={k} className={mycssclass}>{React.createElement(myfield.component, {...mfothers, ...otherProps, ...extra})}</div>
        :   <Field key={i} name={k} validate={mfvalidate} render = {(props) => {
                const {input, meta} = props;
                const error   = (meta && 'error'   in meta ? meta.error   : null );
                const MyError = () => (error ? <span style={{color: "red"}}>{error}</span> : null)
//console.log ("PROPS", {mfvalidate, _id, props, myfieldornotprops})
                return (
                    <>
                    {React.createElement(myfield.component, {_id, className:mycssclass, ...mfothers, ...otherProps, ...extra, ...myTimerAutosave, ...input, meta})}
                    <MyError />
                    </>
                )
            }}/>
    );
}

const MyField   = (myfieldprops) => {
    const {k, i, type, formname, myfield, props} = myfieldprops;
    const {inline=false}                         = props;
//if (k === 'version' || k === 'status') {
//console.log ("EXTRA", extra, props);
//}
    const myRet = (inline
        ?   MyFieldOrNot ({k, i, type, formname, myfield, props, mycssclass:"col-12 my-1 form-control"})
        :   <div className="d-flex" key={i}>
                <label className="col-3" htmlFor={k}>{myfield.intitule}</label>
                {MyFieldOrNot ({k, i, type, formname, myfield, props, mycssclass:"my-1 form-control"})}
            </div>
    );
    return (myRet);
}

export const ListFields = (props) => {
//console.log ("LISTFIELDS", props);
    const {tFields=null, type, formname, whichAffichage=sDefaultTypeedit} = props;
    const tMyFields = (tFields
        ?   tFields
        :   whichAffichage==='inline'
        ?   { 'details' : {component:('html_type' in props?props.html_type:'textarea'),intitule:('type' in props?props.type:'details'),others:{rows:'5'}}}
        :   defFields
    );
    const tGenFields = Object.keys(tMyFields).map ((k,i) => MyField({k, i, type, formname, myfield: tMyFields[k], props}));
    return (
        <>
        {tGenFields}
        </>
    );
}
ListFields.propTypes = {
    tFields:        PropTypes.object,
    formname:       PropTypes.string,
    type:           PropTypes.string,
    html_type:      PropTypes.string,
    whichAffichage: PropTypes.string,
};

/**
 * DT representation.
 * On ne crée que le bouton d'ajout des compo que si le type existe.
 */
const MyCellAction = (r,_,modal) => <CellAction rowdata={r} actions={['edit','trash']} modal={modal} />
const hDescDtDefInit = {
    "denomination": { title: "Dénomination courte", },
    "details"     : { title: "Commentaire" , },
    "action"      : { title: "Actions" , contenu: MyCellAction },
};

const dfltaddbutton         = (modal)   => { showModal({modal, resetFirst:true}); }
const MetaDocDatatableConst = (props) => {
    const {type=null, partof=null, tracktype=null,
          hDescDt=hDescDtDefInit, addbutton=dfltaddbutton} = props;
    //const modal         = (tracktype?tracktype:type);
    // modal avec uuid unique
    const modal         = uuid();
    const mycompo       = myFabrik.getCompo(tracktype?tracktype:type, partof);

    // partof (etude par exemple) passé dans les props
    // Affichage de: datatable, fenêtre modale, bouton pour ajouter.
//console.log ("JFBJFB AFFICHAGE DATATABLE", props);
    return (
        <div className="w-100 text-center text-md-left">
            <Datatable type={type} modal={modal} hDescDt={hDescDt} {...props} />
            <EditMetaDoc {...mycompo} modal={modal} formname={modal} />
            {   addbutton
            ?   <div className="text-center mt-5">
                    <Button className='btnTextGreen' onClick={() => addbutton(modal)}>
                        Ajouter
                        <i className="bi bi-file-earmark-plus ms-2"></i>
                    </Button>
                </div>
            :   null
            }
        </div>
    );
}
MetaDocDatatableConst.propTypes = {
    addbutton: PropTypes.bool,
    hDescDt:   PropTypes.object,
    tracktype: PropTypes.string,
    partof:    PropTypes.string,
    type:      PropTypes.string,
};

/**
 * @class MetaDocConst. Chaque champs provenant de ../model/model_phase est un metadoc.
 * Tous les param se trouvent dans les props de la classe.
 * @param {props} html_type Si existe dans les props alors champs d'inputs (input, textarea, ...), sinon DT.
 * @param {props} formname Le nom du formulaire qui servira à l'enregistrement via redux-form. Si pas passé en paramètre on reprend le type.
 * @param {props} type Le type qui permet de donner le nom au document pouchdb/couchdb.
 * @param {props} title Intitulé (à afficher dans l'IHM).
 * @param {props} partof Type d'un document sur lequel la metadoc sera lié (cveeez par exemple).
 * @param {props} className Classe css/html propre au champs label + input.
 * @param {props} tip Message d'aide dans tip.
 * A rajouter : les props pour les DT (tFields, entetes, etc ...)
 */
const MetaDocConst = (props) => {
    // Flagger automatiquement autosave pour les champs avec html_type
    const myprops  = {...props};
    const typeedit = (props && 'typeedit' in props && props.typeedit?props.typeedit:'inline');
    const type     = (props && 'type'     in props && props.type    ?props.type    :null    );
    const formname = (props && 'formname' in props && props.formname?props.formname:type    );
//console.log ("METADOCCONST", props)
    if (myprops) { myprops.autosave = (props && 'autosave' in props?props.autosave:true); }
    if (!type)   { console.error ("MetaDocConst", props); throw new Error ("Error MetaDocConst", {props}); }
//console.log ("METADOCCONST", {formname, typeedit, props});
    return (props.wecanstart === false
        ?   <span>App starting...</span>
        :   ('hDescDt' in props)
            ?   <MetaDocDatatableConst {...props}/>
            :   type
                ?   <EditMetaDoc formname={formname} typeedit={typeedit} {...myprops} />
                :   "Error"
    )
}
MetaDocConst.propTypes = {
    wecanstart: PropTypes.bool,
    typeedit:   PropTypes.string,
    formname:   PropTypes.string,
    type:       PropTypes.string,
    autosave:   PropTypes.bool,
};

export const MetaDoc = connect(
    (state) => ({
         wecanstart: state.wecanstart,
    }),
)(MetaDocConst);

