import {appstore}               from './Communs';

export const initFormstate = {};
export const FORMSTATE_CONSTS  = {
    UPDATE_FORMSTATE : "UPDATE_FORMSTATE",
}
export const formpropsInitState = { formstate: initFormstate, }

const formpropsstatereducer = (state=initFormstate, action) => {
    switch (action.type) {
        case FORMSTATE_CONSTS.UPDATE_FORMSTATE: {
            const newStateFormstate = { ...state };
            newStateFormstate[action.formname] = (action && 'formstate' in action?action.formstate:{});
            return newStateFormstate;
        }
        default: {
            return state;
        }
    }
}
export const formstateReducers  = { formstate: formpropsstatereducer, }

// -----------------------------------------------------------------------------
// Montrer la modale de saisie du component
export const dispatchFormstate = ({formname, formstate={}}) => {
    appstore.dispatch({type:FORMSTATE_CONSTS.UPDATE_FORMSTATE, formname, formstate});
}

