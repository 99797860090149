import React      from 'react';
import PropTypes  from 'prop-types';
import {connect}  from 'react-redux';
import Badge      from 'react-bootstrap/Badge';
import Toast      from 'react-bootstrap/Toast';

import {uuid}     from './Communs';
import {appstore} from './Communs';

export const closeMetaToast = (myuuid) => {
    appstore.dispatch ({type: METATOASTS_CONSTS.DEL_TOAST, content: {myuuid}});
}

export const METATOASTS_CONSTS = {
    ADD_TOAST        : "ADD_TOAST",
    DEL_TOAST        : "DEL_TOAST",
}
export const metatoastInitState = { listemetatoasts: {}, }

export const toastMaxTime = 5000;

// Code à injecter dans le store de l'application
export const listemetatoastsreducer = (state={}, action) => {
    const myuuid = ('content' in action && 'myuuid' in action.content && action.content.myuuid?action.content.myuuid:uuid());
    const myHashMetaToasts = {...state};

    // on affiche les metatoasts depuis le store sur ADD_TOAST et DEL_TOAST.
    // Lorsqu'on fait un nouveau ADD_TOAST alors que des metatoasts sont déja displayed,
    // on détruit le timeout (et le metatoast reste actif même si tout est oK).
    // Il faut donc détruire les metatoasts "à la main".
    const nowDate = new Date().getTime();
    Object.keys(myHashMetaToasts).forEach (metatoastname => {
        const metatoast = myHashMetaToasts[metatoastname];
        if ((metatoast.variant !== 'danger') && (nowDate - metatoast.creationDate > toastMaxTime)) {
            delete myHashMetaToasts[metatoast.myuuid];
        }
    });

    switch (action.type) {
        case METATOASTS_CONSTS.ADD_TOAST:
            // En réutilisant le même myuuid il est possible de changer le message
            // Début de l'action, message de démarrage, ensuite succès ou erreur.
            myHashMetaToasts[myuuid] = action.content;
            return myHashMetaToasts;
        case METATOASTS_CONSTS.DEL_TOAST:
            if (myuuid && myuuid in myHashMetaToasts)
            { delete myHashMetaToasts[myuuid]; }
            return myHashMetaToasts;
        default:
            return state;
    }
}
export const metatoastReducers  = { listemetatoasts: listemetatoastsreducer, }

const MyMetaToast = (props) => {
    const {titre, message, variant="primary", myuuid=null} = props;
    // Pas de timeout pour les alarmes / doivent être acquittées manuellement
    const mondelay   = (variant === 'danger'?{delay:toastMaxTime,autohide:true}:{autohide:true});
    const myaria     = (variant === 'danger'?{'role':"alert", 'aria-live':"assertive"}:{'role':"status", 'aria-live':"polite"});

    return (
        <Toast {...myaria} show={true} onClose={()=>{closeMetaToast(myuuid);}} {...mondelay}>
            <Toast.Header>
                <Badge bg={variant}><strong className="mr-auto">{titre}</strong></Badge>
            </Toast.Header>
            <Toast.Body>
                {message}
            </Toast.Body>
        </Toast>
    );
}
MyMetaToast.propTypes = {
    titre:   PropTypes.string,
    message: PropTypes.string,
    variant: PropTypes.string,
    myuuid:  PropTypes.string,
};

const ListeMetaToastsConst = (props) => {
    const {listemetatoasts}= props;

    return (listemetatoasts
        ?
        <div className="toast-container">
        { Object.keys(listemetatoasts).map ((k,i)=> <MyMetaToast key={i} {...listemetatoasts[k]}/>) }
        </div>
        :   null
    );
}
ListeMetaToastsConst.propTypes = {
    listemetatoasts: PropTypes.object,
};
const ListeMetaToastsConnected = connect(
    (state) => ({ listemetatoasts: state.listemetatoasts }),
)(ListeMetaToastsConst);

export const ListeMetaToasts = () => {
    // Armer un timer pour balayyer les metatoasts toutes les 2*toastMaxTime''
    // Lié à un bug dand MetaToast (pas d'autohide sur certains MetaToasts)
    setInterval (() => {
        closeMetaToast(null);
    }, 2*toastMaxTime);
    return (
        <ListeMetaToastsConnected />
    );
}

export const RenderMetaToast = (titre, message, variant='info', myuuid=null) => {
    const uuidforsure  = (myuuid?myuuid:uuid()); 
    const creationDate = new Date().getTime();
    const mylog        = {
        type: METATOASTS_CONSTS.ADD_TOAST,
        content: {titre, message, variant, myuuid: uuidforsure, creationDate}
    };
    if (appstore)
    { appstore.dispatch (mylog); }
    else
    { console.log ("META TOAST", mylog); }
    return (uuidforsure);
}

