import {EventEmitter}        from 'events';
import React                 from 'react';
//import {change}            from 'redux-form';
import PouchDB               from 'pouchdb-core';
import PouchdbHttp           from 'pouchdb-adapter-http';
import PouchdbIdb            from 'pouchdb-adapter-idb';
import PouchdbMemory         from 'pouchdb-adapter-memory';
//import PouchdbReplication    from 'pouchdb-replication';
import PouchdbAuth           from 'pouchdb-authentication';
import PouchdbFind           from 'pouchdb-find';
//import PouchdbReplicate      from 'pouchdb-replicate';
import PouchdbMapReduce      from 'pouchdb-mapreduce';
import PouchdbChangesFilter  from 'pouchdb-changes-filter';

//import PouchdbDebug        from 'pouchdb-debug';
//import PouchdbLocalstorage from 'pouchdb-adapter-localstorage';

import {disconnectFromMyApp} from './Login';
import {RenderMetaToast}     from './MetaToast';
import {setappstore}         from './Communs';
import {appstore}            from './Communs';
import {appmodels}           from './Communs';
import {uuid}                from './Communs';
import {injectdate}          from './Communs';

const Promise = require('lie');

PouchDB.plugin(PouchdbHttp);
PouchDB.plugin(PouchdbIdb);
PouchDB.plugin(PouchdbMemory);
//PouchDB.plugin(PouchdbReplication);
PouchDB.plugin(PouchdbAuth);
PouchDB.plugin(PouchdbFind);
//PouchDB.plugin(PouchdbReplicate);
PouchDB.plugin(PouchdbMapReduce);
PouchDB.plugin(PouchdbChangesFilter);
//PouchDB.plugin(PouchdbDebug);
//PouchDB.plugin(PouchdbLocalstorage);
//PouchDB.debug.enable('*');
//PouchDB.debug.enable('pouchdb:put');

const mysrv    = (process.env.REACT_APP_REMOTE_COUCH_SRV ?process.env.REACT_APP_REMOTE_COUCH_SRV:"https://couchdb.si.g-echo.fr:6984/")
const couchurl = (dbserver=null,dbname=null) => (dbserver?dbserver:mysrv)+(dbname?dbname:'');

/* 2 bases de données ou plus sont gérées en parallèle.
 * Exemple pour friskeeez:
 * - La base de données 'friskeeez-central' contient la liste des études et des utilisateurs,
 * - La base de données en cours d'édition caractérisée par son _id avec l'alias 'working'.
 * Les bases sont synchrones en local et en distant si le stockage local et distant sont disponibles.
 * Les bases bases de données sont également 'in memory' pour un accès facilité et rapide.
 */
export const infosdbs  = {__baseurl__: couchurl(null,null)}
//let iCpt=0;
//export const mymetalog = (props) => {
//    const wecanstart = appstore.getState().wecanstart;
//    if (!(infosdbs && "author-commons" in infosdbs)) {
//        iCpt++; if (iCpt>100) {iCpt=100;}
//    } else {
//        iCpt = 0;
//    }
//    if (wecanstart && iCpt>0) { console.error (infosdbs, props); }
//}

const defliveoptions  = {
    live: true, retry: true, since: 'now', include_docs : true,
    // https://github.com/facebook/react-native/issues/9436
    // https://github.com/facebook/react-native/issues/14391
    timeout: 5000, // 5 secondes
    //batches_limit: 10, batch_size: 1000
};
const fetchwithjwtcookie = (url, opts) => {
    //const authToken = localStorage.getItem("JWT");
    const logged      = appstore.getState().logged;
    const info        = (logged && 'info'        in logged?logged.info     :null);
    const bearerToken = (info   && 'bearerToken' in info  ?info.bearerToken:null);
    if (bearerToken === null) { opts.headers.set('Authorization', null                 ); }
    else                      { opts.headers.set('Authorization', 'Bearer '+bearerToken); }
    //opts.credentials= 'same-origin';
    return PouchDB.fetch(url, opts);
}
const methanolsyncinfos = {
    'memorydb': {action: {full: 'changes', light: null     }, options: defliveoptions}
   ,'localdb' : {action: {full: 'sync'   , light: null     }, options: defliveoptions}
   ,'remotedb': {action: {full: 'sync'   , light: 'changes'}, options: defliveoptions}
}
export const methanolPouchDb = {
    "memorydb": (k) => new PouchDB(k                   , {adapter: 'memory'                                  , fetch:fetchwithjwtcookie}),
    "localdb" : (k) => new PouchDB(k                   , {adapter: 'idb'                     /*, cache:true*/, fetch:fetchwithjwtcookie}),
    "remotedb": (k) => new PouchDB(infosdbs[k].url     , {adapter: 'http'  , skip_setup: true/*, cache:true*/, fetch:fetchwithjwtcookie}),
    "logindb" : (k) => {
        return new PouchDB(infosdbs.__baseurl__+'/'+k, {adapter: 'http'  , skip_setup: true                ,                         })
    }
    ,
}
// 2 modes:
// - full: bdd memory, stockage local et sync sur remote,
// - light: remote only.
const methanolmode = (process.env.REACT_APP_METHANOL_MODE?process.env.REACT_APP_METHANOL_MODE:'light');
const methanolbdds = {
    'light': ['remotedb'],
    'full':  ['memorydb','remotedb','localdb'],
}
const        tmodesbdds = methanolbdds[methanolmode];
export const defoltdb   = tmodesbdds[0]; // memorydb pour full, remotedb pour light

export const BDD_CONSTS = {
    UPDATE_CONNEXION : "UPDATE_CONNEXION",
    DOCLIVEFROMFORM  : "DOCLIVEFROMFORM",
    WECANSTART       : "WECANSTART", // Permet de savoir que l'initialisation du logiciel est terminée
}

export const bddInitState = {
    connexions       : {},           // Etat des connexions avec les bases de données
    wecanstart       : false,        // Signale que l'application peut démarrer
    dyndocs          : {},
}

const connexionsReducer = (state={}, action) => {
    switch (action.type) {
        case BDD_CONSTS.UPDATE_CONNEXION: {
            // Transformer {bdd: nombdd, loc: memory, con: denied}
            // En table[nombdd][memory]='denied'
            const newState  = {...state};
            const connexion = ('connexion' in action && action.connexion?action.connexion:null);
            const _id       = (connexion && '_id' in connexion?connexion._id:null);
            const bdd       = (connexion && 'bdd' in connexion?connexion.bdd:null);
            const loc       = (connexion && 'loc' in connexion?connexion.loc:null);
            const con       = (connexion && 'con' in connexion?connexion.con:null);
            if (bdd && loc && con) {
                if (!(bdd in newState           )) { newState[bdd]            = {_id,tLocs:{}}; }
                if (!(loc in newState[bdd].tLocs)) { newState[bdd].tLocs[loc] = {};             }
                newState[bdd].tLocs[loc] = con;
            }
            return (newState);
        }
        default:
            return state;
    }
}

const dyndocsreducer = (state={}, action) => {
    switch (action.type) {
        case BDD_CONSTS.DOCLIVEFROMFORM: {
            const newstate = {...state};
            const doctype  = ('type' in action.dyndoc?action.dyndoc['type']:'__default__');
            if (doctype === '__default__') { console.error ("Erreur applicative dyn docs reducer", action); }
            newstate[doctype] = action.dyndoc;
            return newstate;
        }
        default:
            return state;
    }
}

const wecanstartreducer = (state=false, action) => {
    switch (action.type) {
        case BDD_CONSTS.WECANSTART:
            return action.wecanstart;
        default:
            return state;
    }
}

export const bddReducers  = {
    connexions       : connexionsReducer,
    wecanstart       : wecanstartreducer,
    dyndocs          : dyndocsreducer,
}

let defaultbddentry = null;
export const setdefaultbddentry = (bddentry)     => { defaultbddentry = bddentry; }
export const getdefaultbddentry = (doc=null)     => {
    const tkeysinfosdbs = (infosdbs?Object.keys(infosdbs):null); // 1er clef __baseurl__
    const dbe = (doc
        ?'bddentry' in doc && doc.bddentry
        ?doc.bddentry
        :'__methanol__' in doc && 'bddentry' in doc.__methanol__ && doc.__methanol__.bddentry
        ?doc.__methanol__.bddentry
        :defaultbddentry?defaultbddentry:(tkeysinfosdbs.length>0?tkeysinfosdbs[1]:null)
        :        defaultbddentry?defaultbddentry:(tkeysinfosdbs.length>0?tkeysinfosdbs[1]:null)
    );
    return (dbe);
}

export const addfuel            = (bddentry,doc) => {
    const af = (be, d) => {
        d['__methanol__']={bddentry: be};
        return (d);
    }
    if (doc && 'constructor' in doc && doc.constructor === Array)
    { return (doc.map (d => af (bddentry, d))); }
    else
    { return (af (bddentry, doc)); }
}

export const delfuel            = (doc) => {
    const df = (d) => {
        const md = {...d};
        if ('__methanol__' in md)
        { delete md.__methanol__; }
        return (md);
    }
    let myret = null;
    if (doc.constructor === Array)
    { myret = doc.map (d => df (d)); }
    else
    { myret = df (doc); }
    return (myret);
}

/** 
 * Etat de la connexion avec le serveur
 *  
 */
export const tStateConnexion = {
    'loading'     : { background: 'badge-warning blinking', callBack: null },
    'disconnected': { background: 'badge-danger'          , callBack: null },
    'active'      : { background: 'badge-success'         , callBack: null },
    'syncing'     : { background: 'badge-primary blinking', callBack: null },
    'connected'   : { background: 'badge-success'         , callBack: null },
    'complete'    : { background: 'badge-success'         , callBack: null },
    'paused'      : { background: 'badge-muted'           , callBack: null },
    'denied'      : { background: 'badge-warning'         , callBack: null },
    'error'       : { background: 'badge-dark'            , callBack: null },
}


//const remoteDBName= Object.keys (infosdbs).reduce ((a,k)=>{a[k]=url(k);return (a);},{});

EventEmitter.defaultMaxListeners = 96;
//mDB.setMaxListeners(32); rDB.setMaxListeners(32);

// Tous les évènements change font un trigger sur les BD
let _tDBchanges      = {};
// Les objets peuvent s'abonner et se désabonner au trigger
// Cf fonctions subscribeToBddChanges et unsubscribeToBddChanges
let _tAbonnes        = {}
let _tDesabonnements = {}
export const closeremainingabonnements = () => {
    Object.keys(_tDesabonnements).forEach (d => {
        const pdesabonnement = _tDesabonnements[d];
//console.error ("DESABONNEMENT", d, pdesabonnement);
        (pdesabonnement && 'cancel' in pdesabonnement) && pdesabonnement['cancel'].cancel();
    });
}

export const closemydbs = (bddentry=null) => {
    const tlisteabos = (bddentry?[bddentry]:Object.keys(_tDBchanges));
//console.log ("CLOSEMYDBS DEBUT tlisteabos tmodesbdds _tDBchanges", tlisteabos, _tDBchanges, infosdbs, tmodesbdds);
//console.log ("_TDBCHANGES", _tDBchanges);
//console.log ("INFOSDB", infosdbs);
    const l_resilierabonnements = () => {
        tlisteabos.forEach (e => {
            if (e in _tDBchanges) {
                //mymetalog ("CLOSEMYDBS", e);
                _tDBchanges[e][defoltdb].cancel();
                (e && e in _tDBchanges) && delete _tDBchanges[e];
            } else {
                console.error ("ERROR on close", e);
            }
        });
    }
    const l_fermerbdsouvertes = () => {
        const tkeysinfosdbs = (infosdbs
            ?   Object.keys(infosdbs).filter (k => (k != "__baseurl__"))
            :   null                 ); // 1er clef __baseurl__
        tmodesbdds.forEach(typebdd => {
            tkeysinfosdbs.forEach (k => {
//console.log ("JFBJFB", k, typebdd, infosdbs);
                if (k in infosdbs && typebdd in infosdbs[k]) {
    //mymetalog ("CLOSEMYDBS DB...", k, typebdd);
                    infosdbs[k][typebdd].close()
                    .then(() => {
                        //console.log ("OK Déconnexion", res, k, typebdd);
                    })
                    .catch(error => {
                        console.error ("ERREUR Déconnexion", error, k, typebdd);
                    });
                    delete infosdbs[k];
                }
                else {
                    console.error ("ERREUR Déconnexion impossible", k, typebdd);
                }
            })
        });
    }

    // Supprimer en premier tous les abonnements à des onchange
    // Fermer toutes les bases de données ouvertes
    l_resilierabonnements     ();
    closeremainingabonnements ();
    l_fermerbdsouvertes       ();

//console.log ("CLOSEMYDBS FIN _tDBchanges infosdbs tmodesbdds", _tDBchanges, infosdbs, tmodesbdds);
}

// Avant de rendre la main au navigateur (on quitte la page),
// faire un cancel des bases de données.
if (typeof window !== 'undefined') {
    window.addEventListener("beforeunload", (/*event*/) => {
        //event.returnValue = "\o/";
        closemydbs ();
    });
}

// Liste des uuids des toasts des différentes étapes de démarrage
//let uuidTE     = {
//    indexdata    :{},
//    synchro      :{},
//    localstorage :{},
//    remotestorage:{},
//};

export const F_InjectStore = (store       ) => {
    setappstore (store);
}

export const indexnamefromi = (i) => "myindex-"+i

// Permet de créer des indexes pour accéler les requêtes des Bdds
const myPromiseCreateIndex = ({bddentry,tListeFields,i}) => {
    const pDB = infosdbs[bddentry][defoltdb];
    return pDB.createIndex({
        index: {fields: tListeFields, ddoc: indexnamefromi(i)}
    })
}

const selector = {
    "selector": { "type": { "$exists": true } },
    "use_index": indexnamefromi(0)
};
const f_vPrepareIdFromType = (bddentry) => {
    return Promise.resolve()
        .then (() => {
            return infosdbs[bddentry][defoltdb].find(selector)
        })
        .then  (res => {
            const  red = res.docs.reduce((a,d) => {a[d.type]=d._id; return a},{});
            return (addfuel(bddentry, red));
        })
        .catch (error => { throw (error) });
}

// Retourner l'_id du dernier enregistrement vu avec le type passé en paramètre.
// Permet de requêter les éléments uniques dans la Bdd sans connaître leur _id.
export const lastidfromtype = (bddentry,type) => {
    const plidftyp = (bddentry in infosdbs && 'lastidfromtype' in infosdbs[bddentry]?infosdbs[bddentry].lastidfromtype:null);
    const lastid   = (plidftyp && type in plidftyp?plidftyp[type]:null);
    return (lastid);
}

// promise garantit que les index seront prêts lors des 1ères requêtes
// A faire dans l'ordre:
// - Préparer les index (pour find),
// - Synchroniser la base de données locale,
// - synchroniser la base de données distance
// On ne peut démarrer qu'après ces étapes.
const f_vDemarrerSync = (bddentry) => {
    //mDB.info().then((result) => { console.log("STATS:",result); }).catch(function (error) { console.log("STATS:",error); });
//    uuidTE.synchro[bddentry]=RenderMetaToast(
//        "Etape 4",
//        "Démarrage synchronisation temps réel "+bddentry+"...",
//        "info"
//    );
    // Ensuite on démarre la réplication temps réel memory, local, remote
    if (bddentry in _tDBchanges)
    {
        throw new Error (
            "===> Database sync allready done... Can't initialize twice. <===",
            {bddentry}
        );
    }
    _tDBchanges[bddentry] = tmodesbdds.reduce ((a,e) => {a[e]={}; return (a);}, {});
    // Lancer la synchronisation temps réel
    return (
        Promise.all ([ tmodesbdds.map (typedb => f_vSyncBdd ({typedb, bddentry})) ])
    );
}

const f_vConnecterStorage = ({bddentry=getdefaultbddentry(), typedb/*, step*/}) => {
//    uuidTE.[typedb+'storage'][bddentry]=RenderMetaToast(
//        "Etape "+step,
//        "Synchronisation stockage "+typedb+" ... "+bddentry,
//        "info"
//    );
    // Synchronisation one-way
    return Promise.resolve()
        .then (() => {
            return infosdbs[bddentry][defoltdb].replicate.from(infosdbs[bddentry][typedb])
        })
        .then(result => {
            //RenderMetaToast( "Etape "+step, "Stockage "+typedb+" synchronisé "+bddentry, "success", uuidTE[typedb+'storage'][bddentry]);
            //console.log ("SYNC LOCAL 2", new Date().toISOString(), result);
            return (result);
        })
        .catch(error => {
            //RenderMetaToast( "Etape "+step, "ERROR Synchronisation stockage "+typedb+" "+bddentry, "danger",  uuidTE[typedb+'storage'][bddentry]);
            onSyncError(bddentry, typedb, error);
            throw (error);
        })
}

const PreparaLastIdFromType = (bddentry) => {
    // Les données sont arrivées en mémoire depuis le stockage local et/ou distant
    // Initialiser maintenant lastidfromtype
    // Ce mécanisme permet d'avoir à disposition les _id des types avec un seul enregistrement
    // sans devoir passer par un find.
    return Promise.resolve()
        .then (() => {
            return f_vPrepareIdFromType (bddentry)
        })
        .then  (values => { infosdbs[bddentry].lastidfromtype = values[0]; return (values); })
        .catch (error => {
            console.error (">>>> From type mecanism not available <<<<", bddentry, error);
            throw (error);
        })
}

export const okstartapp = (bddentry) => {
    return Promise.resolve()
        .then (() => {
            return PreparaLastIdFromType (bddentry)
        })
        .then  ((/*res*/) => {
            //console.log ("START CONNECT", res);
            // Lancer la synchronisation continue
            return (f_vDemarrerSync (bddentry));
        })
        .then  (rests => { appstore.dispatch({ type: "WECANSTART", wecanstart: true }); return (rests); })
        .catch (error => {
            console.error (bddentry, error);
            throw (error);
        });
}

const f_ConnectBdd = (bddentry) => {
    return new Promise ((resolve, reject) => {
        if (methanolmode === 'full') {
            Promise.all([
                f_vConnecterStorage ({bddentry, typedb:"localdb",  step:"2"}),
                f_vConnecterStorage ({bddentry, typedb:"remotedb", step:"3"})
            ])
            // Dans tous les cas de figure, démarrer la synchronisation temps réel
                .then  (res   => { console.log ("RES START", res);   return resolve (res);     })
                .catch (error => { console.error (bddentry, error);  return reject (error);    })
            ;
        }
        else { return resolve ("oK"); }
    });
}

/* 
 * 2 étapes:
 * - F_PrepareBdd prépare les structures de données de la bdd,
 * - F_RunBdd     lance le chargement et les synchronisations temps réel de la bdd
 * bddserver donne le nom ou l'adresse IP du serveur couchdb
 * bddentry pointe sur la base de données qui sert à se connecter
 * Exemple dans le cas de friskeeez: couchdb.g-echo.fr et friskeeez-central
 */
export const F_PrepareBdd = ({bddserver=null, _id=null, bddentry=getdefaultbddentry()}) => {
    return Promise.resolve()
    .then (() => {
        if (bddentry in infosdbs) {
            const error = {
                error: '===> Database creation can only be done once, destroy first <===',
                bddentry
            };
            //console.log ("ERROR", error, bddserver, _id, tmodesbdds, infosdbs);
            throw (error);
        }

        /* Avant quoi que ce soit:
         * 1 - enregistrer le nom et l'alias de la bdd centrale
         */
        infosdbs[bddentry]                = {};
        infosdbs[bddentry]._id            = _id;
        infosdbs[bddentry].url            = couchurl(bddserver,bddentry);
        tmodesbdds.forEach (typedb => 
            infosdbs[bddentry][typedb]    = methanolPouchDb[typedb](bddentry)
        );
        //infosdbs[bddentry].memorydb       = memorydb(bddentry);
        //infosdbs[bddentry].remotedb       = remotedb(bddentry);
        //infosdbs[bddentry].localdb        = localdb (bddentry);
        infosdbs[bddentry].lastidfromtype = {};
        return ('oK');
    })
}

export const F_RunBdd     = ({bddentry=getdefaultbddentry(), tIndexesList=[]}) => {
    // Ensuite préparer les index et synchroniser la base distance
//    uuidTE.indexdata[bddentry]=RenderMetaToast(
//        "Etape 1",
//        "Indexer les données "+bddentry+"...",
//        "info")
    //const tPromissIndexList = [];
    const tPromissIndexList = tIndexesList.reduce ((a,tListeFields,i) => {
        a.push(myPromiseCreateIndex({bddentry,tListeFields,i}));
        return (a);
    }, [])
    return Promise.resolve()
        .then (() => {
            if (!(bddentry in infosdbs         )) { const error = { error: "===> Database shall be initialized first. Call F_PrepareBdd. <==="   , bddentry}; throw new Error (error); }
            if ('indexes'  in infosdbs[bddentry]) { const error = { error: "===> Can't re-create database indexes (second call,app problem) <===", bddentry}; throw new Error (error); }
            return Promise.all(
                tPromissIndexList
                //[
                //myPromiseCreateIndex (['type'                  ]), // 0
                //...
                //myPromiseCreateIndex (['type','etude','nature','denomination']), // 9
                //]
            )
        })
        .then (indexes => {
            infosdbs[bddentry][defoltdb].indexes = indexes; // Pour pouvoir sélectionner son index lors d'un find
            //            RenderMetaToast(
            //                "Etape 1",
            //                "Données indexées "+bddentry+"...",
            //                "success",
            //                uuidTE.indexdata[bddentry]
            //            )
            return (f_ConnectBdd(bddentry));
        })
    // Then let it flow
}

const mycon          = (bdd, loc, con          ) => { const _id=(bdd in infosdbs && '_id' in infosdbs[bdd]?infosdbs[bdd]._id:null); return {_id, bdd, loc, con}; }
const eventtostore   = (bddentry, loc, myevent ) => { appstore.dispatch({ type: BDD_CONSTS.UPDATE_CONNEXION, connexion: mycon(bddentry, loc, myevent ) }) }
const onDbChange     = (bddentry, loc, change  ) => { eventtostore(bddentry, loc, 'syncing' ); f_vDispatchChangeById(bddentry, loc, change);    }
const onSyncComplete = (bddentry, loc, /*info*/) => { eventtostore(bddentry, loc, 'complete');                                                  }
const onSyncDenied   = (bddentry, loc, /*info*/) => { eventtostore(bddentry, loc, 'denied'  ); /* Replication was denied  */                    }
const onSyncActive   = (bddentry, loc, /*info*/) => { eventtostore(bddentry, loc, 'active'  ); /* Replication was resumed */                    }
const onSyncPaused   = (bddentry, loc, /*info*/) => { eventtostore(bddentry, loc, 'paused'  ); /* Replication was paused (lost connexion?) */   }
const onSyncError    = (bddentry, loc, error   ) => { eventtostore(bddentry, loc, error     );
    /* Cause totally unhandled error (shouldn't happen) logout */
    console.error ("REMOTE ERROR logout...",bddentry,loc,error);
    disconnectFromMyApp()
}

const tevents = {
    'change'  : onDbChange
    ,'paused'  : onSyncPaused
    ,'active'  : onSyncActive
    ,'denied'  : onSyncDenied
    ,'complete': onSyncComplete
    ,'error'   : onSyncError
}
const tkeysevents = Object.keys (tevents);

const onbddevent       = (typeevent, bddentry, loc, info   ) => {
    const defaultevent = (...props) => console.log ("ERROR defaultevent", props);
    const pclbk        = (typeevent in tevents?tevents[typeevent]:defaultevent);
    pclbk && pclbk (bddentry, loc, info);
}

const addhooksonevents = (mybdd, bddentry) => {
    tkeysevents.forEach (e => mybdd.on(e, (info) => onbddevent(e, bddentry, 'memory', info)));
}

const f_vSyncBdd = ({typedb, bddentry}) => {
    const dbinfos= (typedb && typedb in methanolsyncinfos?methanolsyncinfos[typedb]:null);
    const action = (dbinfos?dbinfos.action[methanolmode] :'sync');
    const options= (dbinfos?dbinfos.options:{});
    const args   = (action==='sync'?[infosdbs[bddentry][typedb],options]:[options]);

    return new Promise ((resolve, reject) => {
        if (!dbinfos)
        {
            const error = {typedb, bddentry, methanolsyncinfos};
            console.error ("F_VSYNCBDD", error);
            return reject (error);
        } else {
            try {
                _tDBchanges[bddentry][typedb] = infosdbs[bddentry][defoltdb][action](...args);
                addhooksonevents (_tDBchanges[bddentry][typedb], bddentry);
                return resolve ();
            }
            catch (error) {
                const error2 = {bddentry, typedb, error};
                console.error ("f_vSyncBdd", error2);
                return reject (error2);
            }
        }
    });
}

// Infinite loop to listen from selector
// Exemple d'appel:
// const selector = { type: 'notification' };
// ListenFromSelector ({selector})
//    .on('change'  , (info) => onChange   (info))
//    .on('complete', (info) => onComplete (info))
//    .on('error'   , (error ) => onError    (error ))
//    );
//
// UPDATE 20210315 JFB: le feed onchange avec changes+selector ne fonctionne pas sur couchdb.
//
//export const ListenFromSelector = ({bddentry=getdefaultbddentry(), selector, onchange, oncomplete, onerror, title}) => {
//    const options  = {...defliveoptions, selector};
//    const ptrcancel= infosdbs[bddentry][defoltdb].changes(options)
//        .on('change'  , (info) => onchange   (info)       )
//        .on('complete', (info) => oncomplete (info, title))
//        .on('error'   , (info) => onerror    (info, title));
//    return (ptrcancel);
//}

// Une seule des 2 options doc_ids ou filter doit être activée
const donothing = () => {}
export const ListenFromIdsOrFilter = (liofprops) => {
    const {bddentry=getdefaultbddentry(), doc_ids=null, filter=null, onchange=donothing, oncomplete=donothing, onerror=donothing, title} = liofprops;
    const options  = (doc_ids?{...defliveoptions, doc_ids}:filter?{...defliveoptions, filter}:{...defliveoptions});
    const mychange = (info) => {
//console.log ("BDD CHANGE DETECTED", info);
        onchange(info);
    };
    const ptrcancel= infosdbs[bddentry][defoltdb].changes(options)
        .on('change'  , (info) => mychange   (info)       )
        //console.error ("LISTENFROMIDSORFILTER complete",bddentry, options, info);
        .on('complete', (info) => oncomplete (info)       )
        .on('error'   , (info) => onerror    (info, title))
        ;
//console.log ("LISTEN", bddentry, defoltdb, options, ptrcancel);
    return (ptrcancel);
}

export const justGetFromSelector = ({bddentry=getdefaultbddentry(), selector}) => {
    return Promise.resolve()
        .then (() => {
            if (!(bddentry && selector)) {
                throw new Error (
                    "no bddentry or selector",
                    {bddentry, selector}
                );
            }
            return infosdbs[bddentry][defoltdb].find({selector})
        })
        .then (res   => res)
        .catch(error => { throw (error); })
}

/* ----------------------------------------------------------------------
 * Gestion des subscribeToBddChanges et unsubscribeToBddChanges et
 * de l'arbre associé.
 * ---------------------------------------------------------------------- */

// Parcourrir l'arbre des abonnés et déclencher le callback si le chemin
// dans l'arbre correspond à une ou plusieurs combinaisons possibles
const f_CallbacksAbonnesById = (doc) => {
    const _id = ('_id' in doc && doc._id?doc._id:null);
    if (_id && _id in _tAbonnes && '__callback__' in _tAbonnes[_id]) {
        const pCallbacks = _tAbonnes[_id].__callback__;
        Object.keys(pCallbacks).forEach (k => {
            const pf = pCallbacks[k];
            if (typeof pf  === "function") {
                pCallbacks[k](doc); }
            else {
                throw ({
                    text: "callback is not a function",
                    k, pf, doc, _tAbonnes
                });
            }
        });
    }
    //    let mysearchkeys = Object.keys(doc).sort();
    //    let pAbonnes     = _tAbonnes;
    //    while (mysearchkeys.length>0) {
    //	const ms0    = mysearchkeys[0];
    //        if (ms0 in pAbonnes && doc[ms0] in pAbonnes[ms0]) {
    //	    pAbonnes = pAbonnes[ms0][doc[ms0]]; // Egalité sur 1 paramètre, On avance de 2 niveaux dans l'arbre
    //	}
    //	mysearchkeys.splice (0,1); // Retirer le 1er élément
    //	// Si pAbonnes point sur une structure contenant __callback__, appeler tous les callbacks à disposition
    //	if ('__callback__' in pAbonnes) {
    //	    const pCallbacks = pAbonnes.__callback__;
    //	    Object.keys(pCallbacks).forEach (k => { pCallbacks[k](doc); });
    //	}
    //    }
}

const addlastidfromtype = (bddentry, type, _id) => {
    const pinfosdb   = (bddentry && bddentry in infosdbs         && infosdbs[bddentry]     ?infosdbs[bddentry]     :null);
    if (pinfosdb && (!pinfosdb['lastidfromtype'])) {
        pinfosdb['lastidfromtype'] = {};
    }
    const plastid    = (pinfosdb?pinfosdb.lastidfromtype:null);
    //const plstfromtp = (plastid  && type && type in plastid      && plastid[type]          ?plastid[type]          :null);
    if (plastid && type)
    { plastid[type] = _id; }
    else
    {
        throw ({
            text: "IMPOSSIBLE TO WRITE LAST ID FROM TYPE",
            bddentry, type, _id, infosdbs
        });
    }
}

const f_vDispatchChangeById = (bddentry, loc, change) => {
    try {
        if (loc === 'memory' && 'doc' in change) {
            const doc = change.doc;
            // Mettre à jour le _id du "last" type modifié
            if ('type' in doc) {
                addlastidfromtype (bddentry, doc.type, doc._id);
            }
            // handle doc
            // change: (1) […]
            //    0: Object { rev: "8-66440136e12763b2c78cc63669380124" }
            // _id: "f9ae8f69-e0a5-426e-a068-e901b958e184"
            // seq: 375
            // appstore.dispatch ("CHANGE_DB", action: {type: doc.type, seq: change[0].seq}).
            // Ajouter des méta-informations methanol
            const mdoc = addfuel (bddentry, doc);
            f_CallbacksAbonnesById (mdoc);
        }
    }
    catch (error) {
        throw ({error, bddentry, change, _tAbonnes});
    }
}

// Description de la gestion de l'arbre des abonnements aux "change" de la Bdd
// (primitives subscribeToBddChanges et unsubscribeToBddChanges).
//
// Règles:
// - Niveaux pairs   (0, 2, 4, ...) les noms des champs à filtrer,
// - Niveaux impairs (1, 3, 5, ...) les valeurs attendues.
//
// Insertion:
//   - Dans l'ordre alphabétique des clefs du "selector" passé en paramètre
//   - Ajouter le callback dans "__callback__" après avoir traversé l'arbre et éventuellement ajouté les branches.
//

const f_vInsererCallbackById = (props) => {
    const {pAbonnes, _id_abo, myuuid, callback, level=0} = props;
    // Normalement le code ne peut pas boucler, sait-on jamais ???
    if (level>20) { console.error ("INFINITE LOOP F_VINSERERCALLBACK",pAbonnes,_id_abo,myuuid); return; }
    // Race condition: désabonné avant de finir l'abonnement
    if (!(myuuid in _tDesabonnements)) { return; }

    if (!(_id_abo in pAbonnes)) {pAbonnes[_id_abo]={};}
    const pFeuille = pAbonnes[_id_abo];
    if (!('__callback__' in pFeuille)) { pFeuille['__callback__'] = {}; }
    pFeuille['__callback__'][myuuid] = callback;
    if (!(myuuid in _tDesabonnements)) {
        delete pFeuille['__callback__'][myuuid];
    }
    else {
        _tDesabonnements[myuuid].target = pFeuille['__callback__'];
    }
}

// Suppression:
//   - si __callback__ vide et pas de clef, supprimer le noeud,
//   - Refaire le test pour le noeud du dessus,
//   - Arrêter lorsque __callback__ non vide OU des clefs dans le hash du noeud.
// 
const f_vSupprimerCallback = (myuuid) => {
    if (myuuid in _tDesabonnements) {
        const pInfos = _tDesabonnements[myuuid];
        // Supprimer le callback
        if ('target' in pInfos && myuuid in pInfos['target'])
        { delete pInfos['target'][myuuid]; }
        if ('cancel' in pInfos && pInfos.cancel && 'cancel' in pInfos.cancel)
        {
//mymetalog ("F_VSUPPRIMERCALLBACK", myuuid);
            pInfos.cancel.cancel();
        }
        // Supprimer la référence à l'abonné
        delete _tDesabonnements[myuuid];
    } else {
//        console.error (
//            "uuid not in subscribers",
//            {myuuid, _tDesabonnements}
//        );
    }
}

/*
 * Lorsqu'on s'abonne avec subscribeToBddChanges aux changements de la base de données,
 * on fournit en paramètres first et callback. first prend une liste de docs en paramètre
 * alors que callback ne prend qu'un seul doc.
 * Il faut ensuite gérer ce doc dans la liste des docs pour faire un refresh des objets
 * en fonction de la nature de l'évènement qui nous arrive de la base de données (ajout,
 * modification ou suppression de l'enregistrement dans la liste).
 */
export const FromDocToDocs = ({doc, docs}) => {
    let   ret      = docs;
    const localCopy= [...docs];             // Faire une copie de la liste pour pouvoir la modifier
    try {
        const tIds = localCopy.map (k => k._id); // Extraire la liste des _id
        const iPos = tIds.indexOf (doc['_id']);  // Regarder si le doc était déja dans la liste
        const bdel = ("_deleted" in doc && doc._deleted);
        if (iPos > -1) {
            if (bdel)  { localCopy.splice (iPos, 1); } // doc supprimé? Le supprimer
            else       { localCopy[iPos] = doc;      } // Mettre à jour le doc
        }
        else if (!bdel){ localCopy.push   (doc);     } // Nouveau doc? Le rajouter
        ret = localCopy;
    }
    catch (error) {
//console.log ("FromDocToDocs", error2)
        throw ({error, doc, docs});
    }
    //console.log ("FromDocToDocs", ('denomination' in doc?doc.denomination:doc), docs, ret);
    return (ret);
}

// Parcours:
//   De manière récursive:
//   Pour chacun des champs C du noeud
//       Si C dans les attributs du doc et doc[C] dans noeud[C]
//           Si des __callback__ les appeler
//           Reprendre de manière récursive pour chacun de noeud de C.
//   A noter: C est de niveau parir, sa valeur V de niveau impair.
//
// TBC dans l'exemple ci-dessous, le noeud etude devrait être supprimé.
// 
// {
//    _id : {
//        "05562e92-ca24-4236-9845-7fa002844f52": {
//            __callback__: {id1:callback1, id2:callback2, ...}
//        }
//    }
//    type: {
//        participant: {
//            referentiel_item: {
//                "01603052-0e57-4f72-b253-e7158bd4fdf3": {
//                }
//            }
//            etude: {
//                "ea85ac25-e81f-4397-9245-304cd14444cd": {
//                    __callback__: {id3:callback3, id4:callback4, ...}
//                }
//            }
//        }
//        // ... childs exemples
//        etude: {
//            "ea85ac25-e81f-4397-9245-304cd14444cd": {
//                __callback__: {}
//            }
//        }
//    },
//    etude: {
//        "ea85ac25-e81f-4397-9245-304cd14444cd"
// }
// 

const ptrcancelchangements = (pDB, cbk, bdd, sel, myuuid) => {
//const mymetalog = console.log;
//mymetalog ("MYCHANGES ON...", cbk);
    const callback  = cbk;
    const bddentry  = bdd;
    const selector  = sel;
    const options   = {...defliveoptions, selector};
//mymetalog ("MYCHANGES PTRCANCELCHANGEMENTS",selector, options, myuuid);
    return pDB.changes (options)
        .on('change'  , res  => {
//mymetalog ("MYCHANGES change",res,selector,callback);
            callback (addfuel(bddentry, res.doc));
        })
        .on('complete', (/*info*/) => {
//mymetalog ("MYCHANGES complete", options, callback, bddentry, selector, info);
            // Rien à faire ici, évènement souhaité
            // cancel fait lors du désabonnemement (unsubscribeToBddChanges)
            //console.error ("MYCANCEL",info,myselector);
            if (myuuid && myuuid in _tDesabonnements && 'cancel' in _tDesabonnements[myuuid] && _tDesabonnements[myuuid].cancel && 'cancel' in _tDesabonnements[myuuid].cancel) {
                _tDesabonnements[myuuid].cancel.cancel();
            }
        })
        .on('error'   , (error) => {
//mymetalog ("MYCHANGES ERROR", error);
            //console.error ("ptrcancelchangements CHANGES", options, callback, bddentry, selector, error);
            RenderMetaToast("Live", "ERROR changes "+bddentry +" "+JSON.stringify(selector)+" "+JSON.stringify(error), "danger");
        })
}

const changesById = (byidprops) => {
    const {selector, myuuid, bddentry, nofirst, first, callback} = byidprops;
    const _id_abo  = ('_id' in selector?selector._id:null);
    const pAbonnes = _tAbonnes;
    const pDB      = infosdbs[bddentry][defoltdb];
    const _fInsertAndExecCallback = (doc) => {
        const mdoc = addfuel (bddentry, doc);
        f_vInsererCallbackById ({pAbonnes, _id_abo, selector, myuuid, callback});
        if (first) { first   (mdoc); }
        else       { callback(mdoc); }
    }
    return new Promise ((resolve, reject) => {
        if (!_id_abo) {
            const error = {error: "no _id", _id_abo, selector};
            return reject (error);
        }
        // Lire et callback pour la 1ère fois (sauf si nofirst)
        if (nofirst) {
            f_vInsererCallbackById ({pAbonnes, _id_abo, selector, myuuid, callback});
            return resolve (myuuid);
        }
        else if (_id_abo) {
            //console.log ("GET", _id_abo, pDB, infosdbs);
            if (!pDB) {
                const error = {message: "No DB", selector, myuuid, bddentry};
                return reject (error);
            }
            pDB.get(_id_abo)
                .then (doc => {
                    //console.log ("GET OK", doc);
                    _fInsertAndExecCallback(doc);
                    return resolve (myuuid);
                })
                .catch(error => {
                    //console.log ("GET ERROR", error);
                    if (error && "error" in error && error.error === "not_found") {
                        // Le document reste à créer dans un formulaire ??
                        return reject (error);
                    } else {
                        const error2 = {message: "1st read error", _id_abo, selector, error};
                        delete _tDesabonnements[myuuid];
                        return reject  (error2);
                    }
                });
        } else {
            const error = {error: "no _id", _id_abo, selector};
            delete _tDesabonnements[myuuid];
            return reject (error);
        }
    })
}

const changesBySelector = (byselectorprops) => {
    const {selector, selectorsort, iindex, myuuid, bddentry, /*nofirst,*/ first, fields, callback} = byselectorprops;
//mymetalog ("BDD ______", infosdbs)
    const myselector = {
        selector,
        ...(fields      ?{fields                           }:null),
        ...(selectorsort?{sort     : selectorsort          }:null),
        ...(iindex      ?{use_index: indexnamefromi(iindex)}:null),
    };
    const callbackonebyone = (docs) => {docs && docs.forEach (doc => callback(doc))}
    const pDB              = infosdbs[bddentry][defoltdb];
//mymetalog ("BDD 000000", myselector, infosdbs, pDB);
    return Promise.resolve()
        .then (() => {
            return pDB.find(myselector)
        })
        .then(res=>{
            //mymetalog ("1st RES",res,myselector);
            //mymetalog ("BDD 111111", infosdbs);
            let pclbk = (first?first:callbackonebyone);
            //mymetalog ("BDD 222222", infosdbs);
            (res && 'docs' in res && res.docs) && pclbk (addfuel(bddentry, res.docs));
            //mymetalog ("BDD 333333", infosdbs);
            _tDesabonnements[myuuid].cancel = ptrcancelchangements (pDB, callback, bddentry, selector, myuuid);
            //mymetalog ("BDD 444444", infosdbs);
            return (myuuid);
        })
        .catch(error => {
            //mymetalog ("1st ERROR", error);
            console.error ("FIND ERROR", bddentry, selector, myselector, error, pDB)
            delete _tDesabonnements[myuuid];
            const adapter = (pDB && 'adapter' in pDB?pDB.adapter:null);
            onSyncError (bddentry, adapter, error)
            throw new Error (error);
        });
}

const noneChanges = (noneprops) => {
    const {abo, selector, myuuid, bddentry} = noneprops;
    return Promise.resolve()
        .then (() => {
            f_vSupprimerCallback (myuuid);
            const error = {error: "noneChanges", abo, selector, bddentry, _tAbonnes, _tDesabonnements};
            //throw new Error("Erreur applicative subscribeToBddChanges");
            delete _tDesabonnements[myuuid];
            throw new Error (error);
        })
}

// -----------------------------------------------------------------------------
// abo = _id      => selector = { _id : docid }
// abo = selector => selector = { type: 'etude', name: 'id_applicatif', etude: "if572ae8b-9612-4072-a982-62d6b9e927ec" }
// callback pour abonnement par _id      = (doc ) => { faire quelque chose avec le  doc  }
// callback pour abonnement par selector = (docs) => { faire quelque chose avec les docs }
// La fonction retourne un id_applicatif qu'il faut produire pour se désabonner.
const tChangesBy = {
    '_id':      changesById,
    'selector': changesBySelector,
    //'query':NON COUVERT POUR LE MOMENT
    'none':     noneChanges
}
export const subscribeToBddChanges  = (props) => {
    return Promise.resolve()
    .then (() => {
        const {bddentry=getdefaultbddentry(), abo, selector, fields=null, callback, nofirst=false, first=null, iindex=null, selectorsort=null} = props;
        if (appstore.getState().wecanstart === false) {
            throw new Error (
                "subscribeToBddChanges not allowed (db not started)",
                {abo, selector}
            );
        }

        if (!(bddentry && bddentry in infosdbs)) {
            throw new Error (
                "subscribeToBddChanges impossible (bddentry)",
                {bddentry, infosdbs, selector}
            );
        }

        let   myuuid  = uuid();
        if (myuuid in _tDesabonnements) {
            throw new Error (
                "uuid déja présent - collison",
                {myuuid, _tDesabonnements}
            );
        }

        _tDesabonnements[myuuid] = {selector};
        const toprops = {abo, selector, myuuid, bddentry, nofirst, first, callback, fields, selectorsort, iindex};
        const entry   = (abo && abo in tChangesBy?abo:'none');
        return tChangesBy[entry](toprops)
    })
    // then let it flow
}

// -----------------------------------------------------------------------------
// uuidAbonnement = unique id retourné par la fonction subscribeToBddChanges
export const unsubscribeToBddChanges = ({uuidAbonnement}) => {
    f_vSupprimerCallback (uuidAbonnement);
}

/**
 * @classdesc Eléménts de CRUD, exporte de base un read, save, delete
 * @class
 */
export class CRUDcompo {
    constructor(props) {
        // Recopier toutes les propriétés de props dans les propriétés de l'objet
        // Doit inclure à minima 'type'.
        // type "user", "participant", "atelier", ...
        Object.keys(props).forEach(k => {
            let j=k;
            if (!(j in this))
            { this[j] = props[k]; }
            else
            { console.error (j+' allready in this'); }
        });
        // Base de données à utiliser ou base par défaut
        const bddentry   = getdefaultbddentry(props);
        if (!(this && 'type' in this)) {
            const msg = "CRUDcompo type missing";
            console.error (msg, {props});
            throw new Error (msg);
        }
        if (!bddentry) {
            const msg = "No bddentry";
            console.error (msg, {props});
            throw new Error (msg);
        }
        this.bddentry    = bddentry;
        if (!(bddentry in infosdbs && defoltdb in infosdbs[bddentry]))
        {
            const msg = "CRUDcompo database entry missing";
            console.error (msg, { bddentry, defoltdb, props, infosdbs });
            throw new Error (msg);
        }
        this.hooks       = ('__workflow__' in props && 'hooks' in props.__workflow__?props.__workflow__.hooks:null);
        this.beforedelete= (this.hooks && 'beforedelete' in this.hooks && this.hooks.beforedelete?this.hooks.beforedelete:null);
        this.pDB         = infosdbs[bddentry][defoltdb];
    }

    // # fonction privée à la classe
//    _f_vChargerEnreg (doc) {
//        const {_id} = doc;
//console.log ("THIS PDB", this.pDB);
//        return this.pDB.get(_id);
//    }

    _inituuid (doc) {
        return ('inituuid' in this && this.inituuid?this.inituuid (doc):uuid());
    }

    // # fonction privée à la classe
    _f_vPreparerEnreg ({doc,beforeclbk=null,tracktype=null,tagtype=null}) {
        let doc2enreg = {...doc};
        // -----------------------------------------------------------------------------
        // doc est réputé contenir un guuid nommé _id si enregistrement existant
        // S'il s'agit d'un nouvel enregistrement, commencer par appeler le inituuid
        if (!('_id' in doc2enreg && doc2enreg['_id'])) {
            doc2enreg._id = this._inituuid(doc);
        }
        // -----------------------------------------------------------------------------
        // Flagger le type de l'objet automatiquement
        // TBC JFBJFB à integrer dans une base de données d'erreurs applicatives
        if (('type' in doc2enreg) && (doc2enreg.type !== this.type))
        {
            throw new Error (
                "INCOHERENCE TYPE",
                {type: this.type, _id: doc2enreg['_id']}
            );
        }
        else
        { doc2enreg['type'] = this.type; }
        // -----------------------------------------------------------------------------
        // Calculs à réaliser sur l'enregistrement pour le type visé
        if (this.type in appmodels)
        {
            const modeltype  = (tracktype?tracktype:this.type);
            const type2model = (modeltype && modeltype in appmodels?modeltype:null);
            if (type2model) { doc2enreg = appmodels[type2model]({rowdata:doc2enreg}); }
        }
        // -----------------------------------------------------------------------------
        // Si tagtype, tagger l'enregistrement avec ce tagtype
        if (tagtype)
        { doc2enreg['tagtype'] = tagtype; }
        // -----------------------------------------------------------------------------
        // Si action à effectuer avant l'enregistrement, l'effectuer ici
        if (beforeclbk) {
            doc2enreg = beforeclbk(doc2enreg);
        }
        // -----------------------------------------------------------------------------
        // TBC séquence de code à compléter pour gérer un éventuel asynchronisme sur la Bdd Pouch/Couch et être capable de résoudre des conflits
        // _id et champs de formulaire (dont type="user" ou "participant" ou ...)
        const withoutfuel = delfuel(doc2enreg);
        //const myret       = this.pDB.put(withoutfuel);
        return (withoutfuel);
    }

    // fonction privée à la classe
    // doc contient à minima _id
    _f_vDetruireEnreg (props) {
        const {_id} = props;
        const that  = this;
        //const myTid= (withtoast?RenderMetaToast("Suppression","Suppression document "+_id,"info"):null);
        return Promise.resolve()
        .then (() => {
            //this._f_vChargerEnreg ({_id})
            return that.pDB.get (_id)
        })
        .then (doc => {
            doc._deleted  = true;
            const prepdoc = that._f_vPreparerEnreg({doc});
            return (that.pDB.put(prepdoc));
        })
    }

    // Supprime l'attachement.
    // Retourne:
    // - { ok: true, id: "acad9c44-5a62-4267-8c82-f190ce7f5c4f", rev: "4-02eacc739229b3b1e53f8c568c6b3ac5" }
    // - { error: true, status: 409, name: "conflict", message: "Document update conflict", stack: "" }
    _f_vDetruireAttachment (doc) {
        const that = this;
        return Promise.resolve()
        .then (() => {
            const be  = ('__methanol__' in doc && 'bddentry' in doc.__methanol__ && doc.__methanol__.bddentry?doc.__methanol__.bddentry:that['bddentry']);
            const mbd = (be?infosdbs[be][defoltdb]:that.pDB);
            return mbd.removeAttachment(doc['_id'], doc['filename'], doc['_rev'])
        })
        .then  (res => {
            //console.log ("RES", res);
            //RenderMetaToast("Suppression de fichier","Fichier supprimé","success");
            return (res)
        })
        .catch (error => {
            //RenderMetaToast("Suppression de fichier","Erreur lors de la suppression","danger");
            throw (error)
        })
    }

    // -----------------------------------------------------------------------------
    // Update d'un component ("user", "participant", "atelier", ...)
    save(props) {
//mymetalog ("MYCRUD SAVE", props);
        const {doc, beforeclbk=null, tracktype=null, tagtype=null} = props;
        const that = this;
        return Promise.resolve()
        .then (() => {
            if (!doc) {
                throw new Error ('no doc');
            }
//mymetalog ("MYCRUD SAVE 1", props);
            if (!('_id'  in doc)) { doc._id =that._inituuid(doc); }
            if (!('type' in doc)) { doc.type=that.type; }
            //const myTid = RenderMetaToast("Enregistrement","Enregistrement document " + (tracktype?tracktype:that.type),"info");
            //beforeclbk && beforeclbk(doc);
            const prepdoc = that._f_vPreparerEnreg ({doc,beforeclbk,tracktype,tagtype});
            const datedoc = injectdate(prepdoc);
//mymetalog ("MYCRUD SAVE", that, that.pDB, datedoc);
            return (that.pDB.put(datedoc));
        })
        ;
        // Then let it flow
    }

    // -----------------------------------------------------------------------------
    // Lecture d'un component depuis son _id ("user", "participant", "atelier", ...)
    read  (props) {
        const that       = this;
        const {_id=null} = props;
        return Promise.resolve()
        .then (() => {
            //const myTid = RenderMetaToast("Lecture","Lecture document " + this.type,"info")
            //this._f_vChargerEnreg ({_id})
//mymetalog ("JFBJFB READ 1", _id);
            return that.pDB.get (_id)
        })
        .then  (doc => {
//mymetalog ("JFBJFB READ 2 OK", doc);
            const mdoc = addfuel (that.bddentry, doc);
            //RenderMetaToast("Lecture","Lecture du document " + that.type,"success",myTid);
            return (mdoc);
        })
        .catch (error => {
//mymetalog ("JFBJFB 3 READ ERR", error);
            //RenderMetaToast("Lecture","Erreur lors de la lecture du document " + that.type+" ."+error,"danger",myTid);
            //console.error ("ERROR: lecture du document", that['type'], error, that);
            throw (error);
        });
    }

    // -----------------------------------------------------------------------------
    // Destruction d'un component ("user", "participant", "atelier", ...)
    // doc contient en particulier un _id unique
    // Dans le cas d'un attachement, on retrouve les champs digest, content_type, revpos, filename
    delete(props) {
        const that = this;
        return Promise.resolve()
        .then (() => {
            //console.log ("DELETE", props, this);
            // TBC séquence de code à compléter pour gérer un éventuel asynchronisme sur la Bdd Pouch/Couch et être capable de résoudre des conflits
            if ('filename' in props && 'content_type' in props && 'digest' in props && "revpos" in props)
            {
                return that._f_vDetruireAttachment (props);
            }
            else
            {
                if (that.beforedelete)
                {
                    that.beforedelete({doc:props})
                    .then  (res   => that._f_vDetruireEnreg(res))
                    .catch (error => { throw (error);         } )
                    ;
                }
                else
                {
                    return that._f_vDetruireEnreg (props);
                }
            }
        });
    }
}

/** 
 * Mise à jour d'un formulaire après récupération d'un update en Bdd
 */
export const F_vRechargerFormFields = (/*{targetformname, hFieldsAndValues}*/) => {
    throw new Error ("Fonction obsolète, utiliser initialize du redux des formulaires (passé en props aux composants)");
    // Exemple d'utilisation de initialize
    // A noter: on peut également utiliser les fonctions exportées par redux-form initialize et change.
    // Cf. Modales.jsx pour les use case de change, initialize et reset.
    // appstore.getState().form[targetformname].initialize (hFieldsAndValues);
    // Dans la documentation redux-form, INITIALIZE ou CHANGE
    // import {CHANGE}                     from 'redux-form/lib/actionTypes'
    // ...
    // const myUpdateFormFields = (formname, values) => {
    //     //const type      = INITIALIZE;
    //     //const keepDirty = true;
    //     //const action = { type, meta: {formname, keepDirty}, payload };
    //     //store.dispatch (action);
    //     const type      = CHANGE;
    //     Object.keys(values).forEach(k => {
    //         const field   = k;
    //         const payload = values[k];
    //         const action  = {type, meta: {formname, field}, payload};
    //         store.dispatch (action);
    //     })
    // }

//    const formnames  = appstore.getState().form[targetformname].registeredFields;
//    const dictoparse = (formnames?formnames:hFieldsAndValues);
//console.log ("CHANGE FORMNAMES", appstore.getState().form);
//console.log ("CHANGE FORMNAMES", appstore.getState().form, formnames, Object.keys(hFieldsAndValues));
//    if (dictoparse) {
//        Object.keys(dictoparse).forEach(k => {
//console.log ("CHANGE BEFORE", k);
//            if (k in hFieldsAndValues) {
//console.log ("CHANGE", targetformname, k, hFieldsAndValues[k]);
//                appstore.dispatch (change (targetformname, k, hFieldsAndValues[k]));
//            }
//        });
//    }
//    else { console.error ("F_VRECHARGERFORMFIELDS", targetformname, hFieldsAndValues, appstore.getState().form[targetformname]); }
    // Enfin, informer les différents éléments du formulaire édité de la valeur
    // du doc ce qui permet de:
    // - Rendre visible, invisible, actif, inactif, ... des éléments du formulaire
    //   en fonction de conditions spécifiques (workflow),
    // - Afficher les éventuels boutons d'action spécifiques au document...
//    appstore.dispatch({ type: "DOCLIVEFROMFORM", dyndoc: hFieldsAndValues });
}

// Permet de s'abonner à tous les changements sur l'_id
// Usage: doc=useDocFromId({_id})
// beforecleanup = fonction à appeler au moment de la destruction de l'objet
// En cas d'erreur (en particulier si nouvel élément, n'existe pas en base),
// on retourne le code d'erreur.
export const useDocFromId = (props) => {
    const {bddentry=getdefaultbddentry(),_id=uuid(),nofirst=null,defaultinit=null,beforecleanup=null} = props;
    const [doc,            setDoc           ] = React.useState(defaultinit);
    const [uuidAbonnement, setUuidAbonnement] = React.useState(null);
    const callback      = React.useCallback ((doc) => {
        setDoc(doc);
    })
    React.useEffect (() => {
        const abo      = '_id';
        const selector = {_id};
        Promise.resolve()
            .then (() => {
                if (!_id) {
                    const error = {message: "missing", _id};
                    //console.error ({error});
                    throw new Error (error);
                }
                else
                { return subscribeToBddChanges ({ bddentry, abo, selector, callback, nofirst }) }
            })
            .then  (uuida => {
                setUuidAbonnement(uuida);
            })
            .catch (() => {
                setDoc(defaultinit);
            })
        return function cleanup() {
            if (uuidAbonnement) { unsubscribeToBddChanges ({uuidAbonnement})}
            if (beforecleanup)  { beforecleanup (); }
        }
    //},[_id,bddentry,nofirst,beforecleanup]);
    },[]);
    return (doc);
}

// Permet de s'abonner à tous les changements sur le type
// Usage: doc=useDocsFromSelector({selector:{type}})
//export const useDocsFromType = ({bddentry=getdefaultbddentry(),type=null,nofirst=null,defaultinit=null}) => {
export const useDocsFromSelector = (udfsprops) => {
    const {defaultinit=null} = udfsprops;
//console.error ("useDocsFromSelector", udfsprops);
    const [docs,           setDocs          ] = React.useState(defaultinit);
    const [uuidAbonnement, setUuidAbonnement] = React.useState(null);
//debugger;
    const callback = React.useCallback((doc) => { setDocs(docs => {
        try {
            return FromDocToDocs ({doc, docs});
        }
        catch (error) {
//console.error ("useDocsFromSelector ERROR", doc, docs);
            return (defaultinit);
        }
    })})
    const first    = React.useCallback ((docs) => {
        setDocs(docs);
    })
    const abo      = 'selector';
//debugger;
    React.useEffect (() => {
        subscribeToBddChanges ({abo, callback, first, ...udfsprops})
            .then  (uuida => {
//console.log ("useDocsFromSelector uuida", uuida);
                setUuidAbonnement(uuida);
            })
            .catch (error => {
                console.error ({abo, callback, first, error, udfsprops});
                setDocs(defaultinit);
            })
        return function cleanup() {
//console.error ("CLEANUP", uuidAbonnement);
            if (uuidAbonnement) {
                unsubscribeToBddChanges ({uuidAbonnement});
            }
        }
    //},[defaultinit,udfsprops]);
    },[]);
    return (docs);
}

export const justFindDocsFromSelector    = ({bddentry=getdefaultbddentry(),selector=null/*,defaultinit=null*/}) => {
    let myret = null;
    if (selector && bddentry in infosdbs && defoltdb in infosdbs[bddentry]) {
        myret = infosdbs[bddentry][defoltdb].find({selector: selector})
    }
    return (myret);
}

//export const useJustFindDocsFromSelector = (props) => {
//    const {bddentry=getdefaultbddentry(),selector=null,defaultinit=null} = props;
//    const [docs, setDocs] = React.useState (defaultinit);
//    React.useEffect (() => {
//        const jfdfs = justFindDocsFromSelector (props);
//        if (jfdfs) {
//            jfdfs
//                .then  ((res) => {
//                    setDocs ('docs' in res?addfuel(bddentry, res.docs):defaultinit);
//                })
//                .catch ((error) => { console.error (error); setDocs (defaultinit); });
//        }
//    },[bddentry]);
//    // Rajouter selector dans le dépendances du useEffect provoque des boucles infinies (infinite loops)
//    return (docs);
//}

