import {BDD_CONSTS}         from './Bdd'          ; import {bddInitState}       from './Bdd'          ; import {bddReducers}        from './Bdd';
import {GRAFIK_CONSTS}      from './GrafikAtelier'; import {grafikInitState}    from './GrafikAtelier'; import {grafikReducers}     from './GrafikAtelier';
import {LOGGED_CONSTS}      from './Login'        ; import {loggedInitState}    from './Login'        ; import {loggedReducers}     from './Login';
import {METATOASTS_CONSTS}  from './MetaToast'    ; import {metatoastInitState} from './MetaToast'    ; import {metatoastReducers}  from './MetaToast';
import {MODAL_CONSTS}       from './Modal'        ; import {modalInitState}     from './Modal'        ; import {modalReducers}      from './Modal';
import {FORMSTATE_CONSTS}   from './Formstate'    ; import {formpropsInitState} from './Formstate'    ; import {formstateReducers}  from './Formstate';

export const METHANOL_CONSTS = {
    ...BDD_CONSTS,
    ...GRAFIK_CONSTS,
    ...LOGGED_CONSTS,
    ...MODAL_CONSTS,
    ...METATOASTS_CONSTS,
    ...FORMSTATE_CONSTS,
}

export const methanolInitState = {
    ...bddInitState,
    ...grafikInitState,
    ...loggedInitState,
    ...modalInitState,
    ...metatoastInitState,
    ...formpropsInitState,
}

export const methanolReducers = {
    ...bddReducers,
    ...grafikReducers,
    ...loggedReducers,
    ...modalReducers,
    ...metatoastReducers,
    ...formstateReducers,
}

