import React                        from 'react';
//import {Field}                      from 'redux-form';
//import {formValueSelector}          from 'redux-form'
//import {Button}                     from 'react-bootstrap';
//import Button                       from '@material-ui/core/Button';
import countryList                  from 'country-list';

//import {store}                      from '../Store';
//import {mysha256sumFromBuffer}      from '../Communs';
//import {updateFormFields}           from '../methanol/Modal';
import {CellAction}                 from '../methanol/Datatable';
//import {RenderMetaToast}            from '../methanol/MetaToast';
import {MetaDoc}                    from '../methanol/MetaDoc';
import {ListFields}                 from '../methanol/MetaDoc';
import {RenderSelectField}          from '../methanol/MetaDoc';
import {myFabrik}                   from '../methanol/LaFabrik';
import {getdefaultbddentry}         from '../methanol/Bdd';
import {injection_props_profil}     from '../model/ProfilsVerif';
import {required}                   from './noRender/validate';
import {identitycars}               from './noRender/validate';
import {alphaNumeric}               from './noRender/validate';
import {email}                      from './noRender/validate';
import {phoneNumber}                from './noRender/validate';

//const dataGenre = [
//    {text: "Homme", value: "M"},
//    {text: "Femme", value: "F"},
//];
//export const MonGenre = (props) => {
//    return (
//        <RenderSelectField {...props} >
//            <option value="" />
//            {dataGenre.map ((o,i) => <option key={i} value={o.value}>{o.text}</option>)}
//        </RenderSelectField>
//    )
//}

const dataCountries = countryList.getData();
export const MonPays = (props) => {
    return (
        <RenderSelectField {...props} >
            <option value="" />
            {dataCountries.map ((o,i) => <option key={i} value={o.code}>{o.name}</option>)}
        </RenderSelectField>
    )
}

export const tprofilfields = [
    {fieldname: '_id'         , label: 'Identifiant unique'  },
    {fieldname: 'firstname'   , label: 'Hash'                },
    {fieldname: 'lastname'    , label: 'Type de hash utilisé'},
    {fieldname: 'email'       , label: 'Nom'                 },
    {fieldname: 'filesize'    , label: 'Taille (octets)'     },
    {fieldname: 'content_type', label: 'Type de contenu'     },
]

export const EditProfilCompo = {
    formname: 'profil',
    type:     'profil',
    title:    'Profil',
    modal:    'profil',
    //displaytype: 'tabs',
    //displaytype: 'stepper',
    typeedit : 'inmult',
    bddentry: getdefaultbddentry(),
    tCompos: {
        'general': { component: ListFields, intitule: 'Mon profil', others: {
            tFields : {
                //'checkedid.gender'   : { component: MonGenre, intitule: 'Genre',       others: { required: true, validators: [ required               ]                                               } },
                'checkedid.firstname': { component: 'input' , intitule: 'Prénom',      others: { required: true, validators: [ required, identitycars ]                                               } },
                'checkedid.lastname' : { component: 'input' , intitule: 'Nom',         others: { required: true, validators: [ required, identitycars ]                                               } },
                'inscription.email'  : { component: 'input' , intitule: 'Email',       others: { required: true, validators: [ required, email        ]                                               } },
                'phone'              : { component: 'input' , intitule: 'Téléphone',   others: { required: true, validators: [ required, phoneNumber  ], placeholder:"Téléphone (forme +33601234567)" } },
                'pseudo'             : { component: 'input' , intitule: 'Pseudonyme',  others: { required: true, validators: [ required, alphaNumeric ]                                               } },
                //'address.street'     : { component: 'input' , intitule: 'Adresse',     others: { required: true, validators: [ required, alphaNumeric ], placeholder:"Adresse"                        } },
                //'address.complements': { component: 'input' , intitule: 'Complément',  others: {                 validators: [           alphaNumeric ], placeholder:"Adresse (complément)"           } },
                //'address.postalCode' : { component: 'input' , intitule: 'Code postal', others: { required: true, validators: [ required, alphaNumeric ], placeholder:"Code postal (31000)"            } },
                //'address.city'       : { component: 'input' , intitule: 'Ville'      , others: { required: true, validators: [ required, alphaNumeric ], placeholder:"Ville"                          } },
                'address.country'    : { component: MonPays , intitule: 'Pays'       , others: { required: true, validators: [ required               ], placeholder:"Pays"                           } },
            },
            extraprops  : injection_props_profil, // extraprops des fields et tabs
        } }
    },
    __workflow__: {
        //amidisplayed: referentiel_buttondisplayed,
        extraprops  : injection_props_profil, // Gestion enable/disable des boutons
        //tButtons: [
        //    { status: 'draft'     , intitule: <span>Valider {IconFromData({rowdata:{status:'production'},myField:'status',tracktype:'status'})}</span>, triggers: [f_vValiderReferentiel , f_vActiverReferentiel] },
        //    { status: 'production', intitule: <span>Archiver{IconFromData({rowdata:{status:'archive'   },myField:'status',tracktype:'status'})}</span>, triggers: [f_vArchiverReferentiel, ] },
        //    { status: 'archive'   , intitule: <span>Activer {IconFromData({rowdata:{status:'production'},myField:'status',tracktype:'status'})}</span>, triggers: [f_vActiverReferentiel , ] },
        //],
        //hooks: {
            //postupdate  : f_vCreerExigencesCibleFromReferentiel,
            // beforesave injecte également le pdf
            //beforesave: (doc) => { return f_vPreparerProtection (doc); gotoMenu ('lister_oeuvres'); },
            //beforedelete: f_vSupprimerExigencesCible,
            //postsave  : (doc) => { return f_vCreerNotifAction     (doc); },
            //postupdate
            //onrowdisplay: (doc) => {
            //if (doc && 'status' in doc && doc.status==='draft'      ) { showModal({modal: "ok_protection_modale", param: {doc}}); }
            //if (doc && 'status' in doc && doc.status==='signstarted') { F_vDemarrerProtection (doc);                              }
            //if (doc && 'status' in doc && doc.status==='otpsent'    ) { showModal({modal: "otp_modale",          param: {doc}}); }
            //}
        //}
    }
}

export const TableauProfils = (props=null) => {
    //const mystatus = r => <TipsFromData  rowdata={r} myField='status' myCompo={IconFromData} tipCompo={CellFromEchelle} />;
    //const myddoc   = r => <FilesFromData rowdata={r} />;
    const myaction = (r,_,modal) => <CellAction    rowdata={r} actions={['edit','trash']} modal={modal} />;
    return (
        <MetaDoc {...myFabrik.getCompo('liste_profils')} tracktype='profil'
            thClass      = 'grey-erasm whiteColor'
            hDescDt      = {{
                "checkedid.firstname"   : { title: "Prénom",                    },
                "checkedid.lastname"    : { title: "Nom",                       },
                "inscription.email"     : { title: "Email",                     },
                "action"                : { title: "Action", contenu: myaction  },
            }}
            {...props}
        />
    );
}

export const DashboardProfils = () => {
    return (
    <div>
        <h3 className="py-3 text-left">Annuaire</h3>
        <TableauProfils />
    </div>
    );
}

