import {setmodels}    from '../methanol/Communs';

// import {hKeysEchelle} from '../methanol/Communs';

// Exemple de modèle dans friskeeez
//const f_ModelPartiePrenante = ({rowdata}) => {
//    // Echelles:
//    // - ms : 'nonconcerne'...'elevee' => 1...4
//    // - f16: 1...16                   => 'nonconcerne'...'eleve'
//    // - fSD: 1/6..16                  => 'nonconcerne'...'eleve'
//    // const ms         = scaleBand    ().domain(hKeysEchelle['pertinence']).range([1,4]);
//    // const f16        = scaleQuantize().domain([1,16]                    ).range(hKeysEchelle['pertinence']);
//    // const fSD        = scaleQuantize().domain([1/16,16]                 ).range(hKeysEchelle['pertinence']);
//
//    const dependance = ('dependance'  in rowdata && rowdata.dependance ?rowdata.dependance :1);
//    const penetration= ('penetration' in rowdata && rowdata.penetration?rowdata.penetration:1);
//    const maturite   = ('maturite'    in rowdata && rowdata.maturite   ?rowdata.maturite   :1);
//    const confiance  = ('confiance'   in rowdata && rowdata.confiance  ?rowdata.confiance  :1);
//    const exposition = dependance * penetration;
//    const fiabilite  = (maturite!==0 && confiance!==0?maturite*confiance:1);
//    const seuildanger= exposition/fiabilite; // fiabilite ne peut pas être nul forcément entre 1 et 16*16
//    const newrowdata = {
//        ...rowdata,
//	dependance, penetration, maturite, confiance, // Mise à jour des valeurs si par défaut
//        exposition, fiabilite, seuildanger,           // Champs calculés
//    };
//    return (newrowdata);
//}

const hModels = {
}

export const F_SetModels = () => { setmodels (hModels); }

