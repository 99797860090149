let hListComposApp=null;

/**
 * @class Classe prête à être instanciée par la fabrik des données venant de tListPropsCompos
 * Cette classe contient plusieurs méthodes:
 * - tListCompos pour avoir la liste complète
 * - tListModals si pas de 'html_type' dans les props
 * - ttf ... ??? (a definir)
 * - prepareDef n'est qu'une méthode privée à la classe qui sera utilisée par la méthode compo:
 * pour retourner une compo en fonction du type envoyé par paramètre.
 * 
 * La fabrique est directement appellé depuis la DT: CellFromData.

 */
class tPropsCompos {
    _majMyLists () {
        this.tListCompos  = Object.keys(hListComposApp);
        this.tListModals  = this.tListCompos.filter(k=>(!('html_type' in hListComposApp[k])));
    }

    constructor () {
        this._majMyLists ();
        this.defaultCompo = {formname: 'tobefilled', type:'tobefilled', title: 'tobefilled', };
        // Les valeurs tobefilled doivent être remplacées au moment de la création de l'objet
    }

    getDefaultData (type, partof=null) {
        const titleUprCase = (type && type.length>0?type[0].toUpperCase()+type.slice(1):'no-title');
        let defData = {
            type     : type,
            formname : type,
            title    : titleUprCase,
        };
        if (partof) { defData['partof'] = partof; }
        return (defData);
    }

    getModalList   ()             { return (this.tListModals);       }

    existsorcreate (type, partof=null) {
        if (!(type in hListComposApp)) {
            const hCompo = this.getDefaultData(type, partof);
            hListComposApp[type] = {...hCompo};
            this._majMyLists ();
        }
    }

    getCompo (type, partof=null) {
        // Soit on a défini le nouveau type, soit on l'injecte dans la table hListComposApp
        this.existsorcreate(type,partof);
        //hCompo = {...hListComposApp[type]};
        const hCompo = hListComposApp[type];
        return (hCompo);
    }

    getExistingCompo (type) {
        const hCompo = (type && type in hListComposApp?hListComposApp[type]:null);
        return (hCompo);
    }
}

export let myFabrik = null;
//console.log ("FABRIK",myFabrik);

// Injecter des composants communs comme yesorno
const addsaltandpepper = (tCompos) => {
    const salt = {
        'yesorno' : {
            formname: 'yesorno',
            type:     'yesorno',
            title:    'Confirmez-vous la suppression ???',
        },
    }

    Object.keys(salt).forEach (pepper => {
        if (pepper in tCompos)
        { console.error ("Salt and Pepper allready on table...", pepper, salt, tCompos); }
        else
        { tCompos[pepper] = salt[pepper]; }
    });
}

export const F_vInjectFabrik = (hListCompos) => {
    hListComposApp= hListCompos;
    addsaltandpepper (hListComposApp);
    myFabrik      = new tPropsCompos();
}

