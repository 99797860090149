import React                        from 'react';
//import {Field}                      from 'react-final-form';
//import {formValueSelector}          from 'redux-form'
//import {Button}                     from 'react-bootstrap';
//import Button                       from '@material-ui/core/Button';

//import {store}                      from '../Store';
import {mysha256sumFromBuffer}      from '../Communs';
//import {updateFormFields}           from '../methanol/Modal';
//import {CellAction}                 from '../methanol/Datatable';
import {RenderMetaToast}            from '../methanol/MetaToast';
//import {MetaDoc}                    from '../methanol/MetaDoc';
import {myFabrik}                   from '../methanol/LaFabrik';
import {Datatable}                  from '../methanol/Datatable';
//import {RenderField}                from '../Communs';

const Promise    = require('lie');

export const tfilefields = [
    {fieldname: '_id'         , label: 'Identifiant unique'  },
    {fieldname: 'hash'        , label: 'Hash'                },
    {fieldname: 'hashtype'    , label: 'Type de hash utilisé'},
    {fieldname: 'filename'    , label: 'Nom du fichier'      },
    {fieldname: 'filesize'    , label: 'Taille (octets)'     },
    {fieldname: 'content_type', label: 'Type de contenu'     },
]

// window.open('assets/mypdf.pdf');
const unmega    = 1024000;  // 1024 octets = 1ko x 1000 = 1 méga
//const deuxmegas = 2048000;

// Source: https://developer.mozilla.org/fr/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
//const mytypes       = [
//    'image/png', 'image/jpeg', 'image/gif',
//    'application/pdf'
//];

export const readMyFile = (file) => {
    const reader = new FileReader();
//console.error ("TYPE", file.type, file);
    return new Promise((resolve, reject) => {
//        if (!mytypes.includes(file.type)) {
//            reject("Format de fichier non supporté")
//        } else {
            reader.onload  = (e) => { resolve(e.target.result) }
            reader.onerror = (e) => { reject (e, "Erreur lors de l'import du fichier...") }

            //reader.readAsDataURL(file);
            reader.readAsArrayBuffer(file);
//        }
    })
}

export const handleUploadFile  = ({e, clbk, nbmaxfichiers=1}) => {
    const dataTransfer  = ('dataTransfer' in e && e.dataTransfer ?e.dataTransfer:null);
    const target        = ('target'       in e && e.target       ?e.target      :null);
    const selectedFiles = (dataTransfer?dataTransfer.files:target?target.files  :null);
    const nbselected    = (selectedFiles?selectedFiles.length:0);

    //if (filesize && filesize<deuxmegas) {
    if (nbselected > nbmaxfichiers) {
        const message = ["Trop de documents sélectionnés",nbmaxfichiers,"disponibles,",nbselected,"sélectionnés"].join(' ');
        RenderMetaToast("Fichier",message,"danger","Fichier");
    } else if (nbselected > 0) {
        for (let ific = 0; ific < nbselected; ific++) {
            const fileToLoad   = selectedFiles[ific];
            const filename     = (fileToLoad && 'name' in fileToLoad?fileToLoad.name:'unknown');
            const content_type = (fileToLoad && 'type' in fileToLoad?fileToLoad.type:null);
            const filesize     = (fileToLoad && 'size' in fileToLoad?fileToLoad.size:null);

            RenderMetaToast(filename,"Chargement en cours","info",filename);
            if (filesize) {
                readMyFile (fileToLoad)
                    .then(fileLoaded => {
                        const file        = fileLoaded;
                        //const type        = "SET_OEUVRE_FILES";
                        const hashtype    = "sha256";
                        const browserfile = fileToLoad;
                        mysha256sumFromBuffer (file)
                            .then  (hash  => {
                                const values = {hash, hashtype, filename, content_type, filesize, browserfile};
                                //const action = {type, ...values, browserfile};
                                clbk ({doc: values});
                                // JFB on ne dispatche plus les fichiers via le store
                                //store.dispatch(action);
                                RenderMetaToast(filename,"Empreinte calculée","success",filename);
                                //setMyPDF({fileData: fileLoaded, filename});
                            })
                            .catch (error => {
                                RenderMetaToast(filename,"Erreur de calcul d'empreinte","danger",filename);
                                console.log ("ERROR", error);
                            });
                    })
                    .catch((e, err) => {
                        RenderMetaToast(filename,err,"danger",filename);
                        console.error ("ERROR", err, e);
                    });
            } else {
                RenderMetaToast(filename,"Problème sur la taille de votre fichier ("+Math.round(filesize/unmega*100)/100+" Mo)","danger",filename);
            }
        }
    } else {
        RenderMetaToast("Fichier","Pas de fichier sélectionné","warning","Fichier");
    }
}

//const mysize  = { maxHeight: "450px", maxWidth: "450px" }
//const MyAudio = ({hash, myfile, filename, content_type}) =>
//    <>
//    <audio controls id={hash} src={myfile}>
//        Your browser does not support the
//        <code>audio</code> element.
//    </audio>
//    <label htmlFor={hash} className="w-100 m-auto label-upload-pdf">{filename} ({content_type})</label>
//    </>
//
//const MyVideo = ({hash, myfile, filename, content_type}) =>
//    <>
//    <video controls id={hash} src={myfile} type={content_type} style={mysize}>
//        Your browser does not support the
//        <code>video</code> element.
//    </video>
//    <label htmlFor={hash} className="w-100 m-auto label-upload-pdf">{filename} ({content_type})</label>
//    </>
//
//const MyImage = ({hash, myfile, filename, content_type}) =>
//    <>
//    <img id={hash} src={myfile} style={{width: '100%'}}/>
//    <label htmlFor={hash} className="w-100 m-auto label-upload-pdf">{filename} ({content_type})</label>
//    </>
//
////<object aria-label="oeuvre" type={content_type} data={myfile} style={{width:'100%', height:'56em'}}></object>
//const MyDefault = ({hash, myfile, filename, content_type}) =>
//    <>
//    <p aria-label="oeuvre" type={content_type} data={myfile} style={{width:'100%', height:'56em'}}>Rendu non dispoonible pour {content_type}</p>
//    <label htmlFor={hash} className="w-100 m-auto label-upload-pdf">{filename} ({content_type})</label>
//    </>

//const trendr = {
//    audio:   MyAudio   // (props) => MyAudio   (props)
//   ,video:   MyVideo   // (props) => MyVideo   (props)
//   ,image:   MyImage   // (props) => MyImage   (props)
//   ,default: MyDefault // (props) => MyDefault (props)
//}
//const trendrkeys = Object.keys(trendr);

//export const Fichier = ({fields, meta: {error, submitFailed}}) => {
//const myfichierform = 'fichier';
// TBC JFBJFB
//const formfichierselector = formValueSelector(myfichierform);
// !!! Attention: à utiliser dans un MetaDoc de type fichier
//export const EditFichier = (props) => {
//console.log ("EditFichier", props);
//if (myfichierform === 'fichier') {return "cf trace"}
//    const myState      = store.getState();
//    const hashtype     = (formfichierselector             ?formfichierselector(myState, 'hashtype'    ):null);
//    const content_type = (formfichierselector             ?formfichierselector(myState, 'content_type'):null);
//    const tfiletype    = (content_type                    ?content_type.split('/')                     :null);
//    const base_type    = (tfiletype                       ?tfiletype[0]                                :null);
//    const whichrender  = (base_type && base_type in trendr?base_type                                   :'default');
//    const clbk         = ({doc}) => { updateFormFields ({formname: myfichierform, doc}); }
//    //const disabled     = (!(hashtype && hashtype !== undefined));
//    const ButtonUpload = () =>
//        <div className="w100 text-center div-upload-pdf my-2">
//            <input accept="*" name="upload-my-file" id="upload-my-file" type="file" onChange={e => handleUploadFile({e, clbk})}/>
//            <label id="label-upload-pdf" className="containerFlexCol" style={{alignItems: 'center'}} htmlFor="upload-my-file">
//                <Button component="span" className="btnTextGreen">
//                    Sélectionner un fichier
//                    
//                </Button>
//                <span>Taille maximale : la taille mémoire de votre système</span>
//            </label>
//        </div>
//    const RenderInplace = () =>
//        <div style={{maxwidth:'450px', maxheight:'450px'}}>
//        { trendr[whichrender](props) }
//        </div>
//
//    return (
//        <div>
//        <div style={mysize} className="m-auto filesTable">
//            {tfilefields.filter(f => f.fieldname!=='_id').map((f,i) =>
//                <Field key={i} disabled /*validate={[required]}*/ name={f.fieldname} type="text" component={RenderField} placeholder={f.label} />
//            )}
//        </div>
//        { hashtype
//            ?   <RenderInplace />
//            :   <ButtonUpload  />
//        }
//        </div>
//    );
//}

// Passer en pros userData ou addbutton
export const TableauFichiers = (props=null) => {
    //const mystatus = r => <TipsFromData  rowdata={r} myField='status' myCompo={IconFromData} tipCompo={CellFromEchelle} />;
    //const myddoc   = r => <FilesFromData rowdata={r} />;
    //const myaction = r => <CellAction    rowdata={r} actions={['trash']} />;
    return (
        <Datatable {...myFabrik.getCompo('liste_fichiers')} tracktype='fichier'
            thClass      = 'thead-light'
            tableClass   = 'table table-striped table-bordered table-hover table-sm m-auto'
            hDescDt      = {{
                "filename"              : { title: "Nom"                        },
                //"hash"                  : { title: "Hash",                      },
                //"hashtype"              : { title: "Algorithme de hash"         },
                "content_type"          : { title: "Type"                       },
                "filesize"              : { title: "Taille (octets)"            },
                //"action"                : { title: "Action", contenu: myaction  },
            }}
            {...props}
        />
    );
}

export const DashboardFichiers = () => {
    return (
    <div>
        <h3 className="py-3 text-left">Mes empreintes de documents</h3>
        <TableauFichiers />
    </div>
    );
}


// const handleChange  = (e) => {
// 	const uuidtoast = RenderMetaToast("Import","Import du document","warning");
// 	let input       = e.target;
// 	//let myfilename  = e.target.value;
// 	let myfile      = input.files[0];
// 	if (myfile) {
// 	try {
// 	let reader = new FileReader();
// 	reader.onload = (justificatif) => {
// 		//const myData = justificatif.target.result;
// 		const myFileName   = ('name' in myfile && myfile.name?myfile.name:'noname.pdf');
// 		oeuvreFiles = {};
// 		oeuvreFiles[myFileName] =
// 		{
// 		content_type: 'application/pdf', // ou myfile.type
// 		data        : myfile
// 		};
// 		store.dispatch({ type: "SET_OEUVRE_FILES", oeuvreFiles });
// 		RenderMetaToast("Import fichiers","Import du document terminé","success",uuidtoast);
// 	}
// 	reader.readAsArrayBuffer(myfile);
// 	}
// 		catch (e) {
// 			RenderMetaToast("Import","Erreur lors de l'import de votre document", "danger", uuidtoast);
// 			console.error ("BlocOeuvreConnected: fichier incorrect ou erreur lors de l'import", e, myfile);
// 		}
// 	}
// }

