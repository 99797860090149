import React from 'react';

export const Footer = () => {
    const monannee = new Date().getFullYear();
	return(
		<div className="footer d-flex flex-wrap justify-content-center text-left">
			<div className="col-lg-4">
				<h4>Services</h4>
				<ul className="p-0">
					<li><a href="https://erasm.io"           rel="noopener noreferrer">Erasm           </a> </li>
					<li><a href="https://erasm.io/contactus" rel="noopener noreferrer">contact@erasm.io</a> </li>
				</ul>
			</div>
			<div className="col-lg-4">
				<h4>Ressources</h4>
				<ul className="p-0">
					<li><a href="https://erasm.io/contactus"              rel="noopener noreferrer">Contactez-nous</a> </li>
					<li><a href="https://erasm.io/blog/"                  rel="noopener noreferrer">Blog          </a> </li>
				</ul>
			</div>
			<div className="col-lg-4 text-center">
				<span className="copyright">Copyright {monannee} ©Erasm</span>
			</div>
		</div>
	);
}
