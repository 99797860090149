import {justGetFromSelector}      from './Bdd';
import {ListenFromIdsOrFilter}    from './Bdd';
import {RenderMetaToast}          from './MetaToast';

// Exemple de work hooks / évènements sur lesquels une application peut s'abonner
//export const tWorkHooks = {
//    commons: {
//        "CGU"           : { hook: doc=>('type' in doc && doc.type==='CGU'           ), selector: {type: 'CGU'           }, onchange: onChangeCGUFromCommons   , firstselector: {type: 'CGU',          status:           "production"            } },
//        "PGP"           : { hook: doc=>('type' in doc && doc.type==='PGP'           ), selector: {type: 'PGP'           }, onchange: onChangePGPFromCommons   , firstselector: {type: 'PGP',          status:           "production"            } },
//    },
//    customer: {
//        "author-credits": { hook: doc=>('_id'  in doc && doc._id ==='author-credits'), selector: {_id: 'author-credits' }, onchange: onChangeAuthorCredits    },
//        "mon_profil"    : { hook: doc=>('_id'  in doc && doc._id ==='mon_profil'    ), selector: {_id: 'mon_profil'     }, onchange: onChangeMonProfil        },
//        "notification"  : { hook: doc=>('type' in doc && doc.type==='notification'  ), selector: {type: 'notification'  }, onchange: onChangeNotifications    , firstselector: {type: 'notification', dateacquittement: {'$exists': false      }} },
//        "profil_verif"  : { hook: doc=>('type' in doc && doc.type==='profil_verif'  ), selector: {type: 'profil_verif'  }, onchange: onChangeStatusProfilVerif},
//        "CGU"           : { hook: doc=>('type' in doc && doc.type==='CGU'           ), selector: {type: 'CGU'           }, onchange: onChangeCGU              , firstselector: {type: 'CGU',          status:           {'$ne'    : 'confirmed'}} },
//        "PGP"           : { hook: doc=>('type' in doc && doc.type==='PGP'           ), selector: {type: 'PGP'           }, onchange: onChangePGP              , firstselector: {type: 'PGP',          status:           {'$ne'    : 'confirmed'}} },
//    },
//}


let tentries  =[]; //A flagger à l'initialisation avec Object.keys (tWorkHooks);
let pWorkHooks={};

const onHookChange = (entry, type, info) => {
    const pentry = (entry && tentries.indexOf(entry)>-1?pWorkHooks[entry]:null);
    const mytype = (pentry && type && type in pentry?type:null);
    const pinfos = (mytype?pentry[mytype]:null);
//mymetalog ("NOTIFICATION", entry, type, info);
    if (pinfos) {
        pinfos.onchange(info);
    }
    else {
        console.error ("Type de notification inconnue", entry, type, info);
    }
}

const onError    = (info, titre) => { RenderMetaToast(titre,"Réception impossible: rechargez l'application et informez votre administrateur","danger"); }
// from = commons ou customer
export const SilentlyTriggerFrom = ({from, bddentry}) => {
    if (!(from && tentries.indexOf(from) > -1))
    { throw new Error ({text: "Bad entry", from, tentries}); }

    // Commencer par charger les éléments surveillés
    // Pour les notification firstselector permet par exemple
    // de charger les notifications non acquittées.
//console.log ("SILENTLYTRIGGERFROM", from, bddentry);
    Object.keys(pWorkHooks[from]).forEach (k => {
        const myhook   = pWorkHooks[from][k];
        const selector = ('firstselector' in myhook?myhook.firstselector:'selector' in myhook?myhook.selector:null);
        justGetFromSelector ({bddentry, selector})
            .then  (res => {
                //console.log (from, k, selector, res)
                const docs = (res && 'docs' in res && res.docs?res.docs:null);
                if (docs && docs.length>0) {docs.forEach (doc => {
                    const info = {doc};
                    onHookChange(from, k, info);
                })}
            })
            .catch (error  => {
                console.error ({error, bddentry, selector});
            })
    });
    const myHooks = Object.keys(pWorkHooks[from]).map(k => {
        const myptrk        = pWorkHooks[from][k];
        const myptrhook     = ('hook' in myptrk?myptrk.hook:null);
        const myptronchange = ('onchange' in myptrk?myptrk.onchange:null);
        if (!(myptrhook)    ) { throw new Error ({text: "Hook missing",     from, k, myptrk}); }
        if (!(myptronchange)) { throw new Error ({text: "onchange missing", from, k, myptrk}); }
        return (info => {
            const doc = ('doc' in info && info.doc?info.doc:null);
            const activate = info && myptrhook(doc);
            //console.log ("ACTIVATE ?", from, k, activate);
            if (activate) {
                //console.log ("ACTIVATE HOOK DOC", from, k, myptrhook, myptronchange);
                return myptronchange(info);
            }
        })
    });
    const onchange  = (info) => {
        //console.log ("CHANGE DETECTED", info);
        myHooks.forEach(hook => hook(info));
    }
    const onerror   = onError;
    //console.log ("INFOSDB", infosdbs[bddentry]["remotedb"]);
    // Ensuite rentrer dans une boucle d'écoute du sélecteur
    // Séquence d'écoute suivante provoque un blocage xhr
    //Object.keys(pWorkHooks[from]).forEach (k => {
    //    tPtrsListen.push (myPtrListen(from, k, bddentry));
    //})
    const title     = from;
    const ptrcancel = ListenFromIdsOrFilter ({bddentry, doc_ids:null, filter:null, onchange, onerror, title});
    return (ptrcancel);
}

export const F_InitWorkHooks = ({tWorkHooks}) => {
    tentries=Object.keys (tWorkHooks);
    pWorkHooks = tWorkHooks;
}

