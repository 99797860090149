import React from 'react';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
export const required = value => (value || typeof value === 'number' ? undefined : "Information requise")
// export const maxLength = max => value =>
//   value && value.length > max ? `Must be ${max} characters or less` : undefined
// const maxLength15 = maxLength(15)
export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined
export const minLength2 = minLength(2)
export const minLength12 = minLength(12)

// const number = value =>
//   value && isNaN(Number(value)) ? 'Must be a number' : undefined
// const minValue = min => value =>
//   value && value < min ? `Must be at least ${min}` : undefined
// const minValue13 = minValue(13)
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Adresse e-mail invalide'
    : undefined

export const alphaNumeric = value =>
  value && /^[a-zA-Z0-9éèöïçâê -]$/i.test(value)
    ? 'Caractères alphanumériques autorisés'
    : undefined

export const identitycars = value =>
  value && /^[a-zA-Z0-9éèöïçâê -,;]$/i.test(value)
    ? 'Caractères alphanumériques autorisés'
    : undefined

export const regPassword = value => {
	const debutPhrase = "Vous devez renseigner au moins ";
    const longueur  =  value && value.length > 11      ? null : ' 12 caractères';
	const majuscule =  value && /([A-Z]+)/.test(value) ? null : ' 1 majuscule';
	const minuscule =  value && /([a-z]+)/.test(value) ? null : ' 1 minuscule';
	const chiffre   =  value && /([0-9]+)/.test(value) ? null : ' 1 chiffre';
	const special   =  value && /([@[\]!"#()&*/:;=|~$€-]+)/.test(value) ? undefined : ' 1 caractère spécial';
	const myArray   = [longueur, majuscule, minuscule, chiffre, special];
    let   isInvalid = true;
    let   message   = null;
	if (value) {
		const arrayToJoin = myArray.filter(resultat => resultat)
        isInvalid   = (arrayToJoin && arrayToJoin.length>0);
		message     = (isInvalid
			? <p className="mt-2 red-text"  >{debutPhrase+arrayToJoin.join(',')}</p>
			: <p className="mt-2 green-text"><VerifiedUserIcon /></p>
        );
	}
    return {isInvalid, message};
}

// @  [  !
// "  #  (
// 	)  *  /
// 	:  ;  =
// 	|  ~

//Regex pour numéro international format français sous la forme +33651904425
// https://www.developpez.net/forums/d1981502/php/langage/regex-pattern-numero-telephone-format-francais-international/
export const phoneNumber = value => {
    let retvalue = null;
    if (value) {
        // Supprimer les espaces, tirets, . dans le numero de téléphone
        // Supprimer les (0)
        const m2 = value.replace(/[\s\-.]/g, '').replace(/\(0\)/g,'')
        const isvalid = /^(\+\d{1,3}|00\d{1,3}|0)\d{6,12}$/.test(m2);
        if (!isvalid) {
            retvalue = 'Numéro de téléphone invalide, utilisez une notation +33 6 12 34 56 78 ou 0033 6 12 34 56 78'
        }
    }
    return (retvalue);
}

export const regOtp = value => {
    let isvalid = false;
    let message = '6 chiffres / exemple: 123456';
    if (value) {
        // Supprimer les espaces, tirets, . dans le numero de téléphone
        // Supprimer les (0)
        const m2 = value.replace(/[\s\-.]/g, '');
        isvalid  = /^\d{6}$/.test(m2);
        if (isvalid) {
            message = null;
        }
    }
    return {isvalid, message};
}

