import React                 from 'react';
import {connect}             from 'react-redux';

//import {makeStyles}          from '@material-ui/core/styles';
//import {useTheme}            from '@material-ui/core/styles';
//import CssBaseline           from '@material-ui/core/CssBaseline';

import {CRUDcompo}           from './methanol/Bdd';
import {disconnectFromMyApp} from './methanol/Login';

import {onChangeMonProfil}   from './model/Actions';
import {AppModales}          from './components/Modales';
import {MenuUp}              from './components/Header';
import {MenuGauche}          from './components/SideBar';
import {hTopMenus}           from './components/SideBar';
import {Footer}              from './components/Footer';

import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// Initialisation des valeurs du module - app connectée
export const initappvalues = () => {
    const mycrud   = new CRUDcompo({type: 'profil'});
    // Chargement du profil si présent
    // Sinon, on ignore silencieusement
    mycrud.read ({_id: 'mon_profil'})
    .then  ((doc) => {
        //console.log ("CRUD", doc);
        onChangeMonProfil ({doc});
    })
    .catch((/*error*/) => {
        //console.log ('CRUD', error);
    })
}

const MyContent = ({fildAriane}) => {
    const lebonfil = fildAriane.target;

    return (
	<div className="main-dashboard col-12 m-auto">
        {lebonfil in hTopMenus?React.createElement(hTopMenus[lebonfil].compo):null}
	</div>
    );
}

const AppConnected = ({fildAriane}) => {
    //const [mobileOpen, setMobileOpen] = React.useState(false);
    const [open, setOpen] = React.useState(null);
    //const classes            = useStyles();
    //const theme              = useTheme ();
    //const handleDrawerToggle = () => { setMobileOpen(!mobileOpen); };
    const handleClose        = () => { setOpen(false); };
    //classes.content: { flexGrow: .75, padding: theme.spacing(3), },
    return (
        <div className="App height-100">
            <MenuUp 
                open={open}
                handleClose={handleClose}
                disconnect={disconnectFromMyApp}
            />
            <div className="d-flex mainAll">
                {/*
                        <CssBaseline />
                        <main className={classes.content+' mainContent mx-auto'}>
                        <MenuGauche classes={classes} theme={theme}
                            mobileOpen={mobileOpen}
                            handleDrawerToggle={handleDrawerToggle}
                            fildAriane={fildAriane}
                        />
                        <main className={classes.content+' mainContent mx-auto'}>
                */}
                <MenuGauche fildAriane={fildAriane} />
                <main className={'mainContent mx-auto'}>
                    
                <MyContent fildAriane={fildAriane}/>
                </main>
            </div>
            <Footer />
            <AppModales />
        </div>
    );
}

export const App = connect(
    state => ({
        fildAriane: state.fildAriane,
    }
))(AppConnected);

//const drawerWidth = 260;

// Style du layout
//const useStyles = makeStyles((theme) => ({
//
//    //   appBar: {
//    //     [theme.breakpoints.up('sm')]: {
//    //       width: `calc(100% - ${drawerWidth}px)`,
//    //       //marginLeft: drawerWidth,
//    //     },
//    //   },
//
//    drawer: {
//        [theme.breakpoints.up('sm')]: {
//            width: drawerWidth,
//            flexShrink: 0,
//        },
//    },
//    appBar: {
//        [theme.breakpoints.up('sm')]: {
//            // width: `calc(100% - ${drawerWidth}px)`,
//        },
//    },
//    menuButton: {
//        marginRight: theme.spacing(2),
//        [theme.breakpoints.up('sm')]: {
//            display: 'none',
//        },
//    },
//    // necessary for content to be below app bar
//    toolbar: theme.mixins.toolbar,
//    drawerPaper: {
//        width: drawerWidth,
//    },
//    content: {
//        flexGrow: .75,
//        padding: theme.spacing(3),
//    },
//    root: {
//        flexGrow: 1,
//        display: 'flex',
//    },
//    title: {
//        flexGrow: 1,
//    },
//}));

// Props dans le MenuUp
//classes={classes}
// handleDrawerToggle={handleDrawerToggle}
// handleMenu={handleMenu}
// anchorEl={anchorEl}
// open={open}
// handleClose={handleClose}
// disconnect={disconnectFromMyApp}
