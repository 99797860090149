export let appstore      = null;
export let hEchelle      = {};
export let hKeysEchelle  = {};
export let appmodels     = {};

export const {v4: uuid}  = require('uuid');
export const setappstore = (newstore)      => { appstore = newstore;  }
export const setechelles = (hListEchelles) => { hEchelle = hListEchelles; Object.keys(hEchelle).forEach(e=>{hKeysEchelle[e]=Object.keys(hEchelle[e])}); };
export const setmodels   = (newmodels)     => { appmodels= newmodels; }

export const mynature = (myField, rowdata) => {
    return (
        myField && myField in rowdata && rowdata[myField]
        ?(typeof(rowdata[myField])==='string'
            ?rowdata[myField]
            :('value' in rowdata[myField]
                ?rowdata[myField].value
                :null))
        :null
    );
}
export const injectdate = (doc) => {
    const newdoc = {...doc};
    if (!('created_at' in newdoc)) {
        newdoc.created_at = new Date().toISOString();
    }
    newdoc.updated_at     = new Date().toISOString();
    return (newdoc);
}

