import React                    from 'react';
import PropTypes                from 'prop-types';
import {Modal}                  from 'react-bootstrap';
import {connect}                from 'react-redux';

// Externe
//import {reset}                  from 'react-final-form';
//import {change}                 from 'react-final-form';
import Popover                  from 'react-bootstrap/Popover';
import OverlayTrigger           from 'react-bootstrap/OverlayTrigger';
import {Button}                 from 'react-bootstrap';
import Card                     from 'react-bootstrap/Card';

// Interne
//import {F_vRechargerFormFields} from './Bdd';
import {appstore}               from './Communs';

export const initModals   = {};
export const MODAL_CONSTS = {
    SHOW_MODAL       : "SHOW_MODAL",
    HIDE_MODAL       : "HIDE_MODAL",
}
export const modalInitState = { modal: initModals, }

const modalstatereducer = (state=initModals, action) => {
    switch (action.type) {
        case MODAL_CONSTS.SHOW_MODAL: {
            const newStateShow = { ...state };
            newStateShow[action.modal] = {show:true , param: (action.param?action.param:null)}
            return newStateShow;
        }
        case MODAL_CONSTS.HIDE_MODAL: {
            const newStateHide = { ...state };
            newStateHide[action.modal] = {show:false, param: (action.param?action.param:null)}
            return newStateHide;
        }
        default: {
            return state;
        }
    }
}
export const modalReducers  = { modal: modalstatereducer, }

// -----------------------------------------------------------------------------
// Montrer la modale de saisie du component
export const showModal = ({modal,
    //resetFirst=false,
    param=null}) => {
    //const formname = modal;
    // Reset du formulaire si mode édition
    // TBC JFBJFB
    //if (modal && resetFirst)
    //{
    //    appstore.dispatch      (reset(formname)); // Reset du formulaire
    //    //F_vRechargerFormFields ({targetform:modal,hFieldsAndValues:{_id:null,type:modal}});
    //}
//console.log ("SHOWMODAL", {modal, param}, appstore.getState());
    appstore.dispatch({type:MODAL_CONSTS.SHOW_MODAL, modal, param});
}

// --------------------------------------------------------------------------------
// Update des champs d'un formulaire à partir du doc passé en paramètre
// TBC JFBJFB
//export const updateFormFields = ({formname, doc}) => {
//    doc && Object.keys(doc).forEach(k =>
//        appstore.dispatch(change(formname, k, doc[k]))
//    )
//}

// --------------------------------------------------------------------------------
// Edition de doc dans la fenêtre modale du tracktype si fourni (sinon type du doc)
export const editModal = (props) => {
    const {doc, tracktype=null, param=null, modal=null} = props;
    const editmodaluuid = (modal?modal:tracktype?tracktype:doc && 'type' in doc?doc.type:null);
    //const formname = editmodaluuid;
    if (!editmodaluuid) { console.error ("TYPE UNDEFINED",doc,editmodaluuid); return; }
    // Vider le formulaire correpondant au document
    // TBC JFBJFB
    //appstore.dispatch(reset(formname));
    // Charger le formulaire avec le contenu de la Bdd
    //F_vRechargerFormFields ({targetform:editmodaluuid, hFieldsAndValues:doc});
    // TBC JFBJFB
    //updateFormFields({formname, doc})
    // Afficher le formulaire
//const mystate = appstore.getState();
//console.log ("EDITMODAL", {props, editmodaluuid, param, mystate});
    showModal ({modal:editmodaluuid, param:{...param, doc}});
}

// -----------------------------------------------------------------------------
// Cacher la modale de saisie du component
export const hideModal = ({modal}) => {
//console.log ("HIDE MODAL", modal);
    appstore.dispatch({type:"HIDE_MODAL", modal});
}

export const IsShowed = (state=null, type) => {
    const myState = (state?state:appstore.getState());
    return (type in myState.modal?myState.modal[type].show:false);
}

const delay = {show: 200, hide: 100};
export const MyOverlayTrigger = (props) => {
    const {tipCompo=null, mystruct=null, placement='right', children} = props;
    const tipField = (mystruct && 'tipField' in mystruct?mystruct.tipField:null);
    const tip      = (mystruct && 'tip'      in mystruct?mystruct.tip     :null);
    const renderPopOver = (tooltipprops) => {
        const myContent = (tipCompo
            ?   React.createElement(props.tipCompo, props)
            :   tipField
            ?   <span>{tipField}</span>
            :   tip
            ?   <span>{tip}</span>
            :   <span>&quot;...&quot;</span>
        );
        return (
            <Popover {...tooltipprops}>
                <Popover.Header as="h3" className="methanol-popover">
                    Plus d&apos;informations...
                </Popover.Header>
                <Popover.Body>
                    {myContent}
                </Popover.Body>
            </Popover>
        );
    }

    return (
        <OverlayTrigger
            trigger={['hover', 'focus']}
            placement= {placement}
            delay    = {delay}
            overlay  = {renderPopOver}
        >
            {children}
        </OverlayTrigger>
    );
}
MyOverlayTrigger.propTypes = {
    children:  PropTypes.element,
    tipCompo:  PropTypes.func,
    mystruct:  PropTypes.object,
    placement: PropTypes.string,
};

// Montrer le contenu d'un document de manière agnostique
export const ShowMyDoc = ({doc}) => {
    return (
        Object.keys (doc)
        .filter (k => (["number","string","boolean"].indexOf(typeof doc[k])>=0))
        .map    ((k,i) =>
            <div className="d-flex" key={i}>
            <label htmlFor={k} className="col-3"                  >{k}     </label>
            <div   name   ={k} className="col-9 my-1 form-control">{doc[k]}</div>
            </div>
        )
    );
}

// param = {doc, yes, no}
// yes et no peuvent sont des pointeurs de fonction sans paramètre.
// Une fenêtre modale est affichée dans laquelle on affiche du mieux
// qu'on peut le contenu du doc.
// La question pour confirmer la destruction,
// un bouton Oui
// et un bouton Non
// Si Oui est choisi, on appelle la fonction yes(),
// Si Non est choisi, on appelle la fonction no().
const YesOrNoConnected = ({show=false,param=null}) => {
    const myCloseAction = () => hideModal({modal: "yesorno"});
    // param = doc, yes(), no()
    const mydoc = (param && 'doc' in param?param.doc:{}  );
    const myyes = (param && 'yes' in param?param.yes:null);
    const myno  = (param && 'no'  in param?param.no :null);
    const myconfirm = () => { if (myyes) {myyes();} myCloseAction(); }
    const myescape  = () => { if (myno ) {myno ();} myCloseAction(); }

    return (
        <Modal show={show} onHide={myCloseAction} dialogClassName="modal-lg">
            <Modal.Header closeButton>
                <Modal.Title>Confirmer la suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body className="m-4 text-center">
                <div className="col">
                <Card>
                    <ShowMyDoc doc={mydoc}/>
                </Card>
                </div>
                <div className="col">
                    <Button className="m-2" variant="danger"  onClick={myconfirm}>Oui, supprimer</Button>
                    <Button className="m-2" variant="success" onClick={myescape }>Annuler       </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
YesOrNoConnected.propTypes = {
    show:  PropTypes.bool,
    param: PropTypes.object,
};

export const YesOrNo = connect(
    state    => (
        'modal' in state && 'yesorno' in state.modal
            ?{show: state.modal['yesorno']['show'], param: state.modal['yesorno']['param']}
            :{show: false                         , param: null                           }
    ),
)(YesOrNoConnected);

export const SaltAndPepper = () =>
    <YesOrNo />

