import React              from 'react';
import {connect}          from 'react-redux';
import {Button}           from 'react-bootstrap';

import {openshop}         from '../Communs';

//import IconButton         from '@material-ui/core/IconButton';
//import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import SecurityIcon       from '@material-ui/icons/Security';

const NbCreditsConnected = ({nbcredits}) => {
    const myvariant=(nbcredits<2?'danger':nbcredits<4?'warning':'outline');
    return (
        <Button variant={myvariant} onClick={openshop}>
            <SecurityIcon />
            <span id="nbrCredits-id font-weight-bold">
                <b>{nbcredits}</b>
               {' crédit'+(nbcredits>1?'s':'')}
            </span>
        </Button>
    );
}
const NbCredits = connect(
    state => ({
        nbcredits     : state.nbcredits,
    })
)(NbCreditsConnected);


const MyCreditsConnected = ({wecanstart}) => {
    return (wecanstart
        ? <NbCredits />
        : "-"
    );
}

export const MyCredits = connect(
    state => ({ wecanstart: state.wecanstart })
)(MyCreditsConnected);

