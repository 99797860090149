import {store}                    from '../Store';
import {setCGU}                   from '../Communs';
import {setPGP}                   from '../Communs';
import {setdocCGUtoconfirm}       from '../Communs';
import {setdocPGPtoconfirm}       from '../Communs';
import {setNbCredits}             from '../Communs';
import {setMonProfil}             from '../Communs';
import {setVerifiedProfil}        from '../Communs';
import {setStatusProfilVerif}     from '../Communs';
import {processcommons}           from '../Communs';
import {mysha256sum}              from '../Communs';
//import {infosdbs}                 from '../methanol/Bdd';
import {CRUDcompo}                from '../methanol/Bdd';
import {getdefaultbddentry}       from '../methanol/Bdd';
import {showModal}                from '../methanol/Modal';
import {hideModal}                from '../methanol/Modal';
import {RenderMetaToast}          from '../methanol/MetaToast';
import {CheckCondsProfilVerified} from '../components/ProfilVerif';
import {tKeysProfilsVerif}        from '../components/ProfilVerif';
//import {mymetalog}                     from '../methanol/Bdd';

// Composant jamais affiché
// Sert à récupérer les actions demandées par le robot.
export const F_vAcquitterNotification = (doc) => {
    const type      = 'notification';
    const _id       = doc._id;
    const mycrud    = new CRUDcompo({type});
    const date      = new Date().toISOString();
    //const thenclbk = (/*doc2*/) => {/*console.log ("APRES UPDATE notification", doc2);*/}
    return mycrud.read({_id})
        .then(doc => {
            doc.dateacquittement=date;
            return (mycrud.save({doc}));
        })
        .catch (error => {
            console.error ("Impossible d'acquitter une notification", error);
        })
}

const hook_draft = (docaction) => {
//mymetalog ("HOOK_DRAFT", docaction);
    const {docid=null} = docaction;
    if (docid)
    {
        const _id      = docid; // _id de la protection
        const mycrud   = new CRUDcompo({type: 'protection'});
        mycrud.read ({_id})
        .then  (docprotection => {
//mymetalog ("HOOK DRAFT OK", docaction);
            const pclbk = ('dateacquittement' in docaction?hideModal:showModal);
            pclbk({modal: "ok_protection_modale", param: {doc: docprotection, notifdoc: docaction}});
        })
        .catch (() => {
//mymetalog ("HOOK DRAFT ERROR", docaction);
            RenderMetaToast("Notification", "Error draft", "danger");
        });
    } else {
        console.log ("ERROR hook_draft", docaction);
    }
}

const MAX_TRY = 5;
const hook_default = (docaction, msg) => {
    const {_id=null, docid=null, nbtry=0} = docaction;
    if (nbtry > MAX_TRY)
    {
        // TBC remonter erreur applicative
        console.error ("Acknowledge error", {docaction, msg});
    }
    else if (_id && docid)
    {
        const myuuid = RenderMetaToast("Notification",[msg,docid].join(' '),"info", _id);
        const mycrud = new CRUDcompo({type: 'notification'});
        mycrud.read ({_id})
        .then (doc => {
            //const _rev             = (doc && '_rev'             in doc?doc._rev            :null);
            //const dateacquittement = (doc && 'dateacquittement' in doc?doc.dateacquittement:null);
            //console.log ("JFBJFB 1", {nbtry, _id, _rev, dateacquittement, doc});
            if (doc && 'dateacquittement' in doc) {
                //console.log ("JFBJFB 1 déja acquitté", {nbtry, dateacquittement, _rev, doc});
                return ('oK');
            } else {
                const dateacquittement  = new Date().toISOString();
                doc['dateacquittement'] = dateacquittement;
                //console.log ("JFBJFB 2 avant save...", {nbtry, dateacquittement, _rev, doc});
                return mycrud.save({doc});
            }
        })
        .then ((/*res*/) => {
            //console.log ("JFBJFB 3 apres save ok...", {nbtry, res});
            RenderMetaToast("Notification",[msg,docid].join(' '),"success",myuuid);
            return('oK');
        })
        .catch ((error) => {
            const conflict = (error && 'error'  in error && error.error  == "conflict");
            const qtrcneuf = (error && 'status' in error && error.status == 409       );
            if (nbtry < MAX_TRY && (conflict || qtrcneuf)) {
                docaction['nbtry'] = nbtry + 1;
                // Requête récursive / permet de traiter les 409 en cas de conflit d'écriture
                // suite à des updates du robot.
                //console.log ("JFB RETRY", {_id, error, conflict, qtrcneuf, nbtry});
                return (hook_default(docaction, msg));
            } else {
                console.error ("Acknowledge error", {_id, docid, error, conflict, qtrcneuf, nbtry});
            }
        })
    }
}

const hook_otpsent = (docaction) => {
    const {dateacquittement=null, docid=null} = docaction;
    if (docid && !dateacquittement) {
        const _id      = docid;
        const mycrud   = new CRUDcompo({type: 'protection'});
        mycrud.read ({_id})
        .then  (docprotection => {
            const pclbk = (dateacquittement?hideModal:showModal);
            pclbk({modal: "otp_modale", param: {doc:docprotection, notifdoc: docaction}});
            return
        })
        .catch (() => {
            RenderMetaToast("Notification","Error otp sent","danger","OTP");
        });
    }
}

const hook_otprepeat = (docaction) => {
    const {dateacquittement=null, docid=null} = docaction;
    if (docid && !dateacquittement) {
        RenderMetaToast("Notification","OTP saisi incorrect","danger","OTP");
        hook_otpsent (docaction);
    }
}

const hook_otpconfirmed = (docaction) => {
    const {dateacquittement=null, docid=null} = docaction;
    if (!(dateacquittement) && docid) {
        const _id      = docid;
        const mycrud   = new CRUDcompo({type: 'protection'});
        mycrud.read ({_id})
        .then (docprotection => {
            RenderMetaToast("Certificat","OTP confirmé ("+(docprotection && 'denomination' in docprotection?docprotection.denomination:_id)+")","info","OTP");
            return F_vAcquitterNotification (docaction);
        })
        .then (() => {
            RenderMetaToast("Certificat","OTP ok","success","OTP");
            return
        })
        .catch (() => {
            RenderMetaToast("Certificat","Error otp confirmed","danger","OTP");
        });
    }
}

const hook_signcanceled = (docaction) => {
    const {dateacquittement=null, docid=null} = docaction;
    const mytoastid = "Certificat";
    if (!(dateacquittement) && docid) {
        const _id      = docid;
        const mycrud   = new CRUDcompo({type: 'protection'});
        mycrud.read ({_id})
        .then (docprotection => {
            RenderMetaToast("Certificat","Certificat annulé ("+(docprotection && 'denomination' in docprotection?docprotection.denomination:_id)+")","warning",mytoastid);
            return F_vAcquitterNotification (docaction);
        })
        .then (() => {
            RenderMetaToast("Certificat","Certificat ok","success",mytoastid);
            return
        })
        .catch (() => {
            RenderMetaToast("Notification","Erreur de réception d'acquittement du certificat","danger",mytoastid);
        });
    }
}

const hook_signendedok = (docaction) => {
    const {dateacquittement=null, docid=null} = docaction;
    if (!(dateacquittement) && docid) {
        const _id      = docid;
        const mycrud   = new CRUDcompo({type: 'protection'});
        mycrud.read ({_id})
        .then (docprotection => {
            RenderMetaToast("Certificat","Certificat prêt ("+(docprotection && 'denomination' in docprotection?docprotection.denomination:_id)+")","info","Certificat");
            return F_vAcquitterNotification (docaction);
        })
        .then (() => {
            RenderMetaToast("Certificat","Certificat ok","success","Certificat");
            return
        })
        .catch (() => {
            RenderMetaToast("Notification","Erreur de réception d'acquittement du certificat","danger");
        });
    }
}

const hook_finaldocok = (docaction) => {
    const {dateacquittement=null,docid=null} = docaction;
    if (!(dateacquittement) && docid) {
        const _id      = docid;
        const mycrud   = new CRUDcompo({type: 'protection'});
        mycrud.read ({_id})
        .then (docprotection => {
            RenderMetaToast("Certificat","Certificat signé ("+(docprotection && 'denomination' in docprotection?docprotection.denomination:_id)+")","info","Certificat");
            return F_vAcquitterNotification (docaction);
        })
        .then (() => {
            RenderMetaToast("Certificat","Acquittement ok","success","Certificat");
            return
        })
        .catch (() => {
            RenderMetaToast("Notification","Error acquittement document protégé","danger");
        });
    }
}

const hook_ziploadingok = (docaction) => {
    const {dateacquittement=null,docid=null} = docaction;
    if (!(dateacquittement) && docid) {
        const _id      = docid;
        const mycrud   = new CRUDcompo({type: 'protection'});
        mycrud.read ({_id})
        .then (docprotection => {
            RenderMetaToast("Certificat","Zip du certificat en cours de chargement... ("+(docprotection && 'denomination' in docprotection?docprotection.denomination:_id)+")","info","Certificat");
            return F_vAcquitterNotification (docaction);
        })
        .then (() => {
            RenderMetaToast("Certificat","Zip loading ok","success","Certificat");
            return
        })
        .catch (() => {
            RenderMetaToast("Notification","Erreur de réception du zip du certificat","danger","Certificat");
        });
    }
}

const hook_proofdocok = (docaction) => {
    const {dateacquittement=null,docid=null} = docaction;
    if (!(dateacquittement) && docid) {
        const _id      = docid;
        const mycrud   = new CRUDcompo({type: 'protection'});
        mycrud.read ({_id})
        .then (docprotection => {
            RenderMetaToast("Certificat","Certificat reçu ("+(docprotection && 'denomination' in docprotection?docprotection.denomination:_id)+")","info","Certificat");
            return F_vAcquitterNotification (docaction);
        })
        .then (() => {
            RenderMetaToast("Certificat","Certificat ok","success","Certificat");
            return
        })
        .catch (() => {
            RenderMetaToast("Notification","Erreur de réception du certificat","danger");
        });
    }
}

const hook_signerror = (docaction) => {
    const {dateacquittement=null,docid=null} = docaction;
    if (!(dateacquittement) && docid) {
        const _id      = docid;
        const mycrud   = new CRUDcompo({type: 'protection'});
        mycrud.read ({_id})
        .then (docprotection => {
            RenderMetaToast("Certificat","Certificat en erreur ("+(docprotection && 'denomination' in docprotection?docprotection.denomination:_id)+")","danger","Certificat");
            return F_vAcquitterNotification (docaction);
        })
        .then (() => {
            RenderMetaToast("Certificat","Certificat en erreur","danger","Certificat");
            return
        })
        .catch (() => {
            RenderMetaToast("Notification","Error otp sent","danger");
        });
    }
}

const hook_checkerror = (docaction) => {
    const {dateacquittement=null,docid=null} = docaction;
    if (!(dateacquittement) && docid) {
        const _id      = docid;
        const mycrud   = new CRUDcompo({type: 'notification'});
        mycrud.read ({_id})
        .then (docprofil => {
            RenderMetaToast("Profil","Erreur dans le profil ("+(docprofil && 'status' in docprofil?docprofil.status:_id)+")","danger","Profil");
            return F_vAcquitterNotification (docaction);
        })
        .catch ((error) => {
            //RenderMetaToast("Profil","Impossible d'acquitter l'erreur","danger");
            console.error ("Erreur acquittement sur erreur de profil", error);
        });
    }
}

// Boucle infinie en écoute des notifications du robot
const tNotifActions = {
    'start'       : { hook: hook_default,      msg: "Démarrage..."                  },
    'confirm'     : { hook: hook_default,      msg: "Nouvelle convention à valider" },
    'confirmed'   : { hook: hook_default,      msg: "Convention validée"            },
    'checkdone'   : { hook: hook_default,      msg: "Pièce d'identité vérifiée"     },
    'checkendedok': { hook: hook_default,      msg: "Pièce d'identité validée"      },
    'checkerror'  : { hook: hook_checkerror,   msg: "Problème sur le profil"        },
    'createpdfcrt': { hook: hook_default,      msg: "Pdf du certificat créé"        },
    'signstarted' : { hook: hook_default,      msg: "Signature démarrée"            },
    'draft'       : { hook: hook_draft,                                             },
    'otpsent'     : { hook: hook_otpsent,                                           },
    'otprepeat'   : { hook: hook_otprepeat,                                         },
    'otpconfirmed': { hook: hook_otpconfirmed,                                      },
    'signcanceled': { hook: hook_signcanceled,                                      },
    'signendedok' : { hook: hook_signendedok,                                       },
    'finaldoc'    : { hook: hook_finaldocok,                                        },
    'ziploading'  : { hook: hook_ziploadingok,                                      },
    'proofdoc'    : { hook: hook_proofdocok,                                        },
    'signerror'   : { hook: hook_signerror,                                         },
}

export const onChangeNotifications = (info) => {
//mymetalog ("ONCHANGENOTIF", info);
    const {doc=null} = info;
    if (doc) {
        const {action=null} = doc;
        if (action && action in tNotifActions)
        {
            const tact = tNotifActions[action];
            const msg  = ('msg' in tact?tact.msg:null)
            tact.hook(doc, msg);
        }
        else
        // TBC: reporter une erreur logicielle dans ce cas
        { console.error ("Unknown action", action, doc); }
    }
}

const onChangeAuthorCredits = (info) => {
    const {doc=null} = info;
    if (doc) {
        const {nbcredits=0} = doc;
//mymetalog ("AUTHOR-CREDITS", doc, nbcredits);
        setNbCredits (nbcredits);
    }
}

export const onChangeMonProfil = (info) => {
    const {doc=null} = info;
    if (doc) {
        const profilok = CheckCondsProfilVerified (doc);
        setMonProfil      (doc);
        setVerifiedProfil (profilok?doc:null);
//console.log ("CHANGE", {profilok, doc}); const dix=10; if (dix) return;
    }
}

export const onChangeStatusProfilVerif = (info) => {
    const {doc=null} = info;
    if (doc) {
        const {status=null} = doc;
        const newsts = (status && tKeysProfilsVerif.includes(status)?status:'default');
        setStatusProfilVerif (newsts);
    }
}

const goNewCommons = (typeJdk, newJdk, phase, uuidToast=null) => {
    const monProfil      = store.getState().monProfil;
    const inscription    = (monProfil && 'inscription' in monProfil && monProfil.inscription?monProfil.inscription:null);
    const dbname         = getdefaultbddentry();
    const email          = (inscription && 'email' in inscription?inscription.email:null);
    const _id            = (newJdk && '_id'        in newJdk && newJdk._id   ?newJdk._id   :null);
    const _rev           = (newJdk && '_rev'       in newJdk && newJdk._rev  ?newJdk._rev  :null);
    //const text           = (newJdk && 'text'       in newJdk && newJdk.text  ?newJdk.text  :null);
    const sha256         = (newJdk && 'sha256'     in newJdk && newJdk.sha256?newJdk.sha256:null);
    const datefront      = new Date().toISOString();
    const proof          = sha256+email+dbname+datefront;
    //const hasJdk         = mysha256sum
    //console.log ("goNewCommons", typeJdk, monProfil, email, proof);
    mysha256sum(proof)
        .then (sha256cedd => {
            const jdk    = {_id, _rev, datefront, sha256cedd};
            const lcJdk  = typeJdk.toLowerCase();
            let   params = {email, dbname, sha256};
            params[lcJdk]= jdk; // CGU|PGP => params.cgu|pgp pour le nom de paramètre
            //console.log ("goNewCGU2", phase, params);
            processcommons (typeJdk, phase, params)
            .then  (() => {
                RenderMetaToast(typeJdk, "Nouveauté: "+typeJdk+" à valider", "warning", uuidToast);
            })
            .catch (err => {
                console.error (err);
                RenderMetaToast(typeJdk, "ERROR - Erreur réseau ("+typeJdk+")", "danger", uuidToast);
            });
        })
        .catch(err => {
            console.error (err);
            RenderMetaToast(typeJdk, "ERROR - process de validation "+typeJdk, "danger", uuidToast);
        });
}

// Comparer les CGU/PGP avec les CGU/PGP de la base de données customer
// Si les CGU/PGP customer n'existent pas ou différent demander l'acceptation via l'API
const onChangeFromCommons = (typeJdk, infojdk, setCOMMON) => {
    //console.log ("DOCJDKRSM FROM ERASM", typeJdk, infojdk);
    const {doc=null} = infojdk;
    const uuidToast  = typeJdk;
    if (doc) {
        //RenderMetaToast(typeJdk, "Mise à jour "+typeJdk+"...", "info", uuidToast);
        //console.log ("DOCJDKRSM INFO", typeJdk, infojdk);
        const docJdkRsm  = doc;
        if (!docJdkRsm) {
            RenderMetaToast(typeJdk, "Erreur d'accès à la base juridique", "error", uuidToast);
            throw new Error ("No access to common", {typeJdk});
        }
        // Des CGV ou PGP qui ne sont pas en production, ignorer
        const {status="production"} = docJdkRsm; // Status production par défaut - cas erreur commons doit se voir
        if (!(status==='production'))
        { return 'oK'; }
        const type = typeJdk;
        const _id  = typeJdk;
        const mycrud = new CRUDcompo({type}); // CGU/PGP dans la bdd customer
        mycrud.read ({_id})
            .then (docMonJdk => {
                const lcJdk      = typeJdk.toLowerCase();
                const moncmn     = (docMonJdk && lcJdk    in docMonJdk && docMonJdk[lcJdk]?docMonJdk[lcJdk]:null);
                const old_id     = (moncmn    && '_id'    in moncmn    && moncmn._id      ?moncmn._id      :null);
                const oldrev     = (moncmn    && '_rev'   in moncmn    && moncmn._rev     ?moncmn._rev     :null);
                const new_id     = (docJdkRsm && '_id'    in docJdkRsm && docJdkRsm._id   ?docJdkRsm._id   :null);
                const newrev     = (docJdkRsm && '_rev'   in docJdkRsm && docJdkRsm._rev  ?docJdkRsm._rev  :null);
                const revalidate = (!old_id || !oldrev || old_id!==new_id || oldrev!==newrev);
                // Les CGU/PGP ont évolué si id/rev différents entre CGU client/common,
                //     demander la validation des nouvelles CGU/PGP
                // Si id/rev les même des 2 côtés, charger les CGU/PGP actuelles dans le store
                //console.log ("common / customer onChangeFromCommons", {typeJdk, docJdkRsm, moncmn, old_id, new_id, oldrev, newrev, revalidate});
                if (revalidate) {
                    //console.log (typeJdk, "ERASM RESTART", docMonJdk, docJdkRsm);
                    goNewCommons (typeJdk, docJdkRsm, 'start', typeJdk);
                } else {
                    //RenderMetaToast(typeJdk, "Chargement oK - "+typeJdk, "success", uuidToast);
                    setCOMMON (moncmn);
                }
            })
            .catch ((error) => {
                console.error ("common / customer onChangeFromCommons", error);
                // Les CGU/PGP n'existent pas encore dans la base client
                // Demander validation des CGU/PGP via l'API
                goNewCommons (typeJdk, docJdkRsm, 'start', uuidToast);
            });
    } else {
        RenderMetaToast(typeJdk, "ERROR - Impossible de vérifier -"+typeJdk, "danger", uuidToast);
    }
}
const onChangeCGUFromCommons = (infojdk) => { onChangeFromCommons ("CGU", infojdk, setCGU) }
const onChangePGPFromCommons = (infojdk) => { onChangeFromCommons ("PGP", infojdk, setPGP) }

// Comparer les CGU avec les CGU de la base de données customer
// Si les CGU customer n'existent pas ou différent demander l'acceptation via l'API
const onChangeCommons = (info, typecmn, modal, mysettoconfirm, myset) => {
    const lctyp     = typecmn.toLowerCase();
    const uuidToast = typecmn;
    RenderMetaToast(typecmn,"Mise à jour "+typecmn+"...","info",uuidToast);
    //console.log ("JFBJFB", typecmn, info);
    if (info) {
        //console.log ("JFBJFB2", typecmn, info);
        const docMycommons = ('doc' in info?info.doc:null);
        if (docMycommons) {
            //console.log ("JFBJFB3 DOCCOMMONS", {typecmn, info, docMycommons});
            const status = (docMycommons && 'status' in docMycommons && docMycommons.status?docMycommons.status:null);
            const moncmn = (docMycommons && lctyp    in docMycommons && docMycommons[lctyp]?docMycommons[lctyp]:null);
            // Les CGU/PGP ont évolué, demander la validation des nouvelles CGU/PGP
            // Charger les CGU/PGP actuelles dans le store
            //console.log ("JFBJFB 2", status);
            switch (status) {
                case 'start': {
                    RenderMetaToast(typecmn, typecmn+": chargement...", "info", uuidToast);
                    break;
                }
                case 'confirm': {
                    RenderMetaToast(typecmn, typecmn+" à confirmer", "info", uuidToast);
                    showModal ({modal});
                    mysettoconfirm (docMycommons);
                    break;
                }
                case 'confirmed': {
                    RenderMetaToast(typecmn, typecmn+" confirmation ok...", "success", uuidToast);
                    myset (moncmn);
                    break;
                }
                default: {
                    RenderMetaToast(typecmn, typecmn+" unknown status...", "danger", uuidToast);
                    break;
                }
            }
        } else {
            RenderMetaToast(typecmn, typecmn+" erreur de chargement...", "danger", uuidToast);
        }
    }
}

const onChangeCGU = (info) => { onChangeCommons (info, 'CGU', 'validation_cgu', setdocCGUtoconfirm, setCGU); }
const onChangePGP = (info) => { onChangeCommons (info, 'PGP', 'validation_pgp', setdocPGPtoconfirm, setPGP); }
export const onChangeCorP   = (info,typcmn) => {
    switch (typcmn) {
        case 'CGU': { onChangeCGU(info); break; }
        case 'PGP': { onChangePGP(info); break; }
        default:    { /*Erreur applicative TBC*/ }
    }
}

export const tWorkHooks = {
    commons: {
        "CGU"           : { hook: doc=>('type' in doc && doc.type==='CGU'           ), selector: {type: 'CGU'           }, onchange: onChangeCGUFromCommons   , firstselector: {type: 'CGU',          status:           "production"            } },
        "PGP"           : { hook: doc=>('type' in doc && doc.type==='PGP'           ), selector: {type: 'PGP'           }, onchange: onChangePGPFromCommons   , firstselector: {type: 'PGP',          status:           "production"            } },
    },
    customer: {
        "author-credits": { hook: doc=>('_id'  in doc && doc._id ==='author-credits'), selector: {_id: 'author-credits' }, onchange: onChangeAuthorCredits    },
        "mon_profil"    : { hook: doc=>('_id'  in doc && doc._id ==='mon_profil'    ), selector: {_id: 'mon_profil'     }, onchange: onChangeMonProfil        },
        "notification"  : { hook: doc=>('type' in doc && doc.type==='notification'  ), selector: {type: 'notification'  }, onchange: onChangeNotifications    , firstselector: {type: 'notification', dateacquittement: {'$exists': false      }} },
        "profil_verif"  : { hook: doc=>('type' in doc && doc.type==='profil_verif'  ), selector: {type: 'profil_verif'  }, onchange: onChangeStatusProfilVerif},
        "CGU"           : { hook: doc=>('type' in doc && doc.type==='CGU'           ), selector: {type: 'CGU'           }, onchange: onChangeCGU              , firstselector: {type: 'CGU',          status:           {'$ne'    : 'confirmed'}} },
        "PGP"           : { hook: doc=>('type' in doc && doc.type==='PGP'           ), selector: {type: 'PGP'           }, onchange: onChangePGP              , firstselector: {type: 'PGP',          status:           {'$ne'    : 'confirmed'}} },
    },
}

//mysha256sum("7b1c0412a09b58010eba950f4353c5a73d701b60e62a2f9d81601b5c397557bcjfbaillette@g-echo.frauthor-50636730-6b8a-40f5-aaa8-1665bdb631e42020-11-02T09:50:30.368Z")
//    .then (res => console.log ("TOTO",       res))
//    .catch(err => console.log ("ERROR TOTO", err));

