import {createStore, combineReducers} from 'redux';
//import {reducer as formReducer      } from 'react-final-form';

import {METHANOL_CONSTS}             from './methanol/ForStore';
import {methanolInitState}           from './methanol/ForStore';
import {methanolReducers}            from './methanol/ForStore';

const C = {
    SET_FIL_ARIANE        : "SET_FIL_ARIANE",
    SET_CONNEXION         : "SET_CONNEXION",
    SET_NB_CREDITS        : "SET_NB_CREDITS",
    SET_MON_PROFIL        : "SET_MON_PROFIL",
    SET_VERIFIED_PROFIL   : "SET_VERIFIED_PROFIL",
    SET_STATUS_PROFILVERIF: "SET_STATUS_PROFILVERIF",
    SET_OEUVRE_FILES      : "SET_OEUVRE_FILES",
    SET_CNI_RECTO         : "SET_CNI_RECTO",
    SET_CNI_VERSO         : "SET_CNI_VERSO",
    SET_PASSEPORT_RECTO   : "SET_PASSEPORT_RECTO",
    SET_CGU               : "SET_CGU",
    SET_PGP               : "SET_PGP",
    SET_CGUTOCONFIRM      : "SET_CGUTOCONFIRM",
    SET_PGPTOCONFIRM      : "SET_PGPTOCONFIRM",
    // --------------------------------------
    // Vous prendrez bien un peu de methanol?
    // --------------------------------------
    //...METHANOL_CONSTS
}

/* Navigation par ID CHAMPS
**  2 pour Dashboard Bilan
**
**
*/
const initState = {
    // fil d'ariane =
    // - target       : nom de l'affichage en cours (connaissances, etude, user, ...),
    // Exemple complet: { target: 'etude', etude: '8b83c762-96a3-4a0c-ac9f-dbadeac07c27', atelier: 0, etape: 10 }
    fildAriane        : { target: ('REACT_APP_TARGET' in process.env?process.env.REACT_APP_TARGET:'proteger_oeuvre') },
    oeuvreFiles       : null,
    cni_recto         : null,
    cni_verso         : null,
    pst_volet         : null,
    cgu               : null,
    pgp               : null,
    doccgutoconfirm   : null,
    docpgptoconfirm   : null,
    nbcredits         : 0,
    monProfil         : null,
    verifiedProfil    : null,
    statusProfilVerif : 'default',
    // --------------------------------------
    // Vous prendrez bien un peu de methanol?
    // --------------------------------------
    ...methanolInitState,
}

//Définir sous-atelier par son ID
const fildArianeReducer = (state = initState.fildAriane, action) => {
    switch (action.type) {
        case C.SET_FIL_ARIANE:
            return { ...state, ...action.fildAriane };
        default:
            return state;
    }
}

// action de la forme {hash, hashtype, filename, content_type, file}
// Constituer un tableau de fichiers de la forme:
// {
//     <hash1>: {typehash1, filename1, content_type1, filesize1, file1, browserfile1};
//     <hash2>: {typehash2, filename2, content_type2, filesize2, file2, browserfile2};
//     ...
// }
const oeuvreFilesReducer = (state = initState.oeuvreFiles, action) => {
    switch (action.type) {
        case C.SET_OEUVRE_FILES: {
            const hash         = (action && 'hash'         in action?action.hash        :null);
            const deleted      = (action && 'deleted'      in action?action.deleted     :false);
            const hashtype     = (action && 'hashtype'     in action?action.hashtype    :null);
            const filename     = (action && 'filename'     in action?action.filename    :null);
            const content_type = (action && 'content_type' in action?action.content_type:null);
            const filesize     = (action && 'filesize'     in action?action.filesize    :null);
            //const file         = (action && 'file'         in action?action.file        :null);
            const browserfile  = (action && 'browserfile'  in action?action.browserfile :null);

            if (deleted) {
                let newstate = {...state};
                (newstate && 'hash' in newstate) && delete newstate[hash];
                return (newstate);
            //if (hash && hashtype && filename && content_type && filesize && file) {
            } else if (hash && hashtype && filename && content_type && filesize) {
                let newstate = {...state};
                // TBC JFB ajouter le fichier si option sauvegarde file
                //newstate[hash] =  {hashtype, filename, content_type, filesize, file, browserfile}
                newstate[hash] =  {hashtype, filename, content_type, filesize, browserfile}
                return (newstate);
            } else {
                return state;
            }
        }
        default:
            return state;
    }
}

const rectoversoReducer = (state=null, action) => {
    switch (action.type) {
        case C.SET_CNI_RECTO:       return (action.myatchmt);
        case C.SET_CNI_VERSO:       return (action.myatchmt);
        case C.SET_PASSEPORT_RECTO: return (action.myatchmt);
        default:
            return state;
    }
}

const cguReducer = (state = initState.cgu, action) => {
    switch (action.type) {
        case C.SET_CGU:
            return (action.cgu);
        default:
            return state;
    }
}

const pgpReducer = (state = initState.pgp, action) => {
    switch (action.type) {
        case C.SET_PGP:
            return (action.pgp);
        default:
            return state;
    }
}

const doccgutoconfirmReducer = (state = initState.doccgutoconfirm, action) => {
    switch (action.type) {
        case C.SET_CGUTOCONFIRM:
            return (action.doccgutoconfirm);
        default:
            return state;
    }
}

const docpgptoconfirmReducer = (state = initState.docpgptoconfirm, action) => {
    switch (action.type) {
        case C.SET_PGPTOCONFIRM:
            return (action.docpgptoconfirm);
        default:
            return state;
    }
}

const nbcreditsReducer = (state = initState.nbcredits, action) => {
    switch (action.type) {
        case C.SET_NB_CREDITS:
            return (action.nbcredits);
        default:
            return state;
    }
}

const monProfilReducer = (state = initState.monProfil, action) => {
    switch (action.type) {
        case C.SET_MON_PROFIL:
            return (action.monProfil);
        default:
            return state;
    }
}

const verifiedProfilReducer = (state = initState.verifiedProfil, action) => {
    switch (action.type) {
        case C.SET_VERIFIED_PROFIL:
            return (action.verifiedProfil);
        default:
            return state;
    }
}

const statusProfilVerifReducer = (state = initState.statusProfilVerif, action) => {
    switch (action.type) {
        case C.SET_STATUS_PROFILVERIF:
            return (action.statusProfilVerif);
        default:
            return state;
    }
}

const reducers = combineReducers({
    fildAriane       : fildArianeReducer,
    oeuvreFiles      : oeuvreFilesReducer,
    cni_recto        : rectoversoReducer,
    cni_verso        : rectoversoReducer,
    pst_volet        : rectoversoReducer,
    cgu              : cguReducer,
    pgp              : pgpReducer,
    doccgutoconfirm  : doccgutoconfirmReducer,
    docpgptoconfirm  : docpgptoconfirmReducer,
    nbcredits        : nbcreditsReducer,
    monProfil        : monProfilReducer,
    verifiedProfil   : verifiedProfilReducer,
    statusProfilVerif: statusProfilVerifReducer,
    //form             : formReducer,
    // --------------------------------------
    // Vous prendrez bien un peu de methanol?
    // --------------------------------------
    ...methanolReducers,
});

const rootReducer = (state, action) => {
    let myret = reducers(state, action);
    // Si LOGOUT, réinitaliser le state pour ne rien laisser en mémoire
    if (action.type === METHANOL_CONSTS.LOGOUT) { myret=initState; }
    return myret;
}

//create redux store
export const store = createStore(rootReducer, initState);

//on écoute le store à chaque update
store.subscribe(() => {
    //console.log("state updated", store.getState());
});
